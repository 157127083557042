// import ENUM from '@/maps/enum/enum'
import ENABLE from '@/maps/enum/status/enable';
import { listTop } from '@api/rm/menu';
var VAL_WIDTH = '120px';
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
export var FILTER_FORMS = [{
  label: '角色编号',
  valueKey: 'code',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '角色名称',
  valueKey: 'name',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '用户姓名',
  valueKey: 'userName',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '用户账户',
  valueKey: 'userCode',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}];