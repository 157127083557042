





























































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/bank/bill/pa/components/dialog-add.vue"
}
