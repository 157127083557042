import _slicedToArray from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.entries.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { FORM, DATE, INPUT } from './option';
import util from '@/libs/util';
import JSelectTree from '@/components/common/j-select-tree';
export default {
  name: 'dynamic-form',
  props: {
    // 要渲染的表单数据
    config: {
      type: Object,
      default: function _default() {
        return {
          forms: [],
          // 表单列表
          props: {} // 表单配置

        };
      }
    },
    // 表单列表
    forms: {
      type: [Array, Object],
      default: function _default() {
        return [];
      }
    },
    // 表单配置
    props: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    // 默认值
    value: {
      type: Object,
      default: function _default() {}
    }
  },
  components: {
    JSelectTree: JSelectTree
  },
  data: function data() {
    return {
      rules: {},
      model: {},
      formData: [],
      resultKey: 'model' // 结果集的key名

    };
  },
  computed: {
    // 表单配置
    propsConfig: function propsConfig() {
      return _objectSpread(_objectSpread({}, FORM), this.props);
    }
  },
  watch: {
    value: {
      handler: function handler(val) {
        this.model = util.deepClone(val);
      },
      immediate: true
    },
    forms: {
      handler: function handler(val) {
        this.init();
      },
      immediate: true
    }
  },
  methods: {
    // TODO 初始化
    init: function init() {
      var _this = this;

      var model = util.deepClone(this.model || {});
      var formRules = {};
      var optionList = ['radio', 'checkbox', 'select', 'cascader', 'select-tree']; // 有选项的列表

      var forms = Object.values(this.forms);
      this.formData = forms.reduce(function (formData, item) {
        var _item$valueKey = item.valueKey,
            valueKey = _item$valueKey === void 0 ? '' : _item$valueKey,
            _item$value = item.value,
            value = _item$value === void 0 ? '' : _item$value,
            type = item.type,
            _item$params = item.params,
            params = _item$params === void 0 ? {} : _item$params,
            _item$rules = item.rules,
            rules = _item$rules === void 0 ? [] : _item$rules,
            _item$method = item.method,
            method = _item$method === void 0 ? null : _item$method,
            _item$options = item.options,
            options = _item$options === void 0 ? [] : _item$options,
            _item$noSubmit = item.noSubmit,
            noSubmit = _item$noSubmit === void 0 ? false : _item$noSubmit;

        var form = _objectSpread({}, item);

        if (!noSubmit) {
          model[valueKey] = model[valueKey] || value;
        }

        formRules[valueKey] = rules; // 在数组optionList内的才判断是否请求数据请求下拉数据

        if (!options.length && optionList.includes(type) && method) {
          method(params).then(function (list) {
            _this.$set(form, 'options', list); // 设置选项值

          });
        }

        formData[valueKey] = form;
        return formData;
      }, {});
      this.model = model;
      this.rules = formRules;
    },
    // TODO 重置表单的值
    reset: function reset() {
      var model = this.model;
      this.forms.forEach(function (item) {
        var _item$valueKey2 = item.valueKey,
            valueKey = _item$valueKey2 === void 0 ? '' : _item$valueKey2,
            _item$value2 = item.value,
            value = _item$value2 === void 0 ? '' : _item$value2,
            _item$noSubmit2 = item.noSubmit,
            noSubmit = _item$noSubmit2 === void 0 ? false : _item$noSubmit2;

        if (!noSubmit) {
          model[valueKey] = value;
        }
      });
      this.model = model;
    },
    // TODO 获取表单结果
    getForm: function getForm() {
      return this.model;
    },
    // TODO 提交
    submit: function submit() {
      var _this2 = this;

      var showTip = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      return new Promise(function (resolve, reject) {
        _this2.$refs.form.validate(function (valid) {
          // 校验是否通过
          if (valid) {
            resolve(JSON.parse(JSON.stringify(_this2.model)));
          } else {
            var message = '表单校验失败，请检查';

            if (showTip) {
              // 登录表单校验失败
              _this2.$message.error(message);
            }

            reject(message);
          }
        });
      }).catch(function (e) {
        return '';
      });
    },
    // TODO 修改this指向，绑定到当前的this
    bindThis: function bindThis() {
      var _this3 = this;

      var target = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      target = target || {};
      var entries = Object.entries(target);
      var newTarget = {};
      entries.forEach(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            key = _ref2[0],
            fn = _ref2[1];

        newTarget[key] = fn.bind(_this3);
      });
      return newTarget;
    },
    // TODO 修改事件的this指向
    bindListener: function bindListener(item) {
      var _item$listeners = item.listeners,
          listeners = _item$listeners === void 0 ? {} : _item$listeners;
      return this.bindThis(listeners);
    },
    // TODO 修改日期控件限制条件的this指向
    bindPickerOptions: function bindPickerOptions(item) {
      var _item$props = item.props,
          props = _item$props === void 0 ? {} : _item$props;
      var pickerOptions = props.pickerOptions;
      return this.bindThis(pickerOptions);
    },
    // TODO 处理日期组件默认配置
    handleDateProps: function handleDateProps(item) {
      var _item$props2 = item.props,
          props = _item$props2 === void 0 ? {} : _item$props2;
      return _objectSpread(_objectSpread({}, DATE), props);
    },
    // TODO 处理输入框组件默认配置
    handleInputProps: function handleInputProps(item) {
      var _item$props3 = item.props,
          props = _item$props3 === void 0 ? {} : _item$props3;
      return _objectSpread(_objectSpread({}, INPUT), props);
    },
    // TODO 监听数字输入框变化
    numberChange: function numberChange(item) {
      var type = item.type,
          key = item.valueKey,
          _item$props4 = item.props,
          props = _item$props4 === void 0 ? {} : _item$props4; // 如果是数字输入框，则将值转为数字

      if (type === 'number') {
        var max = props.max,
            _props$abs = props.abs,
            abs = _props$abs === void 0 ? true : _props$abs;
        var val = parseFloat(this.model[key]) || 0; // 如果有最大值限制，且超过最大值，则将当前值设置为最大值

        if (max && val > max) {
          val = max;
        } // 是否取绝对值


        if (abs) {
          val = Math.abs(val);
        }

        this.model[key] = val;
      }
    },
    // change事件传递
    itemChange: function itemChange(item) {
      this.$emit('onChange', item);
    }
  }
};