import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.filter.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from '@/mixins/dialog';
import COMMISSION_STATUS from '@/maps/enum/status/commission-status';
import { commissionAudit, commissionSaveAllocation } from '@api/crm/commissionV3/commissionV3';
import { number } from 'echarts/lib/export';
import { listRight as personList, list as detailsList } from '@api/rm/person';
export default {
  name: 'settle-batch',
  props: {
    propsModel: {
      type: Array,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      showLogDialog: false,
      tableData: [],
      formModel: {},
      //表单
      sum: {},
      //统计
      rules: {},
      selectData: {
        person: []
      }
    };
  },
  created: function created() {},
  computed: {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.loadData();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
      this.sum = {};
      this.formModel = {};
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {},
    clickAuditPlan: function clickAuditPlan() {
      var _this = this;

      if (this.formModel.advice == null || this.formModel.advice == '') {
        return this.$message('请填写审核意见');
      } // 退回计划


      this.$confirm('是否退回到待计划?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this.audit(COMMISSION_STATUS.COUNT_AUDIT_FAIL.value);
      }).catch(function () {});
    },
    clickAudit: function clickAudit() {
      var _this2 = this;

      // 处理人员数据
      var isbonusUserId = false; // 未填写人员金额为0 判断

      var param = this.tableData.map(function (item) {
        if (item.detailList.length > 0) {
          item.detailList.forEach(function (n) {
            // 人员展示文本赋值
            n.bonusUserName = item[n.titleId + 'name']; // 人员id赋值

            n.bonusUserId = item[n.titleId]; // 是否在岗人员

            n.isNoUser = item[n.titleId + 'isNoUser']; // 金额

            n.bonus = item[n.titleId + 'bonus']; // 未填写 金额

            if (!n.bonusUserId && n.bonus) {
              isbonusUserId = true;
            }
          });
        }

        return {
          detailList: item.detailList,
          applicationId: item.applicationId,
          remark: item.remark,
          isBackOffice: item.isBackOffice
        };
      });

      if (isbonusUserId) {
        return this.$message('请填写人员姓名，未填写人员姓名的金额必须为0');
      }

      this.loading = true; // 保存分配数据

      commissionSaveAllocation({
        arrayObject: param
      }).then(function (res) {
        var arrayApplicationId = [];

        _this2.tableData.forEach(function (n) {
          arrayApplicationId.push(n.applicationId);
        });

        var submitParam = {
          arrayApplicationId: arrayApplicationId,
          status: COMMISSION_STATUS.COUNT_AUDIT.value
        }; // 提交 状态

        commissionAudit(submitParam).then(function (res) {
          _this2.loading = false;
          _this2.showDialog = false;

          _this2.$message({
            message: '已提交',
            type: 'success'
          });

          _this2.$emit('refresh');
        });
      }).catch(function (e) {
        _this2.loading = false;
      });
    },
    // TODO 确定
    audit: function audit(result) {
      var _this3 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          // 处理id
          var arrayApplicationId = [];

          _this3.tableData.forEach(function (n) {
            arrayApplicationId.push(n.applicationId);
          });

          var param = {
            arrayApplicationId: arrayApplicationId,
            status: result,
            advice: _this3.formModel.advice
          };
          _this3.loading = true;
          commissionAudit(param).then(function (res) {
            _this3.loading = false;
            _this3.showDialog = false;

            _this3.$emit('refresh');
          }).catch(function (e) {
            _this3.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    loadData: function loadData() {
      for (var i = 0; i < this.propsModel.length; i++) {
        var rowData = this.propsModel[i];
        var sumBonus = 0; // 行合计

        for (var j = 0; j < rowData.detailList.length; j++) {
          var n = rowData.detailList[j]; // 人员列表展示

          var keyName = n.titleId + 'name';
          var keyId = n.titleId; // 人员金额展示

          var keyBonus = n.titleId + 'bonus'; // 是否在岗人员

          var keyIsNoUser = n.titleId + 'isNoUser'; // 展示人员文本

          rowData[keyName] = n.bonusUserName; // 展示人员id

          rowData[keyId] = n.bonusUserId;
          rowData[keyBonus] = n.bonus;
          rowData[keyIsNoUser] = n.isNoUser;
          sumBonus += parseFloat(n.bonus);
        } // 合计js精度丢失


        rowData.sumBonus = Number(sumBonus.toFixed(2));
        this.tableData.push(rowData);
      }
    },
    // 设置表格统计行
    setSummaryRow: function setSummaryRow() {
      var sumI0 = 0;
      var sumI1 = 0;
      var sumI4 = 0;
      var sumI2 = 0;
      var sumI3 = 0;
      var sumI5 = 0;
      var sum = 0;
      this.tableData.forEach(function (n) {
        sumI0 += n.i0bonus;
        sumI1 += n.i1bonus == null ? 0 : n.i1bonus;
        sumI4 += n.i4bonus == null ? 0 : n.i4bonus;
        sumI2 += n.i2bonus == null ? 0 : n.i2bonus;
        sumI3 += n.i3bonus == null ? 0 : n.i3bonus;
        sumI5 += n.i5bonus == null ? 0 : n.i5bonus;
        sum += n.sumBonus;
      });
      var sumRow = [];
      sumRow[0] = '合计';
      sumRow[1] = '共' + this.tableData.length + '笔';
      sumRow[7] = sumI0.toFixed(2);
      sumRow[9] = sumI1.toFixed(2);
      sumRow[12] = sumI4.toFixed(2);
      sumRow[15] = sumI2.toFixed(2);
      sumRow[18] = sumI3.toFixed(2);
      sumRow[21] = sumI5.toFixed(2);
      sumRow[24] = sum.toFixed(2);
      return sumRow;
    },
    // 选择是否在岗人员
    changeCheckbox: function changeCheckbox(row, name, index) {
      // 否在岗人员
      var NoUser = name + 'isNoUser'; // 人员

      var bonusUserId = name + 'name'; // 金额

      var bonus = name + 'bonus'; // 复选框选中 清空金额和人员

      if (row[NoUser] === '1') {
        // 展示人员文本
        row[bonusUserId] = null; // 展示人员id

        row[name] = null; // 金额

        row[bonus] = 0;
      } else {
        var rowData = this.propsModel[index]; // 复选框选中 复现金额 人员

        rowData.detailList.forEach(function (item) {
          if (item.titleId === name) {
            // 展示人员文本
            row[bonusUserId] = item.bonusUserName; // 展示人员id

            rowData[name] = item.bonusUserId;
            row[bonus] = item.bonus;
          }
        });
      }

      this.$forceUpdate(); // 强制刷新
    },
    // row 当前项 name人员id键值 value选择人员id
    changePerson: function changePerson(row, name, value) {
      var label = name + 'name'; // 判断是否选择人员

      if (row[label] != '') {
        // 选择新人员
        var n = {}; // 原下拉框选项

        n = this.selectData.person.find(function (item) {
          return item.personuuid === value;
        });

        if (n != null) {
          row[label] = n.name;
          row[name] = n.personuuid;
        }
      } else {
        // 没有选择，或者原有的清空
        row[name] = null;
      } // 数据刷新


      this.$forceUpdate();
    },
    // 搜索用户
    searchPerson: function searchPerson(key, row, keyIdText) {
      var _this4 = this;

      if (key != '') {
        var param = {
          query: {
            name: key
          }
        };
        personList(param).then(function (res) {
          _this4.selectData.person = res.data; // 无值 区总查询

          if (!res.data && (keyIdText == 'i2' || keyIdText == 'i3' || keyIdText == 'i4')) {
            detailsList(param).then(function (res) {
              if (res.data != null && res.data.length === 1) {
                // 区总 查询只有一位时 赋值当前选中
                console.log('1----', res);

                if (key === res.data[0].name) {
                  row[keyIdText] = res.data[0].personuuid;
                  row["".concat(keyIdText, "name")] = res.data[0].name;

                  _this4.$message({
                    message: "".concat(res.data[0].name, " \u59D3\u540D\u6B63\u5E38\uFF0C\u5728\u6211\u53F8\u67B6\u6784\u4E2D\u5B58\u5728\u3002"),
                    type: 'success'
                  });
                }

                _this4.$forceUpdate();
              } else if (res.data != null && res.data.length > 1) {
                // 区总 查询多位时 提醒人员重名 总部人员处理
                var arr = res.data.filter(function (item) {
                  return item.enabled === '1' && item.name === key; // 1 启用  2.查询名字相同
                });

                if (arr.length > 1) {
                  // 多个启用 重名
                  if (key === arr[0].name) {
                    _this4.$message({
                      message: "".concat(arr[0].name, " \u59D3\u540D\u5B58\u5728\u91CD\u540D\uFF0C\u8BF7\u8054\u7CFB\u603B\u90E8\u4EBA\u5458\u5904\u7406\u3002\u3002"),
                      type: 'error'
                    });
                  }
                } else if (arr.length === 1) {
                  // 一个启用
                  if (key === arr[0].name) {
                    row[keyIdText] = arr[0].personuuid;
                    row["".concat(keyIdText, "name")] = arr[0].name;

                    _this4.$message({
                      message: "".concat(arr[0].name, " \u59D3\u540D\u6B63\u5E38\uFF0C\u5728\u6211\u53F8\u67B6\u6784\u4E2D\u5B58\u5728\u3002"),
                      type: 'success'
                    });
                  }
                } else {
                  // 无启用 都是停用或者禁用 取第一个
                  if (key === res.data[0].name) {
                    row[keyIdText] = res.data[0].personuuid;
                    row["".concat(keyIdText, "name")] = res.data[0].name;

                    _this4.$message({
                      message: "".concat(res.data[0].name, " \u59D3\u540D\u6B63\u5E38\uFF0C\u5728\u6211\u53F8\u67B6\u6784\u4E2D\u5B58\u5728\u3002"),
                      type: 'success'
                    });
                  }
                }

                _this4.$forceUpdate();
              }
            });
          }
        }).catch(function (e) {
          _this4.loading = false;

          _this4.$message({
            message: '查询用户出错',
            type: 'error'
          });
        });
      }
    }
  }
};