import "core-js/modules/es.array.concat.js";
export var TABLE_LABEL = [{
  label: '客户等级',
  prop: 'name'
}, {
  width: 250,
  label: '判断条件（万元）',
  prop: 'condition'
}, {
  label: 'logo图片',
  prop: 'logoPicture',
  type: 'img',
  formatter: function formatter(row) {
    return "".concat(process.env.VUE_APP_CLOUD_UPLOAD).concat(row.logoPicture);
  }
}, {
  label: '背景图片',
  prop: 'backgroundPicture',
  type: 'img',
  formatter: function formatter(row) {
    return "".concat(process.env.VUE_APP_CLOUD_UPLOAD).concat(row.backgroundPicture);
  }
}, {
  label: '字体颜色',
  prop: 'frontColor',
  type: 'slot'
}, {
  width: 150,
  label: '创建时间',
  prop: 'createTime'
}, {
  label: '创建人',
  prop: 'createUserName'
}, {
  width: 150,
  label: '更新时间',
  prop: 'updateTime'
}, {
  label: '更新人',
  prop: 'updateUserName'
}, {
  type: 'operation',
  label: '操作',
  width: 150,
  fixed: 'right',
  formatter: function formatter(row) {
    return [{
      type: 'edit',
      label: '修改',
      permission: 'customerLevel-edit'
    }];
  }
}];
export var TABLE_CONFIG = {
  isSelect: true,
  actions: [{
    label: '新增',
    type: 'add',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'customerLevel-add'
  }, {
    label: '删除',
    type: 'delete',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'customerLevel-delete'
  }]
};