import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import { DIALOG_FORM_CONFIG, DIALOG_FORM_DATA, DIALOG_FORM_CONFIG1, DIALOG_FORM_DATA1 } from "../option/sensitive";
import { cloneDeep } from 'lodash-es';
import { setSecrecy, listSecrecy } from "@api/rm/role";
export default {
  name: "dialog-sensitive",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {}
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      dialogFormConfig: cloneDeep(DIALOG_FORM_CONFIG),
      dialogFormData: _objectSpread(_objectSpread({}, cloneDeep(DIALOG_FORM_DATA)), {}, {
        roleId: this.propsModel.id,
        type: 1
      }),
      dialogFormConfig1: cloneDeep(DIALOG_FORM_CONFIG1),
      dialogFormData1: _objectSpread(_objectSpread({}, cloneDeep(DIALOG_FORM_DATA1)), {}, {
        roleId: this.propsModel.id,
        type: 2
      })
    };
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      var _this = this;

      var params = {
        query: {
          dataType: 1,
          roleId: this.propsModel.id
        },
        page: {
          pageNumber: 0,
          pageSize: 0
        }
      };
      listSecrecy(params).then(function (res) {
        if (res.data != null) {
          _this.$nextTick(function () {
            _this.formatValue(_this.dialogFormData, res.data[0]);

            _this.formatValue(_this.dialogFormData1, res.data[1]);
          });
        }
      });
    },
    formatValue: function formatValue(formModel, setting) {
      var _this2 = this;

      Object.keys(setting).forEach(function (key) {
        setting[key] === '1' ? _this2.$set(formModel, key, true) : _this2.$set(formModel, key, false);
      });
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.showDialog = false;
    },
    formatParams: function formatParams(obj) {
      var keys = Object.keys(obj);

      if (keys && keys.length) {
        keys.forEach(function (key) {
          obj[key] === true ? obj[key] = 1 : obj[key] = 0;
        });
      }

      return obj;
    },
    confirm: function confirm() {
      var _this3 = this;

      var params = {
        dataType: "1",
        //设置角色
        objShow: this.formatParams(this.dialogFormData),
        objExport: this.formatParams(this.dialogFormData1)
      };
      params.objShow.roleId = this.propsModel.id;
      params.objShow.type = '1'; //查看

      params.objExport.roleId = this.propsModel.id;
      params.objExport.type = '2'; //导出

      params.objExport.export = params.objShow.export; //导出设置中不需要设置导出，默认=查看中的导出设置

      setSecrecy(params).then(function () {
        _this3.$message.success('敏感词设置成功！');

        _this3.$emit("refresh");

        _this3.dialogClose();
      });
    }
  }
};