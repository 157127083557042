var activityUrl = {
  activity: {
    manage: {
      /** 活动列表 */
      listActivity: '/manage/activity/listActivity',

      /** 新增和修改活动 */
      addOrUpdateActivity: '/manage/activity/addOrUpdateActivity',

      /** 更新活动状态 */
      updateStatus: '/manage/activity/updateStatus',

      /** 逻辑删除活动 */
      deleteById: '/manage/activity/delete',

      /** 批量逻辑删除资讯 */
      deleteBatchByIds: '/alumniManage/information/document/deleteBatchByIds',

      /** 获取活动详情 */
      getActivity: '/manage/activity/getActivity',

      /** 活动签到列表 */
      listSigned: '/manage/zctivity/activityCustomerRecord/listSigned',

      /** 活动预约列表 */
      listAppointment: '/manage/zctivity/activityCustomerRecord/listAppointment',

      /** 获取客户参与记录分页数据 */
      getPage: '/manage/zctivity/activityCustomerRecord/getPage',

      /** 获取活动客户参与情况导出数据 */
      getExportData: '/manage/zctivity/activityCustomerRecord/getExportData',

      /** 活动评价列表 */
      listEvalRecord: '/manage/activity/customerEvalRecord/listEvalRecord',

      /** 活动报名信息表登记设置 */
      updateApplyFilePath: ' /manage/activity/updateApplyFilePath',

      /** 会员客户 */
      getCustomer: '/manage/customer/getActivityChooseCustomerPage',

      /** 客户等级 */
      getCustomerLevel: '/manage/customer/getActivityChooseCustomerPage',

      /** 活动审核 */
      audit: '/manage/activity/activityAuditRecord/audit',

      /** 报名信息登记表 */
      addRegistryForm: '/manage/activity/updateApplyFilePath',

      /** 获取登记表 */
      getRegistryForm: '/manage/activity/getApplyFilePathInfo',

      /** 判断是否客服 */
      isKefu: '/manage/activity/isKefu',

      /** 审核状态列表 */
      reviewList: '/manage/activity/activityAuditRecord/auditLog',

      /** 排序值 */
      updateSort: '/manage/activity/updateSort'
    },
    classify: {
      /**  活动分类新增或修改 */
      addOrUpdate: '/manage/activity/activityClassify/addOrUpdate',

      /** 活动分类修改状态 */
      updateStatus: '/manage/activity/activityClassify/updateStatus',

      /** 活动分类删除 */
      deleteById: '/manage/activity/activityClassify/delete',

      /** 活动分类修改排序值 */
      updateSort: '/manage/activity/activityClassify/updateSort',

      /** 获取活动分类详情 */
      getClassify: '/manage/activity/activityClassify/getClassify',

      /** 活动分类列表 */
      listActivityClassify: '/manage/activity/activityClassify/listActivityClassify',

      /** 所有活动分类 */
      listAllActivityClassify: '/manage/activity/activityClassify/listAllActivityClassify'
    },
    type: {
      /**  活动类型新增或修改 */
      addOrUpdate: '/manage/activity/activityClassify/addOrUpdate',

      /** 活动类型修改状态 */
      updateStatus: '/manage/activity/activityClassify/updateStatus',

      /** 活动类型删除 */
      deleteById: '/manage/activity/activityClassify/delete',

      /** 活动类型修改排序值 */
      updateSort: '/manage/activity/activityClassify/updateSort',

      /** 获取活动类型详情 */
      getClassify: '/manage/activity/activityClassify/getClassify',

      /** 活动类型列表 */
      listActivityClassify: '/manage/activity/activityClassify/listActivityClassify',

      /** 所有活动类型 */
      listActivityType: '/manage/activity/listActivityType'
    },
    template: {
      /** 活动评价模版新增或修改 */
      addOrUpdate: '/manage/activity/activityEvalTemplate/addOrUpdate',

      /** 活动评价模版列表 */
      listActivityTemplate: '/manage/activity/activityEvalTemplate/listActivityTemplate',

      /** 活动评价模版删除 */
      deleteById: '/manage/activity/activityEvalTemplate/delete',

      /** 活动评价模版修改状态 */
      updateStatus: '/manage/activity/activityEvalTemplate/updateStatus',

      /** 活动评价模版详情 */
      getActivityTemplate: '/manage/activity/activityEvalTemplate/getActivityTemplate',

      /** 活动评价模版修改排序值 */
      updateSort: '/manage/activity/activityEvalTemplate/updateSort',

      /** 所有活动模版 */
      listAllTemplate: '/manage/activity/activityEvalTemplate/listAllTemplate'
    }
  }
};
export default activityUrl;