// 推介费 
var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var baseRoute = 'crm';
export default [{
  path: "".concat(baseRoute, "/commissionV3/product"),
  name: 'commissionV3Product',
  meta: {
    title: '产品推介费',
    auth: true,
    cache: true
  },
  component: _import('crm/commissionV3/product')
}, {
  path: "".concat(baseRoute, "/commissionV3/proportion"),
  name: 'commissionV3Proportion',
  meta: {
    title: '推介费比例',
    auth: true,
    cache: true
  },
  component: _import('crm/commissionV3/proportion')
}, {
  path: "".concat(baseRoute, "/commissionV3/service"),
  name: 'commissionV3Service',
  meta: {
    title: '推介费计划',
    auth: true,
    cache: true
  },
  component: _import('crm/commissionV3/service')
}, {
  path: "".concat(baseRoute, "/commissionV3/allocation"),
  name: 'commissionV3Allocation',
  meta: {
    title: '推介费分配',
    auth: true,
    cache: true
  },
  component: _import('crm/commissionV3/allocation')
}, {
  path: "".concat(baseRoute, "/commissionV3/count"),
  name: 'commissionV3Count',
  meta: {
    title: '推介费审核',
    auth: true,
    cache: true
  },
  component: _import('crm/commissionV3/count')
}, {
  path: "".concat(baseRoute, "/commissionV3/search"),
  name: 'commissionV3Search',
  meta: {
    title: '推介费查询',
    auth: true,
    cache: true
  },
  component: _import('crm/commissionV3/search')
}];