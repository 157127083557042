//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import LOG_TYPE from "@/maps/enum/contract-log-type";
import { listContract as _listContract } from "@api/crm/other/contract-log"; //合同审核日志

export default {
  name: "contract-log",
  props: {
    contractId: {
      type: String,
      default: ""
    },
    extId: {
      type: String,
      default: ""
    },
    active: {
      type: String,
      default: "1"
    }
  },
  data: function data() {
    return {
      loading: false,
      collapseActive: [this.active],
      //信息折叠默认展开
      buyLog: [],
      //认购日志
      countLog: [],
      //核算日志
      applyLog: [],
      //赎回日志
      renewLog: [],
      //续约日志
      changeLog: [],
      //变更日志
      interestLog: [],
      //付息日志
      giftLog: [],
      //礼品日志
      paperLog: [],
      //纸合同日志
      clearLog: [],
      //清算日志
      commissionLog: [] //推介费

    };
  },
  mixins: [dialogMixin],
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val != "") {
          this.init();
          this.listContract();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.buyLog = [];
      this.countLog = [];
      this.applyLog = [];
      this.renewLog = [];
      this.changeLog = [];
      this.interestLog = [];
      this.giftLog = [];
      this.paperLog = [];
      this.clearLog = [];
      this.commissionLog = [];
    },
    dialogClose: function dialogClose() {},
    openDialog: function openDialog() {},
    listContract: function listContract() {
      var _this = this;

      var param = {
        contractId: this.contractId,
        extId: this.extId
      }; //this.loading = true;

      _listContract(param).then(function (res) {
        //this.loading = false;
        var data = res.data;

        if (data != null) {
          for (var i = 0; i < data.length; i++) {
            if (data[i].logType == LOG_TYPE.BUY) {
              _this.buyLog.push(data[i]);
            } else if (data[i].logType == LOG_TYPE.COUNT) {
              _this.countLog.push(data[i]);
            } else if (data[i].logType == LOG_TYPE.APPLY) {
              _this.applyLog.push(data[i]);
            } else if (data[i].logType == LOG_TYPE.RENEW) {
              _this.renewLog.push(data[i]);
            } else if (data[i].logType == LOG_TYPE.CHANGE) {
              _this.changeLog.push(data[i]);
            } else if (data[i].logType == LOG_TYPE.INTEREST) {
              _this.interestLog.push(data[i]);
            } else if (data[i].logType == LOG_TYPE.GIFT) {
              _this.giftLog.push(data[i]);
            } else if (data[i].logType == LOG_TYPE.PAPER) {
              _this.paperLog.push(data[i]);
            } else if (data[i].logType == LOG_TYPE.CLEAR) {
              _this.clearLog.push(data[i]);
            } else if (data[i].logType == LOG_TYPE.COMMISSION) {
              _this.commissionLog.push(data[i]);
            }
          }
        }
      });
    }
  },
  created: function created() {
    this.loading = false;
  }
};