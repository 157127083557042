import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { TABLE_CONFIG, TABLE_LABEL } from './config/list';
import { DIALOG_FORM_CONFIG, DIALOG_FORM_DATA } from './config/add';
import { getHeaders } from '@/api-new/service';
import { useSuccessTip } from '@/hooks/use-tip';
import { cloneDeep } from 'lodash-es';
import api from '@/api-new';
import url from '@/api-new/url';
export default {
  name: 'CustomerList',
  data: function data() {
    return {
      loading: false,
      tableConfig: TABLE_CONFIG,
      tableLabel: TABLE_LABEL,
      httpConfig: {},

      /* 弹窗数据 */
      dialogFormData: cloneDeep(DIALOG_FORM_DATA),
      dialogFormConfig: cloneDeep(DIALOG_FORM_CONFIG),

      /* 弹窗是否显示 */
      dialogVisible: false,

      /* 弹窗标题 */
      dialogTitle: '新增等级',
      isEdit: false,
      ids: []
    };
  },
  computed: {},
  methods: {
    /** 接口参数过滤函数 */
    filtersFormatter: function filtersFormatter() {},

    /* 弹窗确认 */
    dialogConfirm: function dialogConfirm() {
      var _this = this;

      this.$nextTick(function () {
        _this.$refs.form.submitForm();
      });
    },

    /* 弹窗确认 */
    dialogSubmit: function dialogSubmit() {
      var _this2 = this;

      if (this.isEdit) {
        var params = _objectSpread({}, this.dialogFormData);

        var _this$dialogFormData = this.dialogFormData,
            logoPicture = _this$dialogFormData.logoPicture,
            backgroundPicture = _this$dialogFormData.backgroundPicture;

        if (logoPicture) {
          params.logoPicture = logoPicture.url;
        }

        if (backgroundPicture) {
          params.backgroundPicture = backgroundPicture.url;
        }

        console.log(params);
        api.UPDATE_LEVELRULE(params).then(function () {
          _this2.dialogVisible = false;
          useSuccessTip();

          _this2.$refs.jPage.getPageData();
        });
      } else {
        var _this$dialogFormData2 = this.dialogFormData,
            _logoPicture = _this$dialogFormData2.logoPicture,
            _backgroundPicture = _this$dialogFormData2.backgroundPicture;
        var _logoPicture$url = _logoPicture.url,
            lUrl = _logoPicture$url === void 0 ? '' : _logoPicture$url;
        var _backgroundPicture$ur = _backgroundPicture.url,
            cUrl = _backgroundPicture$ur === void 0 ? '' : _backgroundPicture$ur;

        var _params = _objectSpread(_objectSpread({}, this.dialogFormData), {}, {
          logoPicture: lUrl,
          backgroundPicture: cUrl
        }); // console.log(params);


        api.ADD_LEVELRULE(_params).then(function () {
          _this2.dialogVisible = false;
          useSuccessTip();

          _this2.$refs.jPage.getPageData();
        });
      }
    },

    /** 点击表格项 */
    tableRowClick: function tableRowClick(action) {
      var _ref = action || {},
          clickItem = _ref.clickItem,
          row = _ref.row;

      var _ref2 = clickItem || {},
          type = _ref2.type;

      switch (type) {
        // 处理列表编辑
        case 'edit':
          this.Edit(row);
          break;
      }
    },
    tableClick: function tableClick(params) {
      console.log('params: ', params);

      var _ref3 = params || {},
          clickItem = _ref3.clickItem,
          selections = _ref3.selections;

      var _ref4 = clickItem || {},
          type = _ref4.type;

      if (type === 'add') {
        this.dialogVisible = true;
        this.isEdit = false;
        this.dialogFormData = cloneDeep(DIALOG_FORM_DATA);
      } else if (type === 'delete') {
        if (selections.length === 0) {
          this.$message.error('请选择要删除的数据');
          return;
        }

        this.ids = selections.map(function (item) {
          return item.id;
        });
        this.delete(this.ids);
      }
    },

    /* 操作删除 */
    delete: function _delete(ids) {
      var _this3 = this;

      this.$confirm('确定要删除所选项吗？', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        api.DELETE_LEVEL_RULE({
          ids: ids
        }).then(function () {
          useSuccessTip();

          _this3.$refs.jPage.getPageData();
        });
      });
    },
    Edit: function Edit(row) {
      console.log('row', row);
      this.dialogVisible = true;
      this.dialogFormData = row;
      this.dialogFormData.logoPicture = {
        url: row.logoPicture
      };
      this.dialogFormData.backgroundPicture = {
        url: row.backgroundPicture
      };
      this.dialogTitle = '编辑等级';
      this.isEdit = true;
    }
  },
  created: function created() {
    var host = url.host,
        apiPrev = url.apiPrev;
    var apiUrl = url.customer.level;
    this.httpConfig = {
      url: "".concat(host).concat(apiPrev).concat(apiUrl.getCustomerLevelRulePage),
      headers: getHeaders()
    };
  }
};