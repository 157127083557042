//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { TOP_FORM, TABLE_CONFIG, TABLE_LABEL } from './config/list';
import { getHeaders } from '@/api-new/service';
import api from '@/api-new';
import url from '@/api-new/url';
export default {
  name: 'CustomerList',
  data: function data() {
    return {
      loading: false,
      topForm: TOP_FORM,
      filtersData: {},
      tableConfig: TABLE_CONFIG,
      tableLabel: TABLE_LABEL,
      httpConfig: {} // dialogVisible: false, //提示框
      // CustomerInfo: {}, //用户信息

    };
  },
  computed: {},
  methods: {
    /** 接口参数过滤函数 */
    filtersFormatter: function filtersFormatter() {},

    /** 点击表格项 */
    tableRowChange: function tableRowChange(action) {// const { clickItem, row, type } = action || {};
      // if (type === 'link') {
      //   console.log(row);
      //   this.link(row);
      // }
    },
    //展示客户信息
    // async link(row) {
    //   //获取客户简短信息
    //   await api.GET_CUSTOMER_SIMPLEINFO({ code: row.customerCode }).then(res => {
    //     console.log(res);
    //     this.CustomerInfo = res;
    //   });
    //   this.dialogVisible = true;
    // },
    //     //弹框关闭事件
    // close() {
    //   this.dialogVisible = false;
    //   this.CustomerInfo = {};
    // },
    // //按钮跳转
    // skip() {
    //   this.$router.push({
    //     name: 'customerDetail',
    //     query: {
    //       id: this.CustomerInfo.customerId,
    //     },
    //   });
    // },
    tableClick: function tableClick(params) {
      console.log('params: ', params);

      var _ref = params || {},
          clickItem = _ref.clickItem;

      var _ref2 = clickItem || {},
          type = _ref2.type;

      if (type === 'add') {
        this.addVisible = true;
      }

      if (type === 'allot') {
        this.openAllot = true;
      }
    }
  },
  created: function created() {// const { host, apiPrev } = url;
    // const apiUrl = url.activity.manage;
    // this.httpConfig = {
    //   url: `${host}${apiPrev}${apiUrl.activityList}`,
    //   headers: getHeaders(),
    // };
  }
};