
































































































































































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/activity/manage/add/advancedSetting/index.vue"
}
