
















































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/customer/follow/index.vue"
}
