// 表头
export default [{
  label: '工号',
  prop: 'personNum',
  width: '100px'
}, {
  label: '姓名',
  prop: 'name',
  width: '180px'
}, {
  label: '账号',
  prop: 'code',
  width: '150px'
}, {
  label: '联系方式',
  prop: 'mobile',
  width: '110px'
}, {
  label: '性别',
  prop: 'sexualText',
  width: '50px'
}, {
  label: '职位',
  prop: 'title',
  width: '120px'
}, {
  label: '所属组织',
  prop: 'orgName',
  width: '250px'
}, {
  label: '后勤',
  prop: 'isBackofficeText',
  width: '50px'
}, {
  label: '在职',
  prop: 'enabledText',
  width: '50px'
}];