// 表头
var VAL_WIDTH = '120px';
export default [{
  label: '产品ID',
  prop: 'productId',
  width: '80px'
}, // {
//     label: '产品代码',
//     prop: 'productCode',
//     width: '120px',
// }, 
{
  label: '产品名称',
  prop: 'productName',
  width: '180px'
}, {
  label: '产品类型',
  prop: 'productTypeText',
  width: '80px'
}, {
  label: '合同号前缀',
  prop: 'prefix',
  width: VAL_WIDTH
}, {
  label: '资金限额',
  prop: 'moneyLimit',
  width: '120px'
}, // {
//     label: '业绩系数',
//     prop: 'businessRatio',
//     width: VAL_WIDTH,
//     align: 'right',
// }, 
{
  label: '目录1',
  prop: 'category1Text',
  width: '120px'
}, {
  label: '目录2',
  prop: 'category2Text',
  width: '120px'
}, {
  label: '目录3',
  prop: 'category3Text',
  width: '160px'
}, {
  label: '结算属性',
  prop: 'countTypeText',
  width: '160px'
}, {
  label: '创建时间',
  prop: 'createTime',
  width: '150px'
}, {
  label: '官方号',
  prop: 'isOfficialCdText',
  width: '120px'
}, {
  label: '到期结算',
  prop: 'isByDayText',
  width: '80px'
}, {
  label: '产品状态',
  prop: 'enabled',
  type: 'slot',
  width: '60px'
}];