// 推介费V 3
export default {
  // 产品推介费
  commissionSave: '/product/commission/save',
  // 产品推介费新增编辑
  commissionList: '/product/commission/list',
  //  产品推介费列表
  commissionGetInfo: '/product/commission/getInfo',
  //  产品推荐费详情
  exportExcel: '/product/commission/exportExcel',
  //  产品推介费导出
  getDropDownList: '/product/getDropDownList',
  //  产品下拉选择
  // 推荐费比例
  commissionFormulaSave: '/commission/formula/rule/save',
  //  推荐费比例新增或编辑
  commissionFormulaList: '/commission/formula/rule/list',
  //  推荐费比例列表
  commissionFormulaGetInfo: '/commission/formula/rule/getInfo',
  //  推荐费比例详情
  formulaExportExcel: '/commission/formula/rule/exportExcel',
  //  推介费比例导出
  formulaDropDownList: '/commission/formula/rule/formulaDropDownList',
  //  推介费比例公式下拉选择列表
  //  合同推介费
  commissionSavePlanAndApplyBatch: '/commission/v3/savePlanAndApplyBatch',
  //  批量创建自定义计划
  commissionReferralGetInfo: '/commission/v3/getInfo',
  //   推介费申请详情
  commissionSubmitPlanAndApplyBatch: '/commission/v3/submitPlanAndApplyBatch',
  //   提交自定义计划
  commissionListApplication: '/commission/v3/listApplication',
  //  推介费申请列表
  commissionExportExcelApplication: '/commission/v3/exportExcelApplication',
  //  导出推介费计划列表
  commissionGetWaitAllocation: '/commission/v3/getWaitAllocation',
  //   获取待分配计划
  commissionSaveAllocation: '/commission/v3/saveAllocationBatch',
  //   批量保存分配
  commissionExportExcel: '/commission/v3/exportExcel',
  //   查询导出
  commissionAudit: '/commission/v3/audit',
  //   审核
  commissionRemovePlanAndApply: '/commission/v3/removePlanAndApply',
  //   删除计划和申请
  commissionListExpectCommission: '/commission/v3/listExpectCommission' //   查询合同预期推介费列表

};