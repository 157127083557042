import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.slice.js";
import useImageParse from '@/hooks/use-image-parse';
var PUBLIC_CONFIG = {
  labelWidth: 80
};
export var TOP_FORM = {
  name: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '跟进方式',
    props: {
      placeholder: '请选择跟进方式'
    },
    options: []
  }),
  type: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '客户名称'
  }),
  informationType: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '大区',
    props: {
      placeholder: '请选择客户等级'
    },
    options: []
  }),
  dept: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '营业部',
    props: {
      placeholder: '请选择营业部'
    },
    options: []
  }),
  joinTime: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '时间',
    props: {
      placeholder: '请选择时间'
    }
  })
};
export var TABLE_LABEL = [{
  label: '流水号',
  prop: 'id'
}, {
  label: '客户',
  prop: 'name',
  type: 'link'
}, {
  label: '创建人',
  prop: 'organizationName'
}, {
  label: '部门',
  prop: 'releaseStatus'
}, {
  label: '跟进方式',
  prop: 'classifyName',
  formatter: function formatter(row, column, cellValue) {
    /** 如果分类超过两个 用...省略 */
    if (cellValue) {
      var arr = cellValue.split(',');

      if (arr.length > 2) {
        return "".concat(arr.slice(0, 2).join(','), "...");
      } else {
        return cellValue;
      }
    }
  }
}, {
  label: '签到时间',
  prop: 'releaseTime'
}, {
  label: '签到地址',
  prop: 'title'
}, {
  label: '总结',
  prop: 'informationType'
}, {
  label: '创建时间',
  prop: 'releaseTime'
}];
export var TABLE_CONFIG = {
  isSelect: true,
  actions: [// {
  //   label: '新增',
  //   type: 'add',
  //   props: {
  //     size: 'small',
  //     type: 'primary',
  //   },
  // },
  {
    label: '导出',
    type: 'export',
    props: {
      size: 'small' // type: 'primary',

    }
  }, {
    label: '删除',
    type: 'delete',
    props: {
      size: 'small',
      type: 'danger'
    }
  }, {
    label: '刷新',
    type: 'refresh',
    props: {
      size: 'small' // type: 'primary',

    }
  }]
};