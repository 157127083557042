import ENUM from '@/maps/enum/enum';
import { getEnumList } from '@api/common/common';
var VAL_WIDTH = '120px';
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
export var FILTER_FORMS = [// {
  //     label: '机构ID',
  //     valueKey: 'orgCd',
  //     value: '',
  //     type: 'input',
  //     props: {
  //         type: 'text',
  //         style: {
  //             width: VAL_WIDTH,
  //         },
  //     },
  // }, {
  //     label: '名称',
  //     valueKey: 'orgName',
  //     value: '',
  //     type: 'input',
  //     props: {
  //         type: 'text',
  //         style: {
  //             width: VAL_WIDTH,
  //         },
  //     },
  // }, {
  //     label: '类型',
  //     valueKey: 'orgType',
  //     value: [],
  //     type: 'select',
  //     method: getEnumList,
  //     params: {
  //         // 请求时用的参数
  //         enumCd: ENUM.ENUM_CD_0113,
  //     },
  //     props: {
  //         clearable: true,
  //         style: {
  //             width: VAL_WIDTH,
  //         },
  //         multiple: false,
  //     },
  // }, {
  //     label: '启用',
  //     valueKey: 'enabled',
  //     value: '',
  //     type: 'select',
  //     options: [{ value: '0', text: '停用' }, { value: '1', text: '启用' }],
  //     props: {
  //         type: 'text',
  //         clearable: true,
  //         style: {
  //             width: VAL_WIDTH,
  //         },
  //     },
  // }, 
];