import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
// 机构
import request from '@/plugin/axios';
import { blobRequest } from '@/plugin/axios';
import { formatDate } from '@/libs/crm/format';
import func from '@/maps/url/rm/org';
import util from '@/libs/util';
export function listOrgTree(params) {
  return request.post(func.getOrgTree, {
    body: params
  }).then(function (res) {
    var _res$data = res.data,
        data = _res$data === void 0 ? {} : _res$data;

    var assembleTree = function assembleTree(arr) {
      return arr.map(function (item) {
        var _item$orgName = item.orgName,
            orgName = _item$orgName === void 0 ? '' : _item$orgName,
            _item$orgCd = item.orgCd,
            orgCd = _item$orgCd === void 0 ? '' : _item$orgCd,
            _item$children = item.children,
            children = _item$children === void 0 ? [] : _item$children;

        var orgItem = _objectSpread(_objectSpread({}, item), {}, {
          label: orgName,
          value: orgCd,
          orgCd: orgCd
        }); // 如果有子菜单，则遍历子菜单


        if (children.length) {
          orgItem.children = assembleTree(children);
        }

        return orgItem;
      });
    };

    return assembleTree([data] || []);
  });
} // TODO 新增组织机构

export function addOrg(params) {
  return request.post(func.add, {
    body: params
  });
} // TODO 编辑组织机构

export function editOrg(params) {
  return request.post(func.edit, {
    body: params
  });
} // TODO 删除组织机构

export function remove(params) {
  return request.post(func.remove, {
    body: params
  });
} // TODO 获取组织机构详情

export function getInfo(params) {
  return request.post(func.getInfo, {
    body: params
  });
} // getOrgTree 机构树

export function getOrgTree(params) {
  return request.post(func.getOrgTree, {
    body: params
  });
} // getOrgTree 机构树全树，没有权限管理

export function getAllTree(params) {
  return request.post(func.getOrgTree, {
    body: params
  });
} // TODO 导出Excel

export function exportExcel(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcel, {
    body: params
  }).then(function (blob) {
    util.download(blob, '机构' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
}