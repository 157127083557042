import "core-js/modules/es.object.to-string.js";
// 银行流水
import request from '@/plugin/axios';
import { blobRequest } from '@/plugin/axios';
import util from '@/libs/util';
import { formatUserDataPromise } from '@/libs/crm/format';
import func from '@/maps/url/crm/bank/bill'; // TODO 保存

export function save(params) {
  var re = request.post(func.save, {
    body: params
  });
  return re;
} // TODO 批量添加

export function batchAdd(params) {
  var re = request.post(func.batchAdd, {
    body: params
  });
  return re;
} // TODO 删除

export function remove(params) {
  var re = request.post(func.remove, {
    body: params
  });
  return re;
} // TODO 列表

export function list(params, formatType) {
  var re = request.post(func.list, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 详情

export function getInfo(params, formatType) {
  var re = request.post(func.getInfo, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 解析流水数据

export function parse(params) {
  var re = request.post(func.parse, {
    body: params
  });
  return re;
} // TODO 导出Excel

export function exportExcel(params, excelName) {
  return blobRequest.post(func.exportExcel, {
    body: params
  }).then(function (blob) {
    util.download(blob, excelName);
  }).catch(function (e) {
    return Promise.reject(e);
  });
}