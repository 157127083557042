var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page-table" },
    [
      _c("j-page", {
        ref: "jPage",
        attrs: {
          filters: _vm.topForm,
          "filters-data": _vm.filtersData,
          filtersFormatter: _vm.filtersFormatter,
          table: _vm.tableConfig,
          "table-label": _vm.tableLabel,
          "http-config": _vm.httpConfig
        },
        on: {
          "update:filtersData": function($event) {
            _vm.filtersData = $event
          },
          "update:filters-data": function($event) {
            _vm.filtersData = $event
          },
          "table-row-change": _vm.tableRowChange,
          "table-click": _vm.tableClick,
          "table-row-click": _vm.tableRowClick
        },
        scopedSlots: _vm._u([
          {
            key: "auditStatus",
            fn: function(scope) {
              return [
                scope.row.auditStatus === _vm.auditStatus.PASS
                  ? _c(
                      "div",
                      {
                        staticClass: "review-text",
                        on: {
                          click: function($event) {
                            return _vm.reviewList(scope.row)
                          }
                        }
                      },
                      [_vm._v(" 审核通过 ")]
                    )
                  : _vm._e(),
                scope.row.auditStatus === _vm.auditStatus.WAITING
                  ? _c("div", [_vm._v("未审核")])
                  : _vm._e(),
                scope.row.auditStatus === _vm.auditStatus.REJECT
                  ? _c(
                      "div",
                      {
                        staticClass: "review-text",
                        on: {
                          click: function($event) {
                            return _vm.reviewList(scope.row)
                          }
                        }
                      },
                      [
                        _vm._v(" 审核不通过 "),
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "bottom-start",
                              title: "审核不通过原因:",
                              trigger: "hover",
                              "popper-class": "popover-font",
                              content: scope.row.auditRemark
                            }
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                slot: "reference",
                                icon: "el-icon-chat-dot-round",
                                type: "text"
                              },
                              slot: "reference"
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            }
          }
        ])
      }),
      _c(
        "j-dialog",
        {
          attrs: {
            visible: _vm.showQrcode,
            footActive: false,
            width: "300px",
            height: "300px",
            title: "二维码查看"
          },
          on: {
            "update:visible": function($event) {
              _vm.showQrcode = $event
            }
          }
        },
        [_c("div", { staticClass: "qrcode", attrs: { id: "qrcode" } })]
      ),
      _c(
        "j-dialog",
        {
          attrs: { visible: _vm.readVisible, title: "查看" },
          on: {
            "update:visible": function($event) {
              _vm.readVisible = $event
            }
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "el-button",
                    { attrs: { size: "small" }, on: { click: _vm.close } },
                    [_vm._v("取消")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [_c("Read", { attrs: { activityId: _vm.currentId } })],
        1
      ),
      _c(
        "j-dialog",
        {
          attrs: {
            visible: _vm.showAttachment,
            width: "400px",
            height: "400px",
            title: "上传报名信息登记表"
          },
          on: {
            "update:visible": function($event) {
              _vm.showAttachment = $event
            }
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.addForm }
                    },
                    [_vm._v("确定")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("j-form-uploadFile", {
            attrs: {
              placeholder: "最多可传1份，excel大小不超过1MB",
              fileType: ["xlsx"]
            },
            on: { change: _vm.upLoadFile }
          })
        ],
        1
      ),
      _c(
        "j-dialog",
        {
          attrs: { visible: _vm.logVisible, title: "审批状态", height: "560" },
          on: {
            "update:visible": function($event) {
              _vm.logVisible = $event
            }
          }
        },
        [
          _c(
            "el-steps",
            { attrs: { direction: "vertical", active: _vm.reviewLog.length } },
            _vm._l(_vm.reviewLog, function(item, index) {
              return _c("el-step", {
                key: index,
                attrs: {
                  title: _vm.computedTitle(item),
                  description: item.createTime
                }
              })
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }