import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _defineProperty from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { FILTER_PROPS, FILTER_FORMS } from './option/filter'; // 过滤条件的form

import ORG_LIST from './option/list'; // 表头

import DynamicForm from '@/components/common/dynamic-form';
import DynamicTable from '@/components/common/dynamic-table';
import DialogAdd from './components/dialog-add';
import { listOrgTree, remove, exportExcel } from '@api/rm/org';
export default {
  name: 'org',
  components: {
    DynamicForm: DynamicForm,
    DynamicTable: DynamicTable,
    DialogAdd: DialogAdd
  },
  data: function data() {
    var _ref;

    return _ref = {
      loading: false,
      filename: __filename,
      filterProps: FILTER_PROPS,
      filterForms: FILTER_FORMS,
      tableData: [],
      query: {},
      pagination: {
        use: false
      }
    }, _defineProperty(_ref, "loading", false), _defineProperty(_ref, "headers", ORG_LIST), _defineProperty(_ref, "table", {
      rowKey: 'orgCd',
      treeProps: {
        children: 'children'
      }
    }), _defineProperty(_ref, "showAddDialog", false), _defineProperty(_ref, "currentClick", {}), _defineProperty(_ref, "isEdit", false), _ref;
  },
  methods: {
    init: function init() {
      this.tableData = [];
      this.loadData();
    },
    //初始化搜索条件
    initFilter: function initFilter() {
      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);
    },
    //点击刷新
    clickRefresh: function clickRefresh() {
      this.$refs.jTable.onRefresh();
      this.$message({
        message: "数据已刷新",
        type: "info",
        duration: 500
      });
    },
    //点击重置
    clickReset: function clickReset() {
      this.$refs.form.reset();
    },
    // TODO 搜索
    clickSearch: function clickSearch() {
      this.initFilter();
      this.$refs.jTable.pageChange(1);
    },
    // TODO 获取组织树
    loadData: function loadData() {
      var _this = this;

      this.loading = true;
      var param = {
        query: null //查全部

      };
      listOrgTree(param).then(function (res) {
        _this.loading = false;
        _this.tableData = res; // console.dir(this.tableData);
        // console.log("-------------");        
        //         let weakSet = new WeakSet();
        //         weakSet.add(this.tableData[0]);
        //         weakSet.add(this.tableData[0].children[0]);
        //         weakSet.add(this.tableData[0].children[0]);
        //         console.dir(weakSet);
      });
    },
    // TODO 点击编辑
    clickEdit: function clickEdit(item) {
      this.isEdit = true;
      this.currentClick = item;
      this.showAddDialog = true;
    },
    // TODO 点击新增
    clickAdd: function clickAdd(item) {
      this.isEdit = false;
      this.currentClick = item;
      this.showAddDialog = true;
    },
    // TODO 点击删除
    clickDelete: function clickDelete(item) {
      var _this2 = this;

      var param = {
        orgCd: item.orgCd
      };
      this.$confirm('确定删除该机构?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        remove(param).then(function () {
          _this2.$message({
            type: 'success',
            message: '删除成功!'
          });

          _this2.init();
        });
      });
    },
    //导出
    clickExport: function clickExport() {
      var _this3 = this;

      this.$confirm("确定数据导出吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this3.initFilter();

        var param = {
          query: _this3.query
        };
        exportExcel(param).catch(function (e) {
          _this3.$message({
            message: e,
            type: "error"
          });
        });
      }).catch(function () {});
    }
  }
};