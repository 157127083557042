//合同日志类型
export default {
  BUY: '1',
  // 认购
  COUNT: '2',
  // 核算
  APPLY: '3',
  // 赎回
  RENEW: '4',
  // 续约
  CHANGE: '5',
  // 变更
  INTEREST: '6',
  // 付息
  GIFT: '7',
  // 礼品
  PAPER: '8',
  // 纸合同
  CLEAR: '9',
  // 清算
  COMMISSION: '10' //推介费

};