var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("d2-container", { staticClass: "page-table" }, [
    _c(
      "div",
      { staticClass: "status" },
      [
        _c("span", [_vm._v("*")]),
        _vm._v(" 是否开启积分： "),
        _c("el-switch", {
          model: {
            value: _vm.form.status,
            callback: function($$v) {
              _vm.$set(_vm.form, "status", $$v)
            },
            expression: "form.status"
          }
        })
      ],
      1
    ),
    _c("div", { staticClass: "basicsRule" }, [
      _c("div", { staticClass: "basicsRule-item" }, [_vm._v("基础规则：")]),
      _c(
        "div",
        { staticClass: "basicsRule-content" },
        [
          _c("j-table", {
            ref: "j_table",
            attrs: {
              table: _vm.table,
              tableLabel: _vm.tableLabel,
              tableData: _vm.tableData
            },
            on: { "row-change": _vm.rowChange }
          }),
          _c("div", { staticClass: "text" }, [
            _vm._v(
              "注：周期为“一次”，表示该项为一次性奖励项目只奖励一次；周期内最多奖励次数为0，表示该项不限制；奖励积分为0，表示该项不奖励积分。"
            )
          ])
        ],
        1
      )
    ]),
    _c("div", { staticClass: "basicsRule" }, [
      _c("div", { staticClass: "basicsRule-item" }, [_vm._v("其他规则：")]),
      _c("div", { staticClass: "basicsRule-content" }, [
        _c(
          "div",
          { staticClass: "elseRule" },
          [
            _vm._v(" 每邀请 "),
            _c("el-input", {
              staticClass: "input",
              model: {
                value: _vm.form.inviterCount,
                callback: function($$v) {
                  _vm.$set(_vm.form, "inviterCount", $$v)
                },
                expression: "form.inviterCount"
              }
            }),
            _vm._v("人 额外赠 "),
            _c("el-input", {
              staticClass: "input",
              model: {
                value: _vm.form.inviteRewardScore,
                callback: function($$v) {
                  _vm.$set(_vm.form, "inviteRewardScore", $$v)
                },
                expression: "form.inviteRewardScore"
              }
            }),
            _vm._v(" 积分 ")
          ],
          1
        ),
        _c(
          "div",
          [
            _vm._v(" 积分有效期： "),
            _c("el-date-picker", {
              attrs: { type: "daterange", "value-format": "yyyy-MM-dd" },
              model: {
                value: _vm.value1,
                callback: function($$v) {
                  _vm.value1 = $$v
                },
                expression: "value1"
              }
            })
          ],
          1
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "foot-button" },
      [
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.cancel } },
          [_vm._v("返回")]
        ),
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.confirm } },
          [_vm._v("确定")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }