var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page-table" },
    [
      _c("j-page", {
        ref: "jPage",
        attrs: {
          filters: _vm.topForm,
          "filters-data": _vm.filtersData,
          table: _vm.tableConfig,
          "table-label": _vm.tableLabel,
          "http-config": _vm.httpConfig
        },
        on: {
          "update:filtersData": function($event) {
            _vm.filtersData = $event
          },
          "update:filters-data": function($event) {
            _vm.filtersData = $event
          },
          "table-click": _vm.tableClick,
          "table-row-click": _vm.tableRowClick
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            width: "25%",
            modal: false,
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.close
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "dialog-title",
              attrs: { slot: "title" },
              slot: "title"
            },
            [
              _c(
                "el-row",
                [
                  _c("span", { staticClass: "dialog-title-text" }, [
                    _vm._v("客户信息")
                  ]),
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right" },
                      attrs: { type: "primary" },
                      on: { click: _vm.skip }
                    },
                    [_vm._v("跳转详情")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c(
                "el-descriptions",
                { attrs: { direction: "horizontal", column: 1, border: "" } },
                [
                  _c("el-descriptions-item", { attrs: { label: "客户姓名" } }, [
                    _vm._v(_vm._s(_vm.CustomerInfo.customerName))
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "性别" } }, [
                    _vm._v(_vm._s(_vm.CustomerInfo.sexValue))
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "年龄" } }, [
                    _vm._v(_vm._s(_vm.CustomerInfo.age))
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "客户电话" } }, [
                    _vm._v(_vm._s(_vm.CustomerInfo.mobile))
                  ]),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "家庭住址", span: 2 } },
                    [_vm._v(_vm._s(_vm.CustomerInfo.address))]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "客户类型", span: 2 } },
                    [_vm._v(_vm._s(_vm.CustomerInfo.customerTypeValue))]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "客户等级", span: 2 } },
                    [_vm._v(_vm._s(_vm.CustomerInfo.customerLevelValue))]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "客户年限", span: 2 } },
                    [_vm._v(_vm._s(_vm.CustomerInfo.customerYearLimit))]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "理顾", span: 2 } },
                    [_vm._v(_vm._s(_vm.CustomerInfo.agentUserName))]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "部门", span: 2 } },
                    [_vm._v(_vm._s(_vm.CustomerInfo.orgName))]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "j-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading.fullscreen.lock",
              value: _vm.fullscreenLoading,
              expression: "fullscreenLoading",
              modifiers: { fullscreen: true, lock: true }
            }
          ],
          attrs: {
            visible: _vm.conflictCustomerVisible,
            title: _vm.conflictCustomerTitle,
            width: "60%"
          },
          on: {
            "update:visible": function($event) {
              _vm.conflictCustomerVisible = $event
            },
            confirm: _vm.mergeCustomer,
            close: _vm.closeConflictCustomer
          }
        },
        [
          _c(
            "el-table",
            {
              ref: "singleTable",
              attrs: {
                "highlight-current-row": "",
                data: _vm.conflictCustomerData
              },
              on: { "selection-change": _vm.handleCurrentChange }
            },
            [
              _vm._v(' style="width: 100%" > '),
              _c("el-table-column", { attrs: { type: "selection" } }),
              _c("el-table-column", {
                attrs: { prop: "customerCode", label: "客户编码" }
              }),
              _c("el-table-column", {
                attrs: { prop: "customerName", label: "客户姓名" }
              }),
              _c("el-table-column", {
                attrs: { prop: "orgName", label: "组织名称" }
              }),
              _c("el-table-column", {
                attrs: { prop: "agentUserName", label: "理顾名称" }
              }),
              _c("el-table-column", {
                attrs: { prop: "sexValue", label: "性别" }
              }),
              _c("el-table-column", { attrs: { prop: "age", label: "年龄" } }),
              _c("el-table-column", {
                attrs: { prop: "birthplaceValue", label: "出生地" }
              }),
              _c("el-table-column", {
                attrs: { prop: "mobile", label: "手机号码" }
              }),
              _c("el-table-column", {
                attrs: { prop: "address", label: "地址" }
              }),
              _c("el-table-column", {
                attrs: { prop: "customerTypeValue", label: "客户类型" }
              }),
              _c("el-table-column", {
                attrs: { prop: "customerLevelValue", label: "客户等级" }
              }),
              _c("el-table-column", {
                attrs: { prop: "customerYearLimit", label: "客户年限" }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "j-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading.fullscreen.lock",
              value: _vm.allotConfirmLoading,
              expression: "allotConfirmLoading",
              modifiers: { fullscreen: true, lock: true }
            }
          ],
          attrs: { visible: _vm.openAllot, title: "选择理顾" },
          on: {
            "update:visible": function($event) {
              _vm.openAllot = $event
            },
            confirm: _vm.allotConfirm,
            close: _vm.AddUserIdHide
          }
        },
        [
          _c(
            "el-form",
            {
              attrs: { inline: true, model: _vm.userSelectData, size: "small" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c("el-cascader", {
                    attrs: {
                      options: _vm.orgTreeData,
                      props: _vm.orgProps,
                      placeholder: "请选择部门",
                      filterable: "",
                      clearable: ""
                    },
                    model: {
                      value: _vm.userSelectData.orgCd,
                      callback: function($$v) {
                        _vm.$set(_vm.userSelectData, "orgCd", $$v)
                      },
                      expression: "userSelectData.orgCd"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入姓名" },
                    model: {
                      value: _vm.userSelectData.userName,
                      callback: function($$v) {
                        _vm.$set(_vm.userSelectData, "userName", $$v)
                      },
                      expression: "userSelectData.userName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.userSearch }
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.userTableData, "tooltip-effect": "dark" },
              on: { "selection-change": _vm.handleSelectionChange }
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" }
              }),
              _c("el-table-column", {
                attrs: { prop: "userName", label: "姓名" }
              }),
              _c("el-table-column", {
                attrs: { prop: "orgName", label: "部门" }
              }),
              _c("el-table-column", {
                attrs: { prop: "userCode", label: "工号" }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }