//客户银行卡信息
export default {
  listCustomerBankCard: '/customer/bankCard/list',
  //列表
  getCustomerBankCard: '/customer/bankCard/getInfo',
  //详情
  updateCustomerBankCard: '/customer/bankCard/updateInfo',
  //第三方接口更新详情
  checkBankCard3c: '/customer/bankCard/checkBankCard3c',
  //验证3要素
  getCheckBankCardInfo: '/customer/bankCard/getCheckBankCardInfo' //获取银行卡验证信息

};