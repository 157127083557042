var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "dialog-info",
      attrs: {
        top: "20vh",
        width: "930px",
        title: "发送快递",
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.propsModel,
            border: "",
            "row-style": { height: "20px" },
            "cell-style": { padding: "6px 0" },
            height: "250"
          }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "contractCd", label: "合同编号", width: "160" }
          }),
          _c("el-table-column", {
            attrs: { prop: "customerName", label: "客户姓名", width: "120" }
          }),
          _c("el-table-column", {
            attrs: { prop: "productName", label: "产品", width: "120" }
          }),
          _c("el-table-column", {
            attrs: { prop: "timeLimit", label: "期限", width: "60" }
          }),
          _c("el-table-column", {
            attrs: { prop: "startDate", label: "开始日期", width: "100" }
          }),
          _c("el-table-column", {
            attrs: { prop: "endDate", label: "结束日期", width: "100" }
          }),
          _c("el-table-column", {
            attrs: { prop: "orgName", label: "机构", width: "170" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "60" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.clickRemove(scope.row)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-divider", { attrs: { "content-position": "left" } }, [
        _vm._v("快递信息")
      ]),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formModel,
            rules: _vm.rules,
            "label-width": "100px",
            size: "mini"
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "发件方", prop: "sendOrgCd" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          value: _vm.userInfo.orgName,
                          disabled: true
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "发件人", prop: "sender" } },
                    [
                      _c("el-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.formModel.sender,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "sender", $$v)
                          },
                          expression: "formModel.sender"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "收件人", prop: "receiver" } },
                    [
                      _c(
                        "el-input",
                        {
                          model: {
                            value: _vm.formModel.receiver,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "receiver", $$v)
                            },
                            expression: "formModel.receiver"
                          }
                        },
                        [
                          _c(
                            "template",
                            { slot: "append" },
                            [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "right",
                                    width: "850",
                                    trigger: "click"
                                  },
                                  model: {
                                    value: _vm.showSearchUser,
                                    callback: function($$v) {
                                      _vm.showSearchUser = $$v
                                    },
                                    expression: "showSearchUser"
                                  }
                                },
                                [
                                  _c("search-user", {
                                    ref: "searchUser",
                                    on: { onSelectItem: _vm.popSelectUser }
                                  }),
                                  _c("el-button", {
                                    attrs: {
                                      slot: "reference",
                                      type: "primary",
                                      icon: "el-icon-zoom-in",
                                      plain: ""
                                    },
                                    slot: "reference"
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "发件日期", prop: "sendDate" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "date",
                          placeholder: "选择日期",
                          "value-format": "yyyy-MM-dd"
                        },
                        model: {
                          value: _vm.formModel.sendDate,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "sendDate", $$v)
                          },
                          expression: "formModel.sendDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "快递公司", prop: "expressCompany" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.formModel.expressCompany,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "expressCompany", $$v)
                            },
                            expression: "formModel.expressCompany"
                          }
                        },
                        _vm._l(_vm.selectData.expressCompany, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.text, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "快递单号", prop: "expressNo" } },
                    [
                      _c("el-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.formModel.expressNo,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "expressNo", $$v)
                          },
                          expression: "formModel.expressNo"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { plain: "", size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                plain: "",
                size: "mini",
                loading: _vm.loading
              },
              on: { click: _vm.send }
            },
            [_vm._v("确认发件")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }