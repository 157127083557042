// 表头
export default [{
  label: '名称',
  prop: 'menuName',
  width: '200px'
}, {
  label: '上级菜单',
  prop: 'parentMenuName',
  width: '150px'
}, {
  label: '类型',
  prop: 'typeText',
  width: '80px'
}, {
  label: 'url',
  prop: 'url',
  width: '500px'
}, {
  label: '启用',
  prop: 'enabledText',
  width: '80px'
}, {
  label: '前端按钮权限控制码',
  prop: 'permissionCode',
  width: '150px'
}, {
  label: '排序',
  prop: 'sort',
  width: '100px'
}];