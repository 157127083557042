


























































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/commissionV3/allocation/index.vue"
}
