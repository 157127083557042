//续约
export default {
  create: '/renew/create',
  // 新增
  isContractCanRenew: '/renew/isContractCanRenew',
  // 检查合同是否可以申请续约
  save: '/renew/save',
  // 保存
  submit: '/renew/submit',
  // 提交
  remove: '/renew/remove',
  //删除
  auditService: '/renew/auditService',
  //客服审核
  auditCount: '/renew/auditCount',
  //结算审核
  auditFinance: '/renew/auditFinance',
  //财务审核
  auditMoney: '/renew/auditMoney',
  //资金审核    
  listRenewCount: '/renew/listRenewCount',
  //手工续约申请查询
  list: '/renew/list',
  //列表
  getInfo: '/renew/getInfo',
  //明细
  exportExcel: '/renew/exportExcel' // 导出Excel

};