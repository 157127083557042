// 表头
export default [{
  label: '编号',
  prop: 'code',
  width: '80px'
}, {
  label: '名称',
  prop: 'name',
  width: '150px'
}, {
  label: '数据权限',
  prop: 'dataTypeText',
  width: '120px'
}, {
  label: '描述',
  prop: 'description',
  width: '400px'
}, {
  label: '启用',
  prop: 'enabledText',
  width: '80px'
}, {
  label: '排序',
  prop: 'sort',
  width: '60px'
}];