//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from '@/mixins/dialog';
import crmMixin from '@/mixins/crm';
import LOG_TYPE from '@/maps/enum/contract-log-type';
import ATTACHMENT_TYPE from '@/maps/enum/attachment-type';
import AUDIT from '@/maps/enum/status/audit';
import DATA_FORMAT_TYPE from '@/maps/enum/data-format-type';
import Attachment from '@/components/business/attachment';
import AttachmentAudio from '@/components/business/attachment/audio';
import ContractLog from '@/components/business/contract-log';
import ContractGoldWeight from '@/components/business/contract-gold-weight';
import { setExtendAttribute } from '@/libs/crm/crm';
import { getInfo as _getInfo, auditService } from '@api/crm/buy/buy'; //合同认购

import AttachmentVideo from '@/components/business/attachmentVideo';
export default {
  name: 'buy-service-audit',
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {
    Attachment: Attachment,
    AttachmentAudio: AttachmentAudio,
    ContractLog: ContractLog,
    ContractGoldWeight: ContractGoldWeight,
    AttachmentVideo: AttachmentVideo
  },
  mixins: [dialogMixin, crmMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      formModel: {},
      //表单
      tabActive: 'tabContract',
      //标签框默认选中
      collapseActive: ['1'],
      //信息折叠默认展开
      attachmentType: ATTACHMENT_TYPE.CONTRACT_STAFF,
      //附件类型
      attachmentTypeAudio: ATTACHMENT_TYPE.CONTRACT_AUDIO,
      //附件类型：合同录音
      attachmentVideoType: ATTACHMENT_TYPE.CONTRACT_VIDEO,
      //附件类型：合同视频
      isAttachmentEdit: false,
      //附件是否可编辑
      showLogDialog: false,
      contractLogType: LOG_TYPE.BUY,
      goldWeightDetail: {},
      //克重明细
      attachmentOpened: '',
      //附件打开状态
      rules: {}
    };
  },
  computed: {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.getInfo();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tabActive = 'tabContract'; //默认打开第一个标签页
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    onAttachmentOpen: function onAttachmentOpen() {
      //初始化标签
      this.tabActive = 'tabContract';
      this.attachmentOpened = 'dialog-position';
    },
    clickAuditFail: function clickAuditFail() {
      //审核不通过
      if (this.formModel.advice == null || this.formModel.advice == '') {
        this.$message('请填写审核意见');
      } else {
        this.audit(AUDIT.NO.value);
      }
    },
    clickAudit: function clickAudit() {
      //审核通过
      this.audit(AUDIT.YES.value);
    },
    // TODO 确定
    audit: function audit(result) {
      var _this = this;

      //this.$refs.form.validate(valid => {
      //  if (valid) {
      var param = {
        contractId: this.model.contractId,
        audit: result,
        advice: this.formModel.advice
      };
      this.loading = true;
      auditService(param).then(function (res) {
        _this.loading = false;
        _this.showDialog = false;

        _this.$emit('refresh');
      }).catch(function (e) {
        _this.loading = false;
      }); //  } else {
      //    return false;
      //  }
      //});
    },
    // TODO 获取详情
    getInfo: function getInfo() {
      var _this2 = this;

      var contractId = this.propsModel.contractId; //this.loading = true;

      _getInfo({
        contractId: contractId
      }, DATA_FORMAT_TYPE.AMOUNT).then(function (res) {
        //this.loading = false;
        var _res$data = res.data,
            data = _res$data === void 0 ? {} : _res$data;
        _this2.model = data;
        console.log('this.model', _this2.model);
        setExtendAttribute(_this2.model); //包装必要展示字段
      }).catch(function (e) {
        _this2.loading = false;
      });
    },
    showGoldWeight: function showGoldWeight() {
      //显示克重明细
      this.showGoldWeightDialog = true;
    },
    showLog: function showLog() {
      //显示审核日志
      this.showLogDialog = true;
    }
  }
};