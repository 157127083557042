import _toConsumableArray from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from '@/mixins/dialog';
import ROLE from '@/maps/enum/role';
import { list as listRole, setUserRole, listUserRole } from '@api/rm/role';
export default {
  name: 'dialog-person-role',
  props: {
    // 当前职员
    person: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      formModel: {},
      tableData: [],
      roleList: [],
      //全部角色列表
      searchKey: null,
      //搜索key
      radio: null,
      //单选
      checkboxRows: [],
      rules: {
        loginName: [{
          required: true,
          message: '请填写客户系统账号',
          trigger: 'input'
        }],
        password: [{
          required: true,
          message: '请填写客户密码',
          trigger: 'input'
        }]
      }
    };
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
        }
      },
      immediate: true
    },
    checkboxRows: {
      //列表的复选框默认选中
      handler: function handler(val) {
        var _this = this;

        if (val != null && val.length > 0) {
          this.$nextTick(function () {
            val.forEach(function (n) {
              if (n != null) {
                var row = _this.tableData.find(function (item) {
                  return item.id == n.id;
                });

                _this.$refs.multipleTable.toggleRowSelection(row, true);
              }
            });
          });
        }
      }
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.searchKey = null;
      this.checkboxRows = [];
      this.radio = null;
      this.loadData();
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
      this.showDialog = false;
    },
    //加载数据
    loadData: function loadData() {
      var _this2 = this;

      if (this.person.personuuid != null) {
        this.tableData = [];
        this.roleList = [];
        var paramAll = {
          page: {
            pageNumber: 0,
            pageSize: 999
          },
          query: {
            enabled: '1'
          }
        };
        var paramUser = {
          userId: this.person.personuuid
        };
        var promiseArr = []; //多个请求数组

        promiseArr[0] = listRole(paramAll); //全部角色列表

        promiseArr[1] = listUserRole(paramUser); //当前用户的角色

        Promise.all(promiseArr).then(function (res) {
          _this2.loading = false; //列表排除管理员，避免被误选

          res[0].data.forEach(function (n) {
            if (ROLE.ADMIN.value != n.code) {
              _this2.roleList.push(n);
            }
          });
          _this2.tableData = _toConsumableArray(_this2.roleList); //当前用户已选择
          //多选

          if (res[1].data != null) {
            _this2.checkboxRows = res[1].data;
          } //单选

          /*if(res[1].data!=null && res[1].data.length>0) {
            this.radio = res[1].data[0].id;
          }*/

        });
      }
    },
    // TODO 确定
    clickSave: function clickSave() {
      var _this3 = this;

      //多选
      if (this.checkboxRows.length == 0) {
        this.$message('请选择角色');
      } else {
        var arrayRoleId = [];
        this.checkboxRows.forEach(function (n) {
          if (n != null) {
            arrayRoleId.push(n.id);
          }
        });
        var param = {
          userId: this.person.personuuid,
          arrayRoleId: arrayRoleId
        };
        setUserRole(param).then(function (res) {
          _this3.loading = false;

          _this3.$message({
            message: '设置角色成功',
            type: 'success'
          });

          _this3.dialogClose();
        });
      } //单选

      /*
      if(this.radio==null || this.radio=='') {
        this.$message("请选择角色");
      } else {
          let arrayRoleId = [];
          arrayRoleId[0] = this.radio;          
          let param = {
            userId: this.person.personuuid,
            arrayRoleId: arrayRoleId
          };
          setUserRole(param).then(res=> {
            this.loading = false;            
            this.$message({ message: "设置角色成功", type: "success" });              
            this.dialogClose();
          });           
      }*/

    },
    //复选框选中
    handleSelectionChange: function handleSelectionChange(rows) {
      this.checkboxRows = rows;
    },
    //健盘输入搜索角色
    keyupRoleSearch: function keyupRoleSearch() {
      var searchKey = this.searchKey;

      if (searchKey != null) {
        this.tableData = [];

        for (var i = 0; i < this.roleList.length; i++) {
          var n = this.roleList[i];

          if (n.name.indexOf(searchKey) > -1) {
            this.tableData.push(n);
          }
        }
      }
    }
  }
};