import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { TABLE, PAGINATION, MULTIPLE } from './option';
export default {
  name: 'dynamic-table',
  props: {
    // 表头
    headers: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 表格配置
    table: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    // 多选配置
    multiple: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    // 分页配置
    pagination: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    data: {
      type: [Array, Object],
      default: function _default() {
        return [];
      }
    },
    // loading图标
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      // 分页参数
      page: {}
    };
  },
  computed: {
    // TODO 表格配置
    tableConfig: function tableConfig() {
      return _objectSpread(_objectSpread({}, TABLE), this.table);
    },
    // 分页配置
    paginationConfig: function paginationConfig() {
      return _objectSpread(_objectSpread({}, PAGINATION), this.pagination);
    },
    // 多选配置
    multipleConfig: function multipleConfig() {
      return _objectSpread(_objectSpread({}, MULTIPLE), this.multiple);
    }
  },
  methods: {
    // TODO 初始化参数
    init: function init() {
      var page = this.paginationConfig.page;
      this.page = _objectSpread({}, page);
    },
    // TODO 选中一行
    currentChange: function currentChange(val) {
      val = val || {};
      val = JSON.parse(JSON.stringify(val));
      this.$emit('current-change', val);
    },
    // TODO 多行选中
    selectionChange: function selectionChange(val) {
      val = JSON.parse(JSON.stringify(val));
      this.$emit('selection-change', val);
    },
    // TODO 切换每页条数
    sizeChange: function sizeChange(val) {
      this.page = {
        pageNumber: 1,
        pageSize: val
      };
      this.load();
    },
    // TODO 当前显示页数
    pageChange: function pageChange(val) {
      this.page.pageNumber = val;
      this.load();
    },
    // TODO 切换排序
    sortChange: function sortChange(params) {
      this.$emit('sort-change', params);
    },
    // TODO 触发加载
    load: function load() {
      this.$emit('load', {
        page: this.page
      });
    },
    // TODO 触发操作事件
    operationHandler: function operationHandler(row, op) {
      this.$emit(op.event, row);
    },
    // TODO click类型触发事件
    clickHandler: function clickHandler(row, item, op) {
      var prop = item.prop;
      this.$emit('click:column', {
        row: row,
        prop: prop
      });
    },
    // TODO 设置选中项，multiple时可用
    setSelected: function setSelected(list) {
      var _this = this;

      var _this$tableConfig$mul = this.tableConfig.multiple,
          multiple = _this$tableConfig$mul === void 0 ? false : _this$tableConfig$mul; // 多选才进行选中操作

      if (multiple) {
        this.$nextTick(function () {
          list.forEach(function (row) {
            _this.$refs.table.toggleRowSelection(row, true);
          });
        });
      }
    },
    // TODO 多选，multiple时可用 选择禁用选项 SelectableDataName 禁用数据字段名 SelectableData 禁用判断值
    checkSelectable: function checkSelectable(row) {
      var _this$tableConfig = this.tableConfig,
          _this$tableConfig$mul2 = _this$tableConfig.multiple,
          multiple = _this$tableConfig$mul2 === void 0 ? false : _this$tableConfig$mul2,
          _this$tableConfig$Sel = _this$tableConfig.Selectable,
          Selectable = _this$tableConfig$Sel === void 0 ? false : _this$tableConfig$Sel,
          _this$tableConfig$Sel2 = _this$tableConfig.SelectableDataName,
          SelectableDataName = _this$tableConfig$Sel2 === void 0 ? '' : _this$tableConfig$Sel2,
          _this$tableConfig$Sel3 = _this$tableConfig.SelectableData,
          SelectableData = _this$tableConfig$Sel3 === void 0 ? '' : _this$tableConfig$Sel3;
      var checkSelectable = true;

      if (multiple && Selectable) {
        if (row[SelectableDataName] == SelectableData) {
          checkSelectable = false;
        }
      }

      return checkSelectable;
    },
    //刷新
    onRefresh: function onRefresh() {
      this.load();
    }
  },
  created: function created() {
    this.init(); // 加载自动初始化

    if (this.tableConfig.auto) {
      this.load();
    }
  }
};