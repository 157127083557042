





















































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/renew/new/oneToOne/index.vue"
}
