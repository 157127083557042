//查询条件
var VAL_WIDTH = '150px';
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
export var FILTER_FORMS = [{
  label: '对方户名',
  valueKey: 'accountName',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '交易日期',
  valueKey: 'tallyDateBegin',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'tallyDateEnd',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}];