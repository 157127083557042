//银行流水
export default {
  save: '/bank/bill/save',
  //保存
  batchAdd: '/bank/bill/batchAdd',
  //批量添加
  remove: '/bank/bill/remove',
  //删除
  list: '/bank/bill/list',
  //银行流水列表
  getInfo: '/bank/bill/getInfo',
  //详情
  parse: '/bank/bill/parse',
  //解析流水
  exportExcel: '/bank/bill/exportExcel' //导出Excel

};