var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "dialog-search-contract",
      attrs: {
        top: _vm.formModel.isBackOffice === "1" ? "10vh" : "4vh",
        width: "910px",
        title: "推介费人员",
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      [
        _c(
          "el-tabs",
          {
            model: {
              value: _vm.tabActive,
              callback: function($$v) {
                _vm.tabActive = $$v
              },
              expression: "tabActive"
            }
          },
          [
            _c(
              "el-tab-pane",
              { attrs: { label: "计划详情", name: "tabAllocation" } },
              [
                _c(
                  "el-collapse",
                  {
                    model: {
                      value: _vm.collapseActive,
                      callback: function($$v) {
                        _vm.collapseActive = $$v
                      },
                      expression: "collapseActive"
                    }
                  },
                  [
                    _c(
                      "el-collapse-item",
                      { attrs: { title: "合同信息", name: "1" } },
                      [
                        _c(
                          "el-row",
                          { staticClass: "block-col-4" },
                          [
                            _c("el-col", { attrs: { span: 5 } }, [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v(_vm._s(_vm.labelContractCd) + ":")
                              ]),
                              _vm._v(" " + _vm._s(_vm.model.contractCd) + " ")
                            ]),
                            _c("el-col", { attrs: { span: 5 } }, [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v("合同状态:")
                              ]),
                              _vm._v(
                                " " + _vm._s(_vm.model.contractStateText) + " "
                              )
                            ]),
                            _c("el-col", { attrs: { span: 5 } }, [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v("开始日期:")
                              ]),
                              _vm._v(" " + _vm._s(_vm.model.startDate) + " ")
                            ]),
                            _c("el-col", { attrs: { span: 5 } }, [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v("到期日期:")
                              ]),
                              _vm._v(" " + _vm._s(_vm.model.endDate) + " ")
                            ])
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { staticClass: "block-col-2" },
                          [
                            _c("el-col", { attrs: { span: 5 } }, [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v("客户名称:")
                              ]),
                              _vm._v(" " + _vm._s(_vm.model.customerName) + " ")
                            ]),
                            _c("el-col", { attrs: { span: 15 } }, [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v("机构:")
                              ]),
                              _vm._v(" " + _vm._s(_vm.model.orgName) + " ")
                            ])
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { staticClass: "block-col-4" },
                          [
                            _c("el-col", { attrs: { span: 5 } }, [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v("产品:")
                              ]),
                              _vm._v(" " + _vm._s(_vm.model.productName) + " ")
                            ]),
                            _c("el-col", { attrs: { span: 5 } }, [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v("合同金额:")
                              ]),
                              _vm._v(" " + _vm._s(_vm.model.amount) + " ")
                            ]),
                            _c("el-col", { attrs: { span: 5 } }, [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v("期限:")
                              ]),
                              _vm._v(" " + _vm._s(_vm.model.timeLimit) + " ")
                            ]),
                            _c("el-col", { attrs: { span: 5 } }, [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v("返还周期:")
                              ]),
                              _vm._v(" " + _vm._s(_vm.model.returnPeriod) + " ")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("el-divider"),
                    _c(
                      "el-collapse-item",
                      { attrs: { title: "推介费信息", name: "2" } },
                      [
                        _c(
                          "el-row",
                          { staticClass: "block-col-4" },
                          [
                            _c("el-col", { attrs: { span: 5 } }, [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v("推介费状态:")
                              ]),
                              _vm._v(" " + _vm._s(_vm.model.statusText) + " ")
                            ]),
                            _c("el-col", { attrs: { span: 5 } }, [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v("总次数:")
                              ]),
                              _vm._v(" " + _vm._s(_vm.model.totalTimes) + " ")
                            ]),
                            _c("el-col", { attrs: { span: 5 } }, [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v("第几次:")
                              ]),
                              _vm._v(" " + _vm._s(_vm.model.currentTimes) + " ")
                            ]),
                            _c("el-col", { attrs: { span: 5 } }, [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v("运营年度:")
                              ]),
                              _vm._v(" " + _vm._s(_vm.model.fiscalYear) + " ")
                            ])
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { staticClass: "block-col-4" },
                          [
                            _c("el-col", { attrs: { span: 5 } }, [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v("推介费类型:")
                              ]),
                              _vm._v(" " + _vm._s(_vm.model.typeText) + " ")
                            ]),
                            _c("el-col", { attrs: { span: 5 } }, [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v("推介费结算月份:")
                              ]),
                              _vm._v(" " + _vm._s(_vm.model.planDate) + " ")
                            ]),
                            _c(
                              "el-col",
                              { attrs: { span: 10 } },
                              [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("是否后勤人员: ")
                                ]),
                                _c(
                                  "el-radio-group",
                                  {
                                    on: { change: _vm.changeRadio },
                                    model: {
                                      value: _vm.formModel.isBackOffice,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formModel,
                                          "isBackOffice",
                                          $$v
                                        )
                                      },
                                      expression: "formModel.isBackOffice"
                                    }
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: "1" } }, [
                                      _vm._v("是")
                                    ]),
                                    _c("el-radio", { attrs: { label: "0" } }, [
                                      _vm._v("否")
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    key: _vm.tableKey,
                    attrs: {
                      data: _vm.formModel.tableData,
                      border: "",
                      "row-style": { height: "20px" },
                      "cell-style": { padding: "6px 0" },
                      hight: "200"
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "职级", prop: "title" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.StatusText(scope.row.titleId)) +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { label: "金额", prop: "bonus", align: "right" }
                    }),
                    _c("el-table-column", {
                      attrs: { label: "人员", prop: "bonusUserName" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    size: "mini",
                                    filterable: "",
                                    remote: "",
                                    disabled: scope.row.isNoUser === "1",
                                    clearable: "",
                                    placeholder: "请录入人员名称",
                                    "remote-method": function(query) {
                                      _vm.searchPerson(
                                        query,
                                        scope.$index,
                                        scope.row
                                      )
                                    }
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.changePerson(
                                        scope.$index,
                                        scope.row
                                      )
                                    }
                                  },
                                  model: {
                                    value: scope.row.bonusUserName,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "bonusUserName", $$v)
                                    },
                                    expression: "scope.row.bonusUserName"
                                  }
                                },
                                _vm._l(_vm.selectData.person, function(item) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: item.personuuid,
                                      attrs: {
                                        label: item.name,
                                        value: item.personuuid
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { float: "left" } },
                                        [_vm._v(_vm._s(item.name))]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            float: "right",
                                            color: "#8492a6",
                                            "font-size": "13px"
                                          }
                                        },
                                        [_vm._v(_vm._s(item.orgName))]
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { label: "无人岗位", prop: "title" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              scope.row.titleId !== "i0"
                                ? _c(
                                    "el-radio-group",
                                    {
                                      on: {
                                        change: function($event) {
                                          return _vm.changeCheckbox(scope.row)
                                        }
                                      },
                                      model: {
                                        value: scope.row.isNoUser,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "isNoUser", $$v)
                                        },
                                        expression: "scope.row.isNoUser"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "1" } },
                                        [_vm._v("是")]
                                      ),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "0" } },
                                        [_vm._v("否")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                ),
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.formModel,
                      rules: _vm.rules,
                      "label-width": "120px"
                    }
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "备注", prop: "remark" } },
                              [
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.formModel.remark,
                                      expression: "formModel.remark"
                                    }
                                  ],
                                  staticClass: "el-textarea__inner",
                                  attrs: { placeholder: "备注" },
                                  domProps: { value: _vm.formModel.remark },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.formModel,
                                        "remark",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm.model.contractId
              ? _c(
                  "el-tab-pane",
                  { attrs: { label: "附件", name: "tabAttachment" } },
                  [
                    _c("attachment", {
                      ref: "componentAttachment",
                      attrs: {
                        "is-attachment-edit": false,
                        "attachment-contract-id": _vm.model.contractId,
                        "attachment-type": _vm.attachmentType
                      },
                      on: { onAttachmentOpen: _vm.onAttachmentOpen }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ],
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              staticClass: "contract-log-tip",
              attrs: { plain: "", size: "mini", icon: "el-icon-finished" },
              on: {
                click: function($event) {
                  return _vm.showLog()
                }
              }
            },
            [_vm._v("审核日志")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", plain: "", size: "mini" },
              on: { click: _vm.clickBack }
            },
            [_vm._v("退回上一级")]
          ),
          _c(
            "el-button",
            { attrs: { plain: "", size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                plain: "",
                size: "mini",
                disabled: !_vm.isFormChange
              },
              on: { click: _vm.clickSave }
            },
            [_vm._v("暂 存")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", plain: "", size: "mini" },
              on: { click: _vm.clickSubmit }
            },
            [_vm._v("完成分配")]
          )
        ],
        1
      ),
      _vm.model.contractId != ""
        ? _c("contract-log", {
            attrs: {
              "contract-id": _vm.model.contractId,
              extId: _vm.model.applicationId,
              active: _vm.contractLogType
            },
            model: {
              value: _vm.showLogDialog,
              callback: function($$v) {
                _vm.showLogDialog = $$v
              },
              expression: "showLogDialog"
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }