import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/web.atob.js";
import "core-js/modules/web.dom-exception.constructor.js";
import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/web.dom-exception.to-string-tag.js";
import "core-js/modules/es.array-buffer.slice.js";
import "core-js/modules/es.typed-array.uint8-array.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.copy-within.js";
import "core-js/modules/es.typed-array.every.js";
import "core-js/modules/es.typed-array.fill.js";
import "core-js/modules/es.typed-array.filter.js";
import "core-js/modules/es.typed-array.find.js";
import "core-js/modules/es.typed-array.find-index.js";
import "core-js/modules/es.typed-array.for-each.js";
import "core-js/modules/es.typed-array.includes.js";
import "core-js/modules/es.typed-array.index-of.js";
import "core-js/modules/es.typed-array.iterator.js";
import "core-js/modules/es.typed-array.join.js";
import "core-js/modules/es.typed-array.last-index-of.js";
import "core-js/modules/es.typed-array.map.js";
import "core-js/modules/es.typed-array.reduce.js";
import "core-js/modules/es.typed-array.reduce-right.js";
import "core-js/modules/es.typed-array.reverse.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.slice.js";
import "core-js/modules/es.typed-array.some.js";
import "core-js/modules/es.typed-array.sort.js";
import "core-js/modules/es.typed-array.subarray.js";
import "core-js/modules/es.typed-array.to-locale-string.js";
import "core-js/modules/es.typed-array.to-string.js";
import EXIF from 'exif-js';
export default {
  getOrientation: function getOrientation(file) {
    return new Promise(function (resolve) {
      EXIF.getData(file, function () {
        var orient = EXIF.getTag(this, 'Orientation');
        resolve(orient);
      });
    });
  },
  dataURLtoFile: function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(',');
    var mime = arr[0].match(/:(.*?);/)[1];
    var bstr = atob(arr[1]);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {
      type: mime
    });
  },
  // 画布旋转
  rotateImage: function rotateImage(image, width, height, file) {
    var orient;
    EXIF.getData(file, function () {
      orient = EXIF.getTag(this, 'Orientation');
    });
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    ctx.save();
    console.log('orient', orient);

    switch (orient) {
      // 旋转180度
      case 3:
        console.log('旋转180度');
        ctx.rotate(Math.PI);
        ctx.drawImage(image, -width, -height, width, height);
        break;
      // 旋转90度

      case 6:
        console.log('旋转90度');
        canvas.width = width;
        canvas.height = height;
        var angle = 0 * Math.PI / 180;
        ctx.rotate(angle);
        ctx.drawImage(image, 0, 0, width, height);
        break;
      // 旋转-90度

      case 8:
        console.log('旋转-90度');
        canvas.width = width;
        canvas.height = height;
        ctx.rotate(0 * Math.PI / 180);
        ctx.drawImage(image, 0, 0, width, height);
        break;
    }

    ctx.restore();
    return canvas.toDataURL("image/jpeg");
  }
};