import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
// 附件
import request from '@/plugin/axios';
import { blobRequest } from '@/plugin/axios';
import func from '@/maps/url/crm/attachment/attachment';
import util from '@/libs/util'; // TODO 打开

export function open(params) {
  return blobRequest.post(func.download, {
    body: params
  }).then(function (blobs) {
    var fileURL = null;
    var blob = new Blob([blobs], {
      type: 'application/pdf;charset=UTF-8'
    });
    fileURL = URL.createObjectURL(blob);
    return fileURL;
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 下载

export function download(params) {
  return blobRequest.post(func.download, {
    body: params
  }).then(function (blob) {
    util.download(blob, params.fileName);
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 上传

export function upload(params) {
  return request.post(func.upload, {
    body: params
  });
} // TODO 上传

export function uploadMultiple(params) {
  return request.post(func.uploadMultiple, {
    body: params
  });
} // TODO 删除

export function remove(params) {
  return request.post(func.remove, {
    body: params
  });
} // TODO 列表

export function list(params) {
  return request.post(func.list, {
    body: params
  });
} // TODO 打包附件

export function bale(params) {
  return request.post(func.bale, {
    body: params
  });
} // TODO 下载附件包

export function downloadPack(params) {
  return blobRequest.post(func.downloadPack, {
    body: params
  }).then(function (blob) {
    util.download(blob, params.fileName);
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 合同附件压缩任务启动

export function imageThumbnailStart(params) {
  return request.post(func.imageThumbnailStart, {
    body: params
  });
} // TODO 重命名

export function rename(params) {
  return request.post(func.rename, {
    body: params
  });
} // TODO 保存视频

export function saveVideo(params) {
  return request.post(func.save, {
    body: params
  });
}