var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
      attrs: {
        top: "5vh",
        width: "1100px",
        title: _vm.title,
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formModel,
            rules: _vm.rules,
            "label-width": "100px",
            size: "mini"
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "工号", prop: "personNum" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formModel.personNum,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "personNum", $$v)
                          },
                          expression: "formModel.personNum"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "姓名", prop: "name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formModel.name,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "name", $$v)
                          },
                          expression: "formModel.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "性别", prop: "sexual" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.formModel.sexual,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "sexual", $$v)
                            },
                            expression: "formModel.sexual"
                          }
                        },
                        _vm._l(_vm.selectData.sexual, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.text, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "民族", prop: "nation" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.formModel.nation,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "nation", $$v)
                            },
                            expression: "formModel.nation"
                          }
                        },
                        _vm._l(_vm.selectData.nation, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.text, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "婚姻状况", prop: "marriageStatus" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.formModel.marriageStatus,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "marriageStatus", $$v)
                            },
                            expression: "formModel.marriageStatus"
                          }
                        },
                        _vm._l(_vm.selectData.marriageStatus, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.text, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "政治面貌", prop: "politicalTeam" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.formModel.politicalTeam,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "politicalTeam", $$v)
                            },
                            expression: "formModel.politicalTeam"
                          }
                        },
                        _vm._l(_vm.selectData.politicalTeam, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.text, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "籍贯", prop: "original" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formModel.original,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "original", $$v)
                          },
                          expression: "formModel.original"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "身份证", prop: "identification" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formModel.identification,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "identification", $$v)
                          },
                          expression: "formModel.identification"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "身份证地址", prop: "idAddress" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formModel.idAddress,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "idAddress", $$v)
                          },
                          expression: "formModel.idAddress"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "住址", prop: "liveAddress" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formModel.liveAddress,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "liveAddress", $$v)
                          },
                          expression: "formModel.liveAddress"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系方式", prop: "mobile" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formModel.mobile,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "mobile", $$v)
                          },
                          expression: "formModel.mobile"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "邮箱", prop: "email" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formModel.email,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "email", $$v)
                          },
                          expression: "formModel.email"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "QQ", prop: "qq" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formModel.qq,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "qq", $$v)
                          },
                          expression: "formModel.qq"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "微信号", prop: "weixin" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formModel.weixin,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "weixin", $$v)
                          },
                          expression: "formModel.weixin"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "毕业院校", prop: "college" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formModel.college,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "college", $$v)
                          },
                          expression: "formModel.college"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "学历", prop: "degree" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.formModel.degree,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "degree", $$v)
                            },
                            expression: "formModel.degree"
                          }
                        },
                        _vm._l(_vm.selectData.degree, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.text, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "专业", prop: "major" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formModel.major,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "major", $$v)
                          },
                          expression: "formModel.major"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "培养方式", prop: "trainingMethod" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.formModel.trainingMethod,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "trainingMethod", $$v)
                            },
                            expression: "formModel.trainingMethod"
                          }
                        },
                        _vm._l(_vm.selectData.trainingMethod, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.text, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "工资卡银行", prop: "salaryBank" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.formModel.salaryBank,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "salaryBank", $$v)
                            },
                            expression: "formModel.salaryBank"
                          }
                        },
                        _vm._l(_vm.selectData.salaryBank, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.text, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "工资卡账号", prop: "salaryAccount" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formModel.salaryAccount,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "salaryAccount", $$v)
                          },
                          expression: "formModel.salaryAccount"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "紧急联系人", prop: "emName" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formModel.emName,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "emName", $$v)
                          },
                          expression: "formModel.emName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "紧急联系方式", prop: "emMobile" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formModel.emMobile,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "emMobile", $$v)
                          },
                          expression: "formModel.emMobile"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "岗位", prop: "title" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formModel.title,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "title", $$v)
                          },
                          expression: "formModel.title"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "入职日期", prop: "comeInDate" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "date",
                          placeholder: "选择日期",
                          "value-format": "yyyy-MM-dd"
                        },
                        model: {
                          value: _vm.formModel.comeInDate,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "comeInDate", $$v)
                          },
                          expression: "formModel.comeInDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否后勤", prop: "isBackoffice" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "0" },
                          model: {
                            value: _vm.formModel.isBackoffice,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "isBackoffice", $$v)
                            },
                            expression: "formModel.isBackoffice"
                          }
                        },
                        [_vm._v("否")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.formModel.isBackoffice,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "isBackoffice", $$v)
                            },
                            expression: "formModel.isBackoffice"
                          }
                        },
                        [_vm._v("是")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否主管", prop: "leader" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "0" },
                          model: {
                            value: _vm.formModel.leader,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "leader", $$v)
                            },
                            expression: "formModel.leader"
                          }
                        },
                        [_vm._v("否")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.formModel.leader,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "leader", $$v)
                            },
                            expression: "formModel.leader"
                          }
                        },
                        [_vm._v("是")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属机构(不可修改)", prop: "orgCd" } },
                    [
                      _vm.formModel.personuuid == null
                        ? _c("el-cascader", {
                            attrs: {
                              "show-all-levels": false,
                              options: _vm.selectData.arrayOrg,
                              props: {
                                checkStrictly: true,
                                emitPath: false,
                                value: "orgCd",
                                label: "orgName",
                                children: "children"
                              }
                            },
                            model: {
                              value: _vm.formModel.orgCd,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "orgCd", $$v)
                              },
                              expression: "formModel.orgCd"
                            }
                          })
                        : _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.formModel.orgName,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "orgName", $$v)
                              },
                              expression: "formModel.orgName"
                            }
                          })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "账号", prop: "code" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.formModel.code,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "code", $$v)
                          },
                          expression: "formModel.code"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              !_vm.isEdit
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "创建账号", prop: "" } },
                        [
                          _c(
                            "el-checkbox",
                            {
                              on: {
                                change: function($event) {
                                  return _vm.changeNewCode()
                                }
                              },
                              model: {
                                value: _vm.checkedNewCode,
                                callback: function($$v) {
                                  _vm.checkedNewCode = $$v
                                },
                                expression: "checkedNewCode"
                              }
                            },
                            [_vm._v("(默认手机号，初始密码 888888)")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注", prop: "remark" } },
                    [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formModel.remark,
                            expression: "formModel.remark"
                          }
                        ],
                        staticClass: "el-textarea__inner",
                        attrs: { placeholder: "请填写备注" },
                        domProps: { value: _vm.formModel.remark },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.formModel,
                              "remark",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { plain: "", size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                plain: "",
                size: "mini",
                loading: _vm.loading
              },
              on: { click: _vm.clickSave }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }