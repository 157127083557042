import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.filter.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from '@/mixins/dialog';
import COMMISSION_TYPE from '@/maps/enum/commission-type';
import { commissionFormulaList, commissionSubmitPlanAndApplyBatch, commissionSavePlanAndApplyBatch } from '@api/crm/commissionV3/commissionV3';
import { listRight as personList, list as detailsList } from '@api/rm/person';
export default {
  name: 'settle-batch',
  props: {
    propsModel: {
      type: Array,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      showLogDialog: false,
      formModel: {
        tableData: []
      },
      // 表单
      sum: {},
      // 统计
      rules: {
        totalTimes: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }],
        currentTimes: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }],
        ruleId: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }],
        type: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }],
        planDate: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }]
      },
      selectData: {
        person: [],
        ruleId: [],
        type: []
      }
    };
  },
  created: function created() {
    var _this = this;

    // 规则列表
    commissionFormulaList().then(function (res) {
      _this.selectData.ruleId = res.data;
    });
    var array2 = [];

    for (var key in COMMISSION_TYPE) {
      array2.push(COMMISSION_TYPE[key]);
    } // 计划默认为空


    this.selectData.type = array2;
  },
  computed: {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.loadData();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.sum = {};
      this.formModel = {};
      this.formModel.tableData = [];
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {},
    clickAudit: function clickAudit() {
      var _this2 = this;

      // 处理人员数据
      var arrayObject = this.formModel.tableData.map(function (item) {
        if (item.detailList.length > 0) {
          item.detailList.forEach(function (n) {
            // 人员id赋值
            n.bonusUserId = item[n.titleId]; // 人员金额

            n.bonus = item[n.titleId + 'bonus']; // 人员比例

            n.percent = item[n.titleId + 'percent'];
          });
        }

        return {
          planId: item.planId,
          contractId: item.contractId,
          type: item.type,
          planDate: item.planDate,
          totalTimes: item.totalTimes,
          currentTimes: item.currentTimes,
          ruleId: item.ruleId,
          status: item.status,
          remark: item.remark,
          isBackOffice: item.isBackOffice,
          detailList: item.detailList
        };
      });
      console.log('param', arrayObject);
      this.$refs.form.validate(function (valid) {
        if (valid) {
          // this.loading = true;
          // 保存数据
          commissionSavePlanAndApplyBatch({
            arrayObject: arrayObject
          }).then(function (res) {
            var arrayApplicationId = [];

            _this2.formModel.tableData.forEach(function (n) {
              arrayApplicationId.push(n.applicationId);
            });

            var submitParam = {
              arrayApplicationId: arrayApplicationId
            }; // 提交 状态

            commissionSubmitPlanAndApplyBatch(submitParam).then(function (res) {
              // this.loading = false;
              _this2.showDialog = false;

              _this2.$message({
                message: '已提交',
                type: 'success'
              });

              _this2.$emit('refresh');
            });
          }).catch(function (e) {
            _this2.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    loadData: function loadData() {
      for (var i = 0; i < this.propsModel.length; i++) {
        var rowData = this.propsModel[i];
        var sumBonus = 0; // 行合计

        for (var j = 0; j < rowData.detailList.length; j++) {
          var n = rowData.detailList[j]; // 人员列表展示

          var keyName = n.titleId + 'name';
          var keyId = n.titleId; // 人员金额展示

          var keyBonus = n.titleId + 'bonus'; // 人员金额标题表头

          var keyPercent = n.titleId + 'percent'; // 人员比例表头

          rowData[keyName] = n.bonusUserName; // 人员姓名

          rowData[keyId] = n.bonusUserId; // 人员id

          rowData[keyBonus] = n.bonus; // 金额

          rowData[keyPercent] = n.percent; // 比例

          sumBonus += parseFloat(n.bonus);
        }

        rowData.sumBonus = sumBonus;
        this.formModel.tableData.push(rowData);
      }
    },
    // 设置表格统计行
    setSummaryRow: function setSummaryRow() {
      var sumI0 = 0;
      var sumI1 = 0;
      var sumI4 = 0;
      var sumI2 = 0;
      var sumI3 = 0;
      var sumI5 = 0;
      var sum = 0;
      this.formModel.tableData.forEach(function (n) {
        sumI0 += n.i0bonus;
        sumI1 += n.i1bonus == null ? 0 : n.i1bonus;
        sumI4 += n.i4bonus == null ? 0 : n.i4bonus;
        sumI2 += n.i2bonus == null ? 0 : n.i2bonus;
        sumI3 += n.i3bonus == null ? 0 : n.i3bonus;
        sumI5 += n.i5bonus == null ? 0 : n.i5bonus;
        sum += n.sumBonus;
      });
      var sumRow = [];
      sumRow[0] = '合计';
      sumRow[1] = '共' + this.formModel.tableData.length + '笔';
      sumRow[8] = sumI0;
      sumRow[10] = sumI1;
      sumRow[10] = sumI1;
      sumRow[12] = sumI4;
      sumRow[14] = sumI2;
      sumRow[16] = sumI3;
      sumRow[18] = sumI5;
      sumRow[19] = sum;
      return sumRow;
    },
    // row 当前项 name人员id键值 value选择人员id
    changePerson: function changePerson(row, name, value) {
      var label = name + 'name'; // 判断是否选择人员

      if (row[label] != '') {
        // 选择新人员
        var n = {}; // 原下拉框选项

        n = this.selectData.person.find(function (item) {
          return item.personuuid === value;
        });

        if (n != null) {
          row[name] = n.personuuid;
        }
      } else {
        // 没有选择，或者原有的清空
        row[name] = null;
      } // 数据刷新


      this.$forceUpdate();
    },
    // 搜索用户
    searchPerson: function searchPerson(key, row, keyIdText) {
      var _this3 = this;

      if (key != '') {
        var param = {
          query: {
            name: key
          }
        };
        personList(param).then(function (res) {
          _this3.selectData.person = res.data; // 无值 区总查询

          if (!res.data && (keyIdText == 'i2' || keyIdText == 'i3' || keyIdText == 'i4')) {
            detailsList(param).then(function (res) {
              if (res.data != null && res.data.length === 1) {
                // 区总 查询只有一位时 赋值当前选中
                console.log('1----', res);

                if (key === res.data[0].name) {
                  row[keyIdText] = res.data[0].personuuid;
                  row["".concat(keyIdText, "name")] = res.data[0].name;

                  _this3.$message({
                    message: "".concat(res.data[0].name, " \u59D3\u540D\u6B63\u5E38\uFF0C\u5728\u6211\u53F8\u67B6\u6784\u4E2D\u5B58\u5728\u3002"),
                    type: 'success'
                  });
                }

                _this3.$forceUpdate();
              } else if (res.data != null && res.data.length > 1) {
                // 区总 查询多位时 提醒人员重名 总部人员处理
                var arr = res.data.filter(function (item) {
                  return item.enabled === '1' && item.name === key; // 1 启用  2.查询名字相同
                });

                if (arr.length > 1) {
                  // 多个启用 重名
                  if (key === arr[0].name) {
                    _this3.$message({
                      message: "".concat(arr[0].name, " \u59D3\u540D\u5B58\u5728\u91CD\u540D\uFF0C\u8BF7\u8054\u7CFB\u603B\u90E8\u4EBA\u5458\u5904\u7406\u3002\u3002"),
                      type: 'error'
                    });
                  }
                } else if (arr.length === 1) {
                  // 一个启用
                  if (key === arr[0].name) {
                    row[keyIdText] = arr[0].personuuid;
                    row["".concat(keyIdText, "name")] = arr[0].name;

                    _this3.$message({
                      message: "".concat(arr[0].name, " \u59D3\u540D\u6B63\u5E38\uFF0C\u5728\u6211\u53F8\u67B6\u6784\u4E2D\u5B58\u5728\u3002"),
                      type: 'success'
                    });
                  }
                } else {
                  // 无启用 都是停用或者禁用 取第一个
                  if (key === res.data[0].name) {
                    row[keyIdText] = res.data[0].personuuid;
                    row["".concat(keyIdText, "name")] = res.data[0].name;

                    _this3.$message({
                      message: "".concat(res.data[0].name, " \u59D3\u540D\u6B63\u5E38\uFF0C\u5728\u6211\u53F8\u67B6\u6784\u4E2D\u5B58\u5728\u3002"),
                      type: 'success'
                    });
                  }
                }

                _this3.$forceUpdate();
              }
            });
          }
        }).catch(function (e) {
          _this3.loading = false;

          _this3.$message({
            message: '查询用户出错',
            type: 'error'
          });
        });
      }
    }
  }
};