//查询条件
import ENUM from '@/maps/enum/enum';
import STATUS from "@/maps/enum/status/count";
import { getEnumList } from '@api/common/common';
import { listCacheOrg, listCacheProduct } from "@api/common/cache";
var VAL_WIDTH = '150px';
var ENUM_CD_0249 = [{
  text: "归还黄金珠宝实物",
  value: "1"
}, {
  text: "回收黄金珠宝实物",
  value: "2"
}, {
  text: "黄金实物+现金",
  value: "1"
}, {
  text: "现金",
  value: "2"
}];
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
export var FILTER_FORMS = [{
  label: '合同编号',
  valueKey: 'contractCd',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '客户',
  valueKey: 'customerName',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '产品',
  valueKey: 'arrayProductId',
  value: [],
  type: 'select',
  method: listCacheProduct,
  params: {},
  options: [],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  }
}, {
  label: '结算属性',
  valueKey: 'arrayProductCountType',
  value: [],
  type: 'select',
  options: [],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  },
  method: getEnumList,
  params: {
    // 请求时用的参数
    enumCd: ENUM.ENUM_CD_PRODUCT_COUNT_TYPE
  }
}, {
  label: '机构名称',
  valueKey: 'orgCd',
  value: '',
  type: 'cascader',
  method: listCacheOrg,
  params: {},
  options: [],
  props: {
    //expandTrigger: 'hover',
    checkStrictly: true,
    emitPath: false,
    showAllLevels: false,
    value: 'orgCd',
    label: 'orgName',
    children: 'children'
  }
}, {
  label: '核算日期',
  valueKey: 'countDateBegin',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'countDateEnd',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '到期日期',
  valueKey: 'endDateBegin',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'endDateEnd',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '返还周期',
  valueKey: 'returnPeriod',
  value: '',
  type: 'select',
  options: [],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    }
  },
  method: getEnumList,
  params: {
    // 请求时用的参数
    enumCd: ENUM.ENUM_CD_0202
  }
}, {
  label: '回访',
  valueKey: 'isVisit',
  value: '',
  type: 'select',
  options: [{
    value: '0',
    text: '否'
  }, {
    value: '1',
    text: '是'
  }],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '顾问',
  valueKey: 'userName',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '审核状态',
  valueKey: 'arrayCountState',
  value: [],
  type: 'select',
  options: filterCountStatus(),
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  }
}, {
  label: '赎回方式',
  valueKey: 'rentRansomType',
  value: '',
  type: 'select',
  options: ENUM_CD_0249,
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    }
  },
  method: getEnumList
}, {
  label: '核算操作',
  valueKey: 'isRenew',
  value: '',
  type: 'select',
  options: [{
    text: "续约",
    value: "1"
  }, {
    text: "确认",
    value: "0"
  }],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    }
  },
  method: getEnumList
}]; //核算状态

function filterCountStatus() {
  var array = [STATUS.WAIT_CONFIRM_DATE, STATUS.SAVE, STATUS.SUBMIT, STATUS.FINANCE_AUDIT, STATUS.FINANCE_AUDIT_FAILURE, STATUS.SERVICE_AUDIT, STATUS.SERVICE_AUDIT_FAILURE];
  return array;
}