export default (function (_ref) {
  var request = _ref.request,
      url = _ref.url;
  return {
    /** 导出积分 */
    EXPORT_SCORE_DATA: function EXPORT_SCORE_DATA(data) {
      return request({
        url: url.score.exportScoreData,
        responseType: 'arraybuffer',
        data: data,
        timeout: 60000
      });
    },

    /** 批量调整积分 */
    BATCH_ADJUST_SCORE: function BATCH_ADJUST_SCORE(data) {
      return request({
        url: url.score.batchAdjustScore,
        data: data
      });
    },

    /** 调整积分 */
    ADJUST_SCORE: function ADJUST_SCORE(data) {
      return request({
        url: url.score.adjustScore,
        data: data
      });
    },

    /** 导出积分明细 */
    EXPORT_SCORE_DETAIL_DATA: function EXPORT_SCORE_DETAIL_DATA(data) {
      return request({
        url: url.score.exportScoreDetailData,
        responseType: 'arraybuffer',
        data: data,
        timeout: 60000
      });
    },

    /** 获取积分明细的变动记录列表 */
    GET_CHANGE_LIST: function GET_CHANGE_LIST(data) {
      return request({
        url: url.score.getChangeList,
        data: data
      }).then(function (res) {
        return res;
      });
    },

    /** 获取积分奖励规则获取详情 */
    GET_SCOREINFO: function GET_SCOREINFO() {
      return request({
        url: url.score.getInfo
      });
    },

    /** 获取积分奖励规更新详情 */
    GET_SCOREUPDETE: function GET_SCOREUPDETE(data) {
      return request({
        url: url.score.update,
        data: data
      });
    }
  };
});