




















































































export default function (Component) {
  Component.options.__source = "src/components/business/previewPdf/index.vue"
}
