import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
// import useCloud from "@/hooks/use-cloud";
// import useImageParse from "@/hooks/use-image-parse";
import { formatUserDataPromise } from '@/libs/crm/format';
export default (function (_ref) {
  var request = _ref.request,
      url = _ref.url;
  return {
    /** 导入客户 */
    IMPORT_CUSTOMER: function IMPORT_CUSTOMER(data) {
      return request({
        url: url.customer.manage.importCustomer,
        data: data,
        responseType: 'arraybuffer'
      });
    },

    /** 导出客户 */
    EXPORT_CUSTOMER: function EXPORT_CUSTOMER(data) {
      return request({
        url: url.customer.manage.exportCustomer,
        data: data,
        responseType: 'arraybuffer',
        timeout: 60000
      });
    },

    /* 客户等级下拉列表 */
    LIST_LEVELRULE_LABEL: function LIST_LEVELRULE_LABEL() {
      return request({
        url: url.customer.level.getChooseList
      }).then(function (res) {
        return res.map(function (item) {
          return {
            label: item.name,
            value: item.id
          };
        }) || [];
      });
    },

    /* 添加客户等级 */
    ADD_LEVELRULE: function ADD_LEVELRULE(data) {
      return request({
        url: url.customer.level.addCustomerLevelRule,
        data: data
      });
    },

    /* 更新客户等级 */
    UPDATE_LEVELRULE: function UPDATE_LEVELRULE(data) {
      return request({
        url: url.customer.level.updateCustomerLevelRule,
        data: data
      });
    },

    /* 删除客户等级 */
    DELETE_LEVEL_RULE: function DELETE_LEVEL_RULE(data) {
      return request({
        url: url.customer.level.deleteCustomerLevelRule,
        data: data
      });
    },

    /* 更新h5账号状态 */
    UPDATE_STATUS_h5: function UPDATE_STATUS_h5(data) {
      return request({
        url: url.customer.h5.updateStatus,
        data: data
      });
    },

    /* 导出H5账号数据 */
    EXPORT_ACCOUNT_DATA: function EXPORT_ACCOUNT_DATA() {
      return request({
        url: url.customer.h5.exportAccountData,
        responseType: 'arraybuffer'
      });
    },

    /* 客户H5账号审核 */
    AUDIT_ACCOUNT: function AUDIT_ACCOUNT(data) {
      return request({
        url: url.customer.h5.auditAccount,
        data: data
      });
    },

    /* 客户H5账号合并客户 */
    MERGE_ACCOUNT_CUSTOMER: function MERGE_ACCOUNT_CUSTOMER(data) {
      return request({
        url: url.customer.h5.mergeAccountCustomer,
        data: data
      });
    },

    /* 获取认证中的冲突客户数据 */
    MERGE_ACCOUNT_CONFLICTCUSTOMERLIST: function MERGE_ACCOUNT_CONFLICTCUSTOMERLIST(data) {
      return request({
        url: url.customer.h5.getConflictCustomerList,
        data: data
      });
    },

    /** 客户详情 */
    GET_CUSTOMER_INFO: function GET_CUSTOMER_INFO(id) {
      return request({
        url: url.customer.manage.getCustomerInfo,
        data: {
          id: id
        }
      }).then(function (res) {
        return _objectSpread({}, res);
      });
    },

    /** 新增客户 */
    ADD_CUSTOMER: function ADD_CUSTOMER(data) {
      return request({
        url: url.customer.manage.addCustomer,
        data: data
      });
    },

    /* 修改客户信息 */
    UPDATE_CUSTOMER: function UPDATE_CUSTOMER(data) {
      return request({
        url: url.customer.manage.updateCustomer,
        data: data
      });
    },

    /* 修改客户状态 */
    UPDATE_STATUS_CUSTOMER: function UPDATE_STATUS_CUSTOMER(data) {
      return request({
        url: url.customer.manage.updateCustomerStatus,
        data: data
      });
    },

    /** 删除客户 */
    DELETE_CUSTOMER: function DELETE_CUSTOMER(id) {
      return request({
        url: url.customer.manage.deleteCustomer,
        data: {
          id: id
        }
      });
    },

    /** 转移客户 */
    TRANSFER_CUSTOMER: function TRANSFER_CUSTOMER(data) {
      return request({
        url: url.customer.manage.transferCustomer,
        data: data
      });
    },

    /** 合并客户 */
    MERGE_CUSTOMER: function MERGE_CUSTOMER(data) {
      return request({
        url: url.customer.manage.mergeCustomer,
        data: data
      });
    },

    /* 新增客户标签 */
    ADD_CUSTOMER_LABEL: function ADD_CUSTOMER_LABEL(data) {
      return request({
        url: url.customer.label.addCustomerLabel,
        data: data
      });
    },

    /* 修改客户标签 */
    UPDATE_CUSTOMER_LABEL: function UPDATE_CUSTOMER_LABEL(data) {
      return request({
        url: url.customer.label.updateCustomer,
        data: data
      });
    },

    /** 删除客户标签 */
    DELETE_CUSTOMER_LABEL: function DELETE_CUSTOMER_LABEL(ids) {
      return request({
        url: url.customer.label.deleteCustomerLabel,
        data: {
          ids: ids
        }
      });
    },

    /** 获取用户下拉列表 */
    GET_USER_CHOOSE_LIST: function GET_USER_CHOOSE_LIST(data) {
      return request({
        url: url.customer.manage.getUserChooseList,
        data: data
      }).then(function (res) {
        return res;
      });
    },

    /** 获取客户的动态日志 */
    GET_CUSTOMER_DYNAMIC_LOG: function GET_CUSTOMER_DYNAMIC_LOG(data) {
      return request({
        url: url.customer.manage.getCustomerDynamicLog,
        data: data
      }).then(function (res) {
        return res;
      });
    },

    /** 获取客户的合同 */
    GET_CUSTOMER_CONTRACT: function GET_CUSTOMER_CONTRACT(data) {
      return request({
        url: url.customer.manage.getContractByCustomer,
        data: data
      }).then(function (res) {
        return res;
      });
    },

    /** 获取客户理顾变动记录分页列表 */
    GET_CUSTOMER_LOPPAGE: function GET_CUSTOMER_LOPPAGE(data) {
      return request({
        url: url.customer.manage.getAgentUserLogPage,
        data: data
      }).then(function (res) {
        return res;
      });
    },

    /** 获取活动选择会员客户分页列表 */
    GET_ACTIVITY_CHOOSE_CUSTOMER: function GET_ACTIVITY_CHOOSE_CUSTOMER(data) {
      return request({
        url: url.customer.manage.getActivityChooseCustomerPage,
        data: data
      }).then(function (res) {
        return res;
      });
    },

    /** 检查客户手机号唯一性 */
    CHECK_CUSTOMER_UNIQUE_MOBILE: function CHECK_CUSTOMER_UNIQUE_MOBILE(data) {
      return request({
        url: url.customer.manage.checkCustomerUniqueMobile,
        data: data
      });
    },

    /** 获取合同选择客户分页数据 */
    GET_CONTRAC_ChHOOSE_CUSTOMER_PAGE: function GET_CONTRAC_ChHOOSE_CUSTOMER_PAGE(data, type) {
      var re = request({
        url: url.customer.manage.getContractChooseCustomerPage,
        data: data
      });
      formatUserDataPromise(re, type);
      return re;
    },

    /** 获取客户简短详情数据 */
    GET_CUSTOMER_SIMPLEINFO: function GET_CUSTOMER_SIMPLEINFO(data) {
      return request({
        url: url.customer.manage.getCustomerSimpleInfo,
        data: data
      });
    },

    /** 没有合同客户转移 */
    TRANSFER_NONCONTACT_CUSTOMER: function TRANSFER_NONCONTACT_CUSTOMER(data) {
      return request({
        url: url.customer.manage.transferNonContactCustomer,
        data: data
      });
    },

    /** 判断是否可以转移当前客户 */
    CHECK_TRANSFER_CUSTOMERDATA: function CHECK_TRANSFER_CUSTOMERDATA(data) {
      return request({
        url: url.customer.manage.checkTransferCustomerData,
        data: data
      });
    },

    /** 判断是否可以转移当前客户 */
    GET_GIFTCHOOSE_CUSTOMER_PAGE: function GET_GIFTCHOOSE_CUSTOMER_PAGE(data) {
      return request({
        url: url.customer.manage.getGiftChooseCustomerPage,
        data: data
      });
    }
  };
});