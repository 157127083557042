import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import ENUM from "@/maps/enum/enum";
import { getEnumList } from "@api/common/common";
import { listCacheOrg } from "@api/common/cache";
import { getInfo as _getInfo, addPerson, editPerson } from "@api/rm/person";
export default {
  name: "dialog-person-add",
  props: {
    person: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      formModel: {},
      checkedNewCode: false,
      //是否创建新CRM账号   
      selectData: {
        sexual: [],
        //性别
        nation: [],
        //民族
        marriageStatus: [],
        //婚姻状况
        politicalTeam: [],
        //政治面貌
        degree: [],
        //学历
        trainingMethod: [],
        //培养方式
        salaryBank: [],
        //工资卡银行
        //title: [], //岗位
        arrayOrg: [] //机构        

      },
      rules: {
        name: [{
          required: true,
          message: "请填写客户姓名",
          trigger: "input"
        }],
        mobile: [{
          required: true,
          message: "请填写联系方式",
          trigger: "input"
        }],
        orgCd: [{
          required: true,
          message: "请选择所属机构",
          trigger: "input"
        }],
        //title: [{ required: true, message: "请选择岗位", trigger: "input" }],
        isBackoffice: [{
          required: true,
          message: "请选择是否后勤",
          trigger: "input"
        }],
        title: [{
          required: true,
          message: "请填写岗位",
          trigger: "input"
        }]
      }
    };
  },
  created: function created() {
    var _this = this;

    //性别
    getEnumList({
      enumCd: ENUM.ENUM_CD_0102
    }).then(function (res) {
      _this.selectData.sexual = res;
    }); //民族

    getEnumList({
      enumCd: ENUM.ENUM_CD_0112
    }).then(function (res) {
      _this.selectData.nation = res;
    }); //婚姻状况

    getEnumList({
      enumCd: ENUM.ENUM_CD_0106
    }).then(function (res) {
      _this.selectData.marriageStatus = res;
    }); //政治面貌

    getEnumList({
      enumCd: ENUM.ENUM_CD_0107
    }).then(function (res) {
      _this.selectData.politicalTeam = res;
    }); //学历

    getEnumList({
      enumCd: ENUM.ENUM_CD_0103
    }).then(function (res) {
      _this.selectData.degree = res;
    }); //培养方式

    getEnumList({
      enumCd: ENUM.ENUM_CD_0108
    }).then(function (res) {
      _this.selectData.trainingMethod = res;
    }); //工资卡银行

    getEnumList({
      enumCd: ENUM.ENUM_CD_0205
    }).then(function (res) {
      _this.selectData.salaryBank = res;
    }); //岗位
    // getEnumList({ enumCd: ENUM.ENUM_CD_0101 }).then((res) => {
    //   this.selectData.title = res;
    // });
    //机构

    listCacheOrg().then(function (res) {
      _this.selectData.arrayOrg = res;
    });
  },
  computed: {
    // 弹窗展示的标题
    title: function title() {
      return this.isEdit ? "修改" : "新增";
    },
    // 是否为编辑
    isEdit: function isEdit() {
      var personuuid = this.person.personuuid;
      return !!personuuid;
    }
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();

          if (this.isEdit) {
            this.getInfo();
          } else {
            this.checkedNewCode = false;
            this.formModel = {
              isBackoffice: "0",
              leader: "0"
            };
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.model = {};
      this.formModel = {};
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    // TODO 获取职员详情
    getInfo: function getInfo() {
      var _this2 = this;

      var personuuid = this.person.personuuid;

      _getInfo({
        personuuid: personuuid
      }).then(function (res) {
        var _res$data = res.data,
            data = _res$data === void 0 ? {} : _res$data;
        _this2.formModel = data;
      });
    },
    // TODO 确定
    clickSave: function clickSave(done) {
      var _this3 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var method = _this3.isEdit ? editPerson : addPerson;
          _this3.loading = true;
          var param = {
            object: _objectSpread({}, _this3.formModel)
          };
          method(param).then(function (res) {
            _this3.loading = false;
            _this3.showDialog = false;

            _this3.$set(_this3.formModel, "personuuid", res.data); //将新生成Id赋值              


            _this3.$emit("refresh");
          }).catch(function (e) {
            _this3.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    //选择默认开通帐号
    changeNewCode: function changeNewCode() {
      if (this.checkedNewCode) {
        this.$set(this.formModel, "code", this.formModel.mobile); //设置帐号 = 手机号
      } else {
        this.$set(this.formModel, "code", null); //设置帐号 = null
      }
    }
  }
};