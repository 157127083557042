import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var PUBLIC_CONFIG = {
  labelWidth: 110,
  col: 15
};
export var FORM_BASE = [{
  title: '基本信息',
  children: [_objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '平台名称',
    prop: 'name',
    props: {
      type: 'text',
      placeholder: '请输入平台名称',
      maxlength: 32 // showWordLimit: true

    },
    rules: [{
      required: true,
      message: '平台名称不能为空',
      trigger: 'blur'
    }]
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'uploadImage',
    label: '平台LOGO',
    prop: 'logoUrl',
    props: {
      placeholder: '最多可传1张，图片大小不超过200KB，支GIF,JPEG,JPG,PNG,BMP格式',
      fileSize: 200
    },
    rules: [{
      required: true,
      message: '平台LOGO不能为空',
      trigger: 'blur'
    }]
  })]
}, {
  title: '用户协议',
  children: [_objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'editor',
    prop: 'protocolContent',
    col: 24,
    props: {}
  })]
}];