var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "dialog-info",
      attrs: {
        "custom-class": _vm.attachmentOpened,
        top: "10vh",
        width: "1000px",
        title: "核算确认（对日到期）",
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.tabActive,
            callback: function($$v) {
              _vm.tabActive = $$v
            },
            expression: "tabActive"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "合同信息", name: "tabContract" } },
            [
              _c(
                "el-collapse",
                {
                  model: {
                    value: _vm.collapseActive,
                    callback: function($$v) {
                      _vm.collapseActive = $$v
                    },
                    expression: "collapseActive"
                  }
                },
                [
                  _c(
                    "el-collapse-item",
                    { attrs: { title: "合同信息", name: "1" } },
                    [
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v(_vm._s(_vm.labelContractCd) + ":")
                            ]),
                            _vm._v(" " + _vm._s(this.model.contractCd) + " ")
                          ]),
                          _vm.isHaveContractBuyCd
                            ? _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v(_vm._s(_vm.labelBuyContractCd) + ":")
                                ]),
                                _vm._v(
                                  " " + _vm._s(this.model.buyContractCd) + " "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("姓名:")
                            ]),
                            _c("span", { staticClass: "d2-emphasis" }, [
                              _vm._v(_vm._s(this.model.customerName))
                            ])
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("证件类型:")
                            ]),
                            _vm._v(" " + _vm._s(this.model.idTypeText) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("证件号码:")
                            ]),
                            _vm._v(
                              " " + _vm._s(this.model.identification) + " "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("联系方式:")
                            ]),
                            _vm._v(" " + _vm._s(this.model.mobile) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 17 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("住址:")
                            ]),
                            _vm._v(" " + _vm._s(this.model.address) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("机构:")
                            ]),
                            _vm._v(" " + _vm._s(this.model.orgName) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("顾问:")
                            ]),
                            _vm._v(" " + _vm._s(this.model.userName) + " ")
                          ])
                        ],
                        1
                      ),
                      _c("el-divider"),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("产品:")
                            ]),
                            _c("span", { staticClass: "d2-emphasis" }, [
                              _vm._v(_vm._s(this.model.productName))
                            ])
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("合同金额:")
                            ]),
                            _c("span", { staticClass: "d2-emphasis" }, [
                              _vm._v(_vm._s(this.model.amount))
                            ])
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("开始日期:")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(this.model.startDate) +
                                " (存续:" +
                                _vm._s(this.model.holdDay) +
                                "天) "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("期限(月):")
                            ]),
                            _vm._v(" " + _vm._s(this.model.timeLimit) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("返还周期(月):")
                            ]),
                            _vm._v(" " + _vm._s(this.model.returnPeriod) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("到期日期:")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(this.model.endDate) +
                                " (到期:" +
                                _vm._s(this.model.leftDay) +
                                "天) "
                            )
                          ])
                        ],
                        1
                      ),
                      _vm.isHaveAnnualRate
                        ? _c(
                            "el-row",
                            { staticClass: "block-col-3" },
                            [
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("收益率(%):")
                                ]),
                                _c("span", { staticClass: "d2-emphasis" }, [
                                  _vm._v(_vm._s(_vm.model.annualRate))
                                ])
                              ]),
                              _vm.model.floatAnnualRateTo > 0
                                ? _c("el-col", { attrs: { span: 7 } }, [
                                    _c("span", { staticClass: "d2-fwb" }, [
                                      _vm._v("浮动(%):")
                                    ]),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.model.floatAnnualRateFrom) +
                                        " ~ " +
                                        _vm._s(_vm.model.floatAnnualRateTo) +
                                        " "
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _vm.isHaveExpectEarnings
                            ? _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v(
                                    _vm._s(_vm.model.extLabelExpectEarnings) +
                                      ":"
                                  )
                                ]),
                                _c("span", { staticClass: "d2-emphasis" }, [
                                  _vm._v(_vm._s(_vm.model.extExpectEarnings))
                                ])
                              ])
                            : _vm._e(),
                          _vm.isHaveGoldWeight
                            ? _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("金价:")
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.model.contractGoldPrice) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _vm.isHaveGoldWeight
                            ? _c(
                                "el-col",
                                { attrs: { span: 10 } },
                                [
                                  _c("span", { staticClass: "d2-fwb" }, [
                                    _vm._v("克重:")
                                  ]),
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "right",
                                        width: "650",
                                        trigger: "click"
                                      }
                                    },
                                    [
                                      _c("contract-gold-weight", {
                                        attrs: {
                                          propsModel: this.model.contractId
                                        }
                                      }),
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            slot: "reference",
                                            type: "warning"
                                          },
                                          slot: "reference"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.model.contractGoldWeight
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm.isHaveActualEndDate
                        ? _c(
                            "el-row",
                            { staticClass: "block-col-3" },
                            [
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("结算日期:")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.actualEndDate) + " "
                                )
                              ]),
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v(
                                    _vm._s(_vm.model.extLabelActualEarnings) +
                                      ":"
                                  )
                                ]),
                                _c("span", { staticClass: "d2-emphasis" }, [
                                  _vm._v(_vm._s(_vm.model.extActualEarnings))
                                ])
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _vm.model.rentRansomType != null &&
                          _vm.model.rentRansomType != ""
                            ? _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("赎回方式:")
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(this.model.rentRansomTypeText) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("签约类型:")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(this.model.contractSignTypeText) +
                                " "
                            )
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("收款方式:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.payMethodText) + " ")
                          ])
                        ],
                        1
                      ),
                      _vm.model.rentRansomType == "1"
                        ? _c(
                            "el-row",
                            { staticClass: "block-col-3" },
                            [
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("联系人:")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.contactName) + " "
                                )
                              ]),
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("联系电话:")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.contactMobile) + " "
                                )
                              ]),
                              _c("el-col", { attrs: { span: 10 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("联系地址:")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.contactAddress) + " "
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-row",
                        { staticClass: "block-col-1" },
                        [
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("合同备注:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.remark) + " ")
                          ])
                        ],
                        1
                      ),
                      _c("el-divider"),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("账户类型:")
                            ]),
                            _vm._v(
                              " " + _vm._s(this.model.accountTypeText) + " "
                            )
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("银行户名:")
                            ]),
                            _vm._v(" " + _vm._s(this.model.accountName) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("银行账号:")
                            ]),
                            _vm._v(" " + _vm._s(this.model.accountNo) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-1" },
                        [
                          _c("el-col", { attrs: { span: 14 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("银行+分行+支行:")
                            ]),
                            _vm._v(" " + _vm._s(this.model.bankName) + " ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.model.contractId != ""
            ? _c(
                "el-tab-pane",
                { attrs: { label: "附件", name: "tabAttachment" } },
                [
                  _c("attachment", {
                    ref: "componentAttachment",
                    attrs: {
                      "is-attachment-edit": false,
                      "attachment-contract-id": _vm.model.contractId,
                      "attachment-type": _vm.attachmentType
                    },
                    on: { onAttachmentOpen: _vm.onAttachmentOpen }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.model.contractId != ""
            ? _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: "回单凭证",
                    name: "tabAttachmentReceptionBill"
                  }
                },
                [
                  _c("attachment", {
                    ref: "componentAttachmentReceptionBill",
                    attrs: {
                      "is-attachment-edit": false,
                      "attachment-contract-id": _vm.model.contractId,
                      "attachment-type": _vm.attachmentTypeReceptionBill
                    },
                    on: { onAttachmentOpen: _vm.onAttachmentOpen }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.model.contractId != ""
            ? _c(
                "el-tab-pane",
                { attrs: { label: "结算申请", name: "tabAttachmentCount" } },
                [
                  _c("attachment", {
                    ref: "componentAttachmentCount",
                    attrs: {
                      "is-attachment-edit": true,
                      "attachment-contract-id": _vm.model.contractId,
                      "attachment-type": _vm.attachmentTypeCount
                    },
                    on: { onAttachmentOpen: _vm.onAttachmentOpen }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("el-divider", { attrs: { "content-position": "left" } }, [
        _vm._v("核算确认(对日到期)")
      ]),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "1" },
                  on: {
                    change: function($event) {
                      return _vm.changeCountApplyType()
                    }
                  },
                  model: {
                    value: _vm.countApplyType,
                    callback: function($$v) {
                      _vm.countApplyType = $$v
                    },
                    expression: "countApplyType"
                  }
                },
                [_vm._v("确认")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: "2" },
                  on: {
                    change: function($event) {
                      return _vm.changeCountApplyType()
                    }
                  },
                  model: {
                    value: _vm.countApplyType,
                    callback: function($$v) {
                      _vm.countApplyType = $$v
                    },
                    expression: "countApplyType"
                  }
                },
                [_vm._v("续约")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.countApplyType != "",
              expression: "countApplyType != ''"
            }
          ],
          ref: "form",
          attrs: {
            model: _vm.formModel,
            rules: _vm.rules,
            "label-width": "100px",
            size: "mini"
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "结算日期", prop: "countDate" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "date",
                          placeholder: "选择日期",
                          "value-format": "yyyy-MM-dd"
                        },
                        on: { change: _vm.goOnProductIdChange },
                        model: {
                          value: _vm.formModel.countDate,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "countDate", $$v)
                          },
                          expression: "formModel.countDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "结算类型", prop: "countType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.formModel.countType,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "countType", $$v)
                            },
                            expression: "formModel.countType"
                          }
                        },
                        _vm._l(_vm.selectData.countType, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.text, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              !_vm.isProductCategorySM
                ? _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "结算方式", prop: "applyType" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "", placeholder: "请选择" },
                              model: {
                                value: _vm.formModel.applyType,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "applyType", $$v)
                                },
                                expression: "formModel.applyType"
                              }
                            },
                            _vm._l(_vm.selectData.applyType, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.text, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "权益合计", prop: "countAmountAll" } },
                    [
                      _c("el-input", {
                        attrs: { type: "number", readonly: "", title: "" },
                        model: {
                          value: _vm.formModel.countAmountAll,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "countAmountAll", $$v)
                          },
                          expression: "formModel.countAmountAll"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.formModel.applyType != "04"
                ? _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "提取金额", prop: "applyBackCash" } },
                        [
                          _c("el-input", {
                            attrs: { type: "number", title: "" },
                            model: {
                              value: _vm.formModel.applyBackCash,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "applyBackCash", $$v)
                              },
                              expression: "formModel.applyBackCash"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "手续费", prop: "serviceFee" } },
                    [
                      _c("el-input", {
                        attrs: { type: "number", title: "" },
                        on: {
                          change: function($event) {
                            return _vm.changeFee()
                          }
                        },
                        model: {
                          value: _vm.formModel.serviceFee,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "serviceFee", $$v)
                          },
                          expression: "formModel.serviceFee"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          !_vm.isProductCategorySM && this.countApplyType == "2"
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "续约产品", prop: "goOnProductId" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "", placeholder: "请选择" },
                              on: { change: _vm.goOnProductIdChange },
                              model: {
                                value: _vm.formModel.goOnProductId,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "goOnProductId", $$v)
                                },
                                expression: "formModel.goOnProductId"
                              }
                            },
                            _vm._l(_vm.selectData.goOnProductId, function(
                              item
                            ) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.text, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "续约合同金额",
                            prop: "goOnAmount",
                            "label-width": "110px"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "number",
                              disabled: _vm.model.rentRansomType == "1",
                              title: ""
                            },
                            on: {
                              change: function($event) {
                                return _vm.changeFee()
                              }
                            },
                            model: {
                              value: _vm.formModel.goOnAmount,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "goOnAmount", $$v)
                              },
                              expression: "formModel.goOnAmount"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.model.rentRansomType == "1"
                    ? _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "续约金价",
                                prop: "goOnContractGoldPrice"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { type: "number", title: "" },
                                on: {
                                  change: function($event) {
                                    return _vm.changeGoldWeight()
                                  }
                                },
                                model: {
                                  value: _vm.formModel.goOnContractGoldPrice,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formModel,
                                      "goOnContractGoldPrice",
                                      $$v
                                    )
                                  },
                                  expression: "formModel.goOnContractGoldPrice"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.model.rentRansomType == "1"
                    ? _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "续约克重", prop: "goOnWeight" }
                            },
                            [
                              _c("el-input", {
                                attrs: { type: "number", title: "" },
                                on: {
                                  change: function($event) {
                                    return _vm.changeGoldWeight()
                                  }
                                },
                                model: {
                                  value: _vm.formModel.goOnWeight,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "goOnWeight", $$v)
                                  },
                                  expression: "formModel.goOnWeight"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm.isProduct1004
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "累计积存金额",
                            prop: "totalSaveAmount"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "number", title: "" },
                            model: {
                              value: _vm.formModel.totalSaveAmount,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "totalSaveAmount", $$v)
                              },
                              expression: "formModel.totalSaveAmount"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "累计积存黄金",
                            prop: "totalSaveWeight"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "number", title: "" },
                            model: {
                              value: _vm.formModel.totalSaveWeight,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "totalSaveWeight", $$v)
                              },
                              expression: "formModel.totalSaveWeight"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "赠送黄金(克)",
                            prop: "countGiftWeight"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "number", title: "" },
                            model: {
                              value: _vm.formModel.countGiftWeight,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "countGiftWeight", $$v)
                              },
                              expression: "formModel.countGiftWeight"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "赠送金额", prop: "countGiftAmount" }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "number", title: "" },
                            model: {
                              value: _vm.formModel.countGiftAmount,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "countGiftAmount", $$v)
                              },
                              expression: "formModel.countGiftAmount"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.isProductCategorySM && _vm.formModel.applyType != "01"
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "提取现货合计",
                            prop: "applyBackGoldAmount",
                            "label-width": "110px",
                            "label-position": "left"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "number", readonly: "", title: "" },
                            model: {
                              value: _vm.formModel.applyBackGoldAmount,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.formModel,
                                  "applyBackGoldAmount",
                                  $$v
                                )
                              },
                              expression: "formModel.applyBackGoldAmount"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "提取现货克重",
                            prop: "investGoldWeight",
                            "label-width": "110px",
                            "label-position": "left"
                          }
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "130px" },
                            attrs: { type: "number", readonly: "", title: "" },
                            model: {
                              value: _vm.formModel.investGoldWeight,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "investGoldWeight", $$v)
                              },
                              expression: "formModel.investGoldWeight"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-row",
            [
              !_vm.isProductCategorySM && _vm.formModel.applyType != "01"
                ? _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "费用扣减方式",
                            prop: "feeDeductType",
                            "label-width": "110px",
                            "label-position": "left"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "130px" },
                              attrs: { clearable: "", placeholder: "请选择" },
                              on: {
                                change: function($event) {
                                  return _vm.changeFee()
                                }
                              },
                              model: {
                                value: _vm.formModel.feeDeductType,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "feeDeductType", $$v)
                                },
                                expression: "formModel.feeDeductType"
                              }
                            },
                            _vm._l(_vm.selectData.feeDeductType, function(
                              item
                            ) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.text, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isProductCategorySM && _vm.formModel.applyType != "01"
                ? _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "提货费用", prop: "goldFee" } },
                        [
                          _c("el-input", {
                            attrs: { type: "number", title: "" },
                            on: {
                              change: function($event) {
                                return _vm.changeFee()
                              }
                            },
                            model: {
                              value: _vm.formModel.goldFee,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "goldFee", $$v)
                              },
                              expression: "formModel.goldFee"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "接受回访", prop: "customerVisitClaim" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          on: {
                            change: function($event) {
                              return _vm.changeCustomerVisitClaim()
                            }
                          },
                          model: {
                            value: _vm.formModel.customerVisitClaim,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "customerVisitClaim", $$v)
                            },
                            expression: "formModel.customerVisitClaim"
                          }
                        },
                        _vm._l(_vm.selectData.customerVisitClaim, function(
                          item
                        ) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.text, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "核算备注",
                        prop: "countRemark",
                        placeholder: ""
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          placeholder: "请务必仔细填写核算备注和续约信息"
                        },
                        model: {
                          value: _vm.formModel.countRemark,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "countRemark", $$v)
                          },
                          expression: "formModel.countRemark"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              staticClass: "contract-log-tip",
              attrs: { plain: "", size: "mini", icon: "el-icon-finished" },
              on: {
                click: function($event) {
                  return _vm.showLog()
                }
              }
            },
            [_vm._v("审核日志")]
          ),
          _vm.isShowPrintButton
            ? _c(
                "el-button",
                {
                  staticClass: "print-button",
                  attrs: { plain: "", size: "mini", icon: "el-icon-printer" },
                  on: {
                    click: function($event) {
                      return _vm.printApply()
                    }
                  }
                },
                [_vm._v("打印确认表")]
              )
            : _vm._e(),
          _c(
            "el-button",
            { attrs: { plain: "", size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                plain: "",
                size: "mini",
                loading: _vm.loading,
                disabled: !_vm.isFormChange
              },
              on: { click: _vm.clickSave }
            },
            [_vm._v("暂存")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                plain: "",
                size: "mini",
                loading: _vm.loading
              },
              on: { click: _vm.clickSubmit }
            },
            [_vm._v("提交")]
          )
        ],
        1
      ),
      _vm.model.contractId != ""
        ? _c("contract-log", {
            attrs: {
              "contract-id": _vm.model.contractId,
              active: _vm.contractLogType
            },
            model: {
              value: _vm.showLogDialog,
              callback: function($$v) {
                _vm.showLogDialog = $$v
              },
              expression: "showLogDialog"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }