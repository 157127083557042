import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import attachmentMap from "@/maps/url/crm/attachment/attachment";
import util from "@/libs/util";
import fileUtil from "./fileUtil";
import ATTACHMENT_TYPE from "@/maps/enum/attachment-type";
import { imageThumbnailStart } from "@api/crm/attachment/attachment";
import { getInfo as productInfo } from "@api/crm/setup/product";
export default {
  props: {
    uploadContractId: {
      type: String,
      default: ""
    },
    uploadExtId: {
      type: String,
      default: ""
    },
    uploadType: {
      type: String,
      default: ""
    },
    uploadProductId: {
      type: String,
      default: ""
    },
    uploadExist: {
      type: Array,
      default: []
    },
    idType: {
      //客户证件类型
      type: String,
      default: ""
    },
    rentRansomType: {
      //赎回方式
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      loading: false,
      name: "attachment-upload",
      uploadUrl: process.env.VUE_APP_API + attachmentMap.upload,
      uploadHeader: {
        "X-Token": util.cookies.get("token")
      },
      fileList: [],
      uploadFileName: "file",
      //服务器接收文件名参数
      uploadParams: {},
      //服务器额外参数
      uploadFileCount: 0,
      //上传文件总数
      accept: ".jpg,.jpeg,.png,.gif,.bmp,.wav,.m4a,.mp3,.pdf,.doc,.docx,.xls,.xlsx",
      //上传文件类型
      fileNeedAll: [],
      //系统必须的文件
      fileNeed: [] //当前系统还缺少的必须文件 

    };
  },
  mixins: [dialogMixin],
  showDialog: {
    handler: function handler(val) {},
    immediate: true
  },
  computed: {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.uploadParams = {
            contractId: this.uploadContractId,
            extId: this.uploadExtId,
            type: this.uploadType
          };
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.fileList = [];
      this.uploadFileCount = 0;
      this.fileNeedAll = [];
      this.fileNeed = [];
      this.initUploadNeedFile(); //系统必须的文件列表 
    },
    dialogClose: function dialogClose() {},
    submitUpload: function submitUpload(params) {
      this.$refs.upload.submit(); //原element上传多个文件拆分成多次请求
    },
    handleRemove: function handleRemove(file, fileList) {
      //移除文件，检查是否是系统必须的
      var fileFirstName = file.name.substring(0, file.name.lastIndexOf(".")); //文件名前部

      var array = Object.assign([], this.fileNeedAll); //系统必须的全部文件

      if (array.includes(fileFirstName)) {
        //是系统必须文件
        if (!this.fileNeed.includes(fileFirstName)) {
          this.fileNeed.push(fileFirstName);
        }
      }
    },
    handlePreview: function handlePreview(file) {},
    handleSuccess: function handleSuccess(response, file, fileList) {
      if (response.code == "1") {
        this.uploadFileCount++;

        if (this.uploadFileCount >= fileList.length) {
          //全部上传完成
          this.$message({
            message: "文件已上传",
            type: "success"
          });
          this.$emit("refresh-upload");
          this.$emit("check", fileList); //检查文件

          this.showDialog = false;
          this.uploadFileCount = 0; //全部上传完成，启动压缩任务

          /*          
          let param = {
            contractId: this.uploadContractId,            
            attachType: this.uploadType, 
          };                    
          imageThumbnailStart(param).then(res => {
           //设置个定时，让服务器有时间压缩图片，减少一上传完就打开预览可能打断压缩任务 
           const loading = this.$loading({
              lock: true,
              text: '附件处理中，请稍候...',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            setTimeout(() => {
              loading.close();
            }, 3000);
          });   
          */
        }
      } else {
        this.init();
        this.$message.error(response.message);
      }
    },
    handleError: function handleError(err, file, fileList) {
      this.init();
      this.$message.error("上传文件出错");
    },
    handleBeforeUpload: function handleBeforeUpload(file) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        fileUtil.getOrientation(file).then(function (orient) {
          if (orient && orient === 6 || orient === 3 || orient === 8) {
            var reader = new FileReader();
            var img = new Image();

            reader.onload = function (e) {
              img.src = e.target.result;

              img.onload = function () {
                var data = fileUtil.rotateImage(img, img.width, img.height, file);
                var newFile = fileUtil.dataURLtoFile(data, file.name);
                resolve(newFile);
              };
            };

            reader.readAsDataURL(file);
          } else {
            var fileExtName = file.name.substring(file.name.lastIndexOf(".")); //文件扩展名

            fileExtName = fileExtName.toLowerCase();

            if (_this.accept.indexOf(fileExtName) < 0) {
              _this.$message(file.name + " 文件格式不支持");

              resolve(false);
            } else {
              resolve(true);
            }
          }
        });
      });
    },
    handleChange: function handleChange(file, fileList) {
      //该方法选中文件，上传成功，失败都会触发，状态不同，选中是ready，上传成功是success
      if (file.status == "ready") {
        //文件选中时检查
        //检查文件是否已在列表，避免重复上传
        var fileFirstName = file.name.substring(0, file.name.lastIndexOf(".")); //文件名前部          

        var existCount = 0; //发现文件存在次数             

        for (var i = 0; i < this.uploadExist.length; i++) {
          //原已存在文件列表
          if (fileFirstName == this.uploadExist[i].fileName) {
            existCount++;
            break;
          }
        }

        for (var _i = 0; _i < fileList.length; _i++) {
          //本次选择上传的文件列表
          if (file.name == fileList[_i].name) {
            existCount++;
          }
        }

        if (existCount > 1) {
          this.fileList = fileList.slice(0, -1); //上传列表删除最后新加的元素

          existCount = 0;
          this.$message({
            message: file.name + " 文件已选择",
            type: "warning"
          });
        } else {
          //检查还需要的文件
          for (var _i2 = 0; _i2 < this.fileNeed.length; _i2++) {
            //选择文件，如果必须列表中存在，去掉
            if (this.fileNeed[_i2].indexOf(fileFirstName) >= 0) {
              this.fileNeed.splice(_i2, 1);
              break;
            }
          }
        }
      }
    },
    //初始化上传时本产品必须的附件
    initUploadNeedFile: function initUploadNeedFile() {
      var _this2 = this;

      /* */
      if (this.uploadProductId != null && this.uploadProductId != "") {
        if (this.uploadType == ATTACHMENT_TYPE.CONTRACT_STAFF) {
          //上传类型为：合同附件
          var param = {
            productId: this.uploadProductId
          };
          productInfo(param).then(function (res) {
            if (res.data.attachment != null) {
              var needAll = JSON.parse(res.data.attachment);
              var com = needAll.com; //公共必须

              var con = ""; //合同主体所需，个人合同或机构合同              

              if (_this2.idType == "06") {
                //机构投资者
                con = needAll.org;
              } else {
                con = needAll.person;
              }

              var all = com + "," + con;

              if (_this2.rentRansomType != null && _this2.rentRansomType == '1' && needAll.jewelry != null) {
                //赎回实物                
                all += "," + needAll.jewelry;
              }

              if (needAll.any != null) {
                //任意一个
                all += "," + needAll.any;
              }

              _this2.fileNeedAll = all.split(","); //系统必须的全部文件

              for (var i = 0; i < _this2.fileNeedAll.length; i++) {
                //检查还缺少的文件
                var isExist = false;

                for (var j = 0; j < _this2.uploadExist.length; j++) {
                  if (_this2.fileNeedAll[i].indexOf(_this2.uploadExist[j].fileName) >= 0) {
                    isExist = true;
                    break;
                  }
                }

                if (!isExist) {
                  _this2.fileNeed.push(_this2.fileNeedAll[i]);
                }
              }
            }
          });
        }
      }
    }
  }
};