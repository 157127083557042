//报表汇总
export default {
  contractSum: '/report/contract/sum',
  // 合同汇总  
  sumAudit: '/report/sum/audit',
  // 合同审核统计列表
  sumYearAmountByMonth: '/report/sum/amount/month',
  // 统计机构包括下属机构按月份入金数据
  sumYearAmountByOrg: '/report/sum/amount/org',
  // 统计机构各下属机构入金数据
  countOrgAcceptDay: '/report/count/org/acceptDay',
  // 统计机构各下属机构合同录入后受理天数
  orgMoney: '/report/org/money',
  // 公司业绩追踪
  exportExcelOrgMoney: '/report/org/exportExcelOrgMoney',
  // 公司业绩追踪导出
  orgAnalyze: '/report/org/analyze',
  // 公司业绩剖析
  exportExcelAnalyze: '/report/org/exportExcelAnalyze',
  // 公司业绩分析导出
  moneyInOut: '/report/money/inOut',
  // 公司出入金分析
  exportExcelMoneyInOut: '/report/money/exportExcelMoneyInOut',
  // 公司出入金分析导出
  contractAuditLog: '/report/contract/audit/log',
  // 合同审核日志统计
  contractAuditLogCount: '/report/contract/audit/logCount',
  // 合同审核日志数量统计
  exportExcelContractAudit: '/report/contract/audit/export',
  // 合同审核日志统计导出Excel
  listOrgBusiness: '/report/business/list',
  // 业务汇总
  exportExcelOrgBusiness: '/report/business/exportExcel',
  // 业务汇总
  ///（上线时删除）///////////////////////////////////
  testSumBusiness: '/sms/special/sumBusiness' // 测试短信
  //////////////////////////////////////

};