import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.number.to-fixed.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import BANK from "@/maps/enum/bank";
import { parse, batchAdd } from "@api/crm/bank/bill";
export default {
  name: "bank-bill-add-gs",
  props: {
    propsModel: {
      type: Array,
      default: []
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      formModel: {
        kind: BANK.GS
      },
      //表单
      importText: "",
      //复制粘贴区
      tableData: []
    };
  },
  computed: {},
  created: function created() {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.importText = "";
          this.tableData = [];
          this.formModel = {};
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.importText = "";
      this.tableData = [];
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {},
    // TODO 确定
    confirm: function confirm() {
      var _this = this;

      if (this.tableData.length == 0) {
        this.$message("请解析录入数据");
      } else {
        var param = {
          arrayObject: this.tableData
        };
        this.loading = true;
        batchAdd(param).then(function (res) {
          _this.loading = false;
          _this.showDialog = false;

          _this.$emit("refresh");
        }).catch(function (e) {
          _this.loading = false;
        });
      }
    },
    //解析数据
    clickParse: function clickParse() {
      var _this2 = this;

      if (this.importText != "") {
        var param = {
          kind: BANK.GS,
          data: this.importText
        };
        this.loading = true;
        parse(param).then(function (res) {
          _this2.loading = false;
          res.data.forEach(function (n) {
            //金额取2位小数
            n.debtor = n.debtor.toFixed(2);
          });
          _this2.tableData = res.data;
        }).catch(function (e) {
          _this2.loading = false;
        });
      }
    }
  }
};