//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from '@/mixins/dialog';
import crmMixin from '@/mixins/crm';
import LOG_TYPE from '@/maps/enum/contract-log-type';
import ATTACHMENT_TYPE from '@/maps/enum/attachment-type';
import DATA_FORMAT_TYPE from '@/maps/enum/data-format-type';
import Attachment from '@/components/business/attachment';
import ContractGoldWeight from '@/components/business/contract-gold-weight';
import ContractLog from '@/components/business/contract-log';
import { setExtendAttribute } from '@/libs/crm/crm';
import { getInfo as _getInfo, jewelryOutConfirm } from '@api/crm/apply/apply';
export default {
  name: 'apply-jewelry-out',
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {
    Attachment: Attachment,
    ContractLog: ContractLog,
    ContractGoldWeight: ContractGoldWeight
  },
  mixins: [dialogMixin, crmMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      tabActive: 'tabContract',
      //标签框默认选中
      collapseActive: ['1', '2'],
      //信息折叠默认展开
      attachmentType: ATTACHMENT_TYPE.APPLY_JEWELRY_MOVE_OUT_BILL,
      //附件类型：赎回出库
      showLogDialog: false,
      contractLogType: LOG_TYPE.APPLY,
      attachmentOpened: '',
      //附件打开状态
      formModel: {},
      //表单
      rules: {}
    };
  },
  computed: {
    isProductCountType: function isProductCountType() {
      var isShow = false; // FIXED_DATE_GOLD: { value: '2', text: '固定日到期（黄金类）' },
      // FIXED_DATE: { value: '3', text: '固定日到期（非黄金类）' } ,
      // 判断固定日

      if (this.model.productCountType == '2' || this.model.productCountType == '3') {
        isShow = true;
      }

      return isShow;
    }
  },
  created: function created() {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.getInfo();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.formModel = {};
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {},
    onAttachmentOpen: function onAttachmentOpen() {
      //初始化标签
      this.tabActive = 'tabContract';
      this.attachmentOpened = 'dialog-position';
    },
    //确认发货
    clickJewelryOut: function clickJewelryOut() {
      var _this = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var attachmentCount = _this.$refs.componentAttachmentJewelryOut.tableData.length; //附件数

          if (!_this.propsModel.applyId || attachmentCount == 0) {
            _this.$message('请上传出库附件');
          } else {
            var param = {
              object: {
                applyId: _this.formModel.applyId,
                goodsName: _this.formModel.goodsName,
                goodsWeight: _this.formModel.goodsWeight,
                price: _this.formModel.price
              }
            };
            jewelryOutConfirm(param).then(function (res) {
              _this.loading = false;
              _this.showDialog = false;

              _this.$emit('refresh');
            });
          }
        }
      });
    },
    // TODO 获取详情
    getInfo: function getInfo() {
      var _this2 = this;

      var applyId = this.propsModel.applyId;
      var param = {
        applyId: applyId
      }; //this.loading = true;

      _getInfo(param, DATA_FORMAT_TYPE.USRE_SETUP).then(function (res) {
        //this.loading = false;
        var _res$data = res.data,
            data = _res$data === void 0 ? {} : _res$data;
        _this2.model = data;
        _this2.formModel = {
          //form默认值
          applyId: _this2.model.applyId,
          goodsWeight: _this2.model.contractGoldWeight,
          price: _this2.model.amount
        };
        setExtendAttribute(_this2.model); //包装必要展示字段
      }).catch(function (e) {
        _this2.loading = false;
      });
    },
    showLog: function showLog() {
      //显示审核日志
      this.showLogDialog = true;
    }
  }
};