// 机构
export default {
  add: '/org/add',
  // 新增机构
  edit: '/org/edit',
  // 编辑机构
  remove: '/org/remove',
  // 删除机构
  getInfo: '/org/getInfo',
  // 详情
  getOrgTree: '/org/getTree',
  // 获取机构树 
  getAllTree: '/org/getAllTree',
  // 机构树全树，没有权限管理
  exportExcel: '/org/exportExcel' // 导出     

};