var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "dialog-search-contract",
      attrs: {
        top: "10vh",
        width: "650px",
        title: "平帐录入",
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      [
        _c(
          "div",
          { staticClass: "demo-input-suffix" },
          [
            _vm._v(" 合同编号： "),
            _c("el-input", {
              attrs: { size: "mini", placeholder: "请填写合同编号" },
              on: {
                change: function($event) {
                  return _vm.changeContractCd()
                }
              },
              model: {
                value: _vm.formModel.contractCd,
                callback: function($$v) {
                  _vm.$set(_vm.formModel, "contractCd", $$v)
                },
                expression: "formModel.contractCd"
              }
            })
          ],
          1
        ),
        _c(
          "el-table",
          {
            attrs: {
              data: _vm.tableData,
              border: "",
              "row-style": { height: "20px" },
              "cell-style": { padding: "6px 0" },
              hight: "200"
            }
          },
          [
            _c("el-table-column", {
              attrs: { label: "职位", prop: "title", width: "150" }
            }),
            _c("el-table-column", {
              attrs: { property: "percent", label: "比例", width: "120" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("el-input", {
                        attrs: {
                          size: "mini",
                          placeholder: "请输入内容",
                          type: "number"
                        },
                        on: {
                          change: function($event) {
                            return _vm.changeBonus(scope)
                          }
                        },
                        model: {
                          value: scope.row.percent,
                          callback: function($$v) {
                            _vm.$set(scope.row, "percent", $$v)
                          },
                          expression: "scope.row.percent"
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: { property: "bonus", label: "金额", width: "180" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("el-input", {
                        attrs: {
                          size: "mini",
                          placeholder: "请输入内容",
                          type: "number"
                        },
                        on: {
                          change: function($event) {
                            return _vm.changeBonus(scope)
                          }
                        },
                        model: {
                          value: scope.row.bonus,
                          callback: function($$v) {
                            _vm.$set(scope.row, "bonus", $$v)
                          },
                          expression: "scope.row.bonus"
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: { label: "人员", prop: "bonusUserName", width: "100" }
            }),
            _c("el-table-column", {
              attrs: { label: "操作", width: "60" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-popover",
                        {
                          ref: "popover-" + scope.$index,
                          attrs: {
                            trigger: "click",
                            placement: "right",
                            width: "850"
                          }
                        },
                        [
                          _c("search-user", {
                            ref: "searchUser",
                            on: { onSelectItem: _vm.popSelectUser }
                          }),
                          _c(
                            "el-button",
                            {
                              attrs: { slot: "reference", type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.clickSearchUser(
                                    scope.$index,
                                    scope.row
                                  )
                                }
                              },
                              slot: "reference"
                            },
                            [_vm._v("人员")]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ],
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formModel,
            rules: _vm.rules,
            "label-width": "120px",
            size: "mini"
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 22 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注", prop: "remark" } },
                    [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formModel.remark,
                            expression: "formModel.remark"
                          }
                        ],
                        staticClass: "el-textarea__inner",
                        attrs: { placeholder: "请填写备注" },
                        domProps: { value: _vm.formModel.remark },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.formModel,
                              "remark",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { plain: "", size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", plain: "", size: "mini" },
              on: { click: _vm.confirm }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }