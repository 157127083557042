// 积分管理
var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var baseRoute = 'crm';
export default [{
  path: "".concat(baseRoute, "/integralGift/integral/manage"),
  name: 'integralManage',
  meta: {
    title: '积分管理',
    auth: true,
    cache: true
  },
  component: _import('crm/integralGift/integral')
}, // 积分明细
{
  path: "".concat(baseRoute, "/integralGift/integralDetail"),
  name: 'integralDetail',
  meta: {
    title: '积分明细',
    auth: true,
    cache: true
  },
  component: _import('crm/integralGift/integralDetail')
}, // 奖励规则
{
  path: "".concat(baseRoute, "/integralGift/integralRule"),
  name: 'integralRule',
  meta: {
    title: '积分奖励规则',
    auth: true,
    cache: true
  },
  component: _import('crm/integralGift/integralRule')
}];