//赎回
export default {
  auditFinanceManager: '/apply/auditFinanceManager',
  //财务总监审核
  auditMoney: '/apply/auditMoney',
  //资金审核
  list: '/apply/list',
  //列表
  getInfo: '/apply/getInfo',
  //明细
  listPrint: '/apply/listPrint',
  //批量打印列表
  printConfirm: '/apply/printConfirm',
  //批量打印确认
  jewelryOutConfirm: '/apply/jewelryOutConfirm',
  //批量发货确认
  exportExcel: '/apply/exportExcel',
  // 导出Excel
  exportExcelBill: '/apply/exportExcelBill',
  // 导出出金Excel
  exportExcelPayment: '/apply/exportExcelPayment',
  // 导出付款单Excel
  create: '/apply/create',
  // 创建
  remove: '/apply/remove' // 删除

};