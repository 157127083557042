import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var PUBLIC_CONFIG = {
  labelWidth: 100
}; // 弹窗中form表单配置

export var DIALOG_FORM_CONFIG = {
  id: {
    hidden: true
  },
  name: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '等级描述',
    props: {
      placeholder: '请输入等级描述'
    },
    rules: [{
      required: true,
      message: '等级描述不能为空',
      trigger: 'blur'
    }]
  }),
  condition: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'slot',
    label: '判断条件'
  }),
  logoPicture: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'uploadImage',
    label: 'logo图片',
    // rules: [{ required: true, message: 'logo图片不能为空', trigger: 'blur' }],
    props: {
      placeholder: '最多可传1张，图片大小不超过1MB，支GIF,JPEG,JPG,PNG,BMP格式'
    }
  }),
  backgroundPicture: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'uploadImage',
    label: '背景图片',
    // rules: [{ required: true, message: '背景图片不能为空', trigger: 'blur' }],
    props: {
      placeholder: '最多可传1张，图片大小不超过1MB，支GIF,JPEG,JPG,PNG,BMP格式'
    }
  }),
  frontColor: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'slot',
    slotName: 'frontColor',
    slotType: 'item',
    label: '字体颜色'
  })
};
export var DIALOG_FORM_DATA = {
  id: null,
  name: '',
  ruleMinValue: undefined,
  ruleMaxValue: undefined
};