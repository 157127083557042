// 附件类型枚举常量
export default {
  CONTRACT_STAFF: '1',
  // 认购-合同资料
  RECEPTION_BILL: '2',
  // 认购-回单凭证
  APPLY_FGSKF_SUBMIT_STAFF: '3',
  // 赎回-申请资料
  APPLY_PAYMENT_REQUEST: '4',
  // 赎回-付息请求
  APPLY_PAYMENT_BILL: '5',
  // 赎回-付款凭证
  APPLY_RECEPTION_BILL: '6',
  // 赎回-回单凭证
  APPLY_JEWELRY_MOVE_OUT_BILL: '7',
  // 赎回-出库单
  RENEW_CONTRACT_STAFF: '8',
  // 续约-申请资料
  RENEW_RECEPTION_BILL: '9',
  // 续约-回单凭证
  RENEW_PAYMENT_BILL: '10',
  // 续约-付款凭证
  CHANGE_APPLY_STAFF: '11',
  // 变更-变更申请    
  INTEREST_STAFF: '13',
  //付息-申请,
  INTEREST_BILL: '14',
  //付息-回单凭证,
  CONTRACT_PARTICULAR_APPLY_STAFF: '15',
  // 合同-特殊申请
  CONTRACT_GIFT_CKECK: '16',
  // 礼品-文件审批表
  CONTRACT_GIFT_CONFIRM: '17',
  // 礼品-礼品签收表
  CONTRACT_GIFT_DESCRIBE: '18',
  // 礼品-申请说明
  CONTRACT_GIFT_CHANGE: '19',
  // 礼品-变更申请说明
  CONTRACT_AUDIO: '30',
  // 合同录音
  VISIT_AUDIO: '31',
  // 回访录音
  CONTRACT_VIDEO: '32',
  // 合同视频
  COMMISSION: '33',
  // 推介费
  DOCUMENT_TEMPLATE: 'document_template' // 文档模板 

};