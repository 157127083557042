var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isEdit
        ? _c(
            "el-button",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: "customer-edit",
                  expression: "'customer-edit'"
                }
              ],
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.edit }
            },
            [_vm._v("编辑")]
          )
        : _vm._e(),
      _vm.isEdit
        ? _c(
            "el-button",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: "customer-edit",
                  expression: "'customer-edit'"
                }
              ],
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.submit }
            },
            [_vm._v("保存")]
          )
        : _vm._e(),
      _vm.isEdit
        ? _c(
            "el-button",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: "customer-edit",
                  expression: "'customer-edit'"
                }
              ],
              attrs: { size: "mini" },
              on: { click: _vm.cancel }
            },
            [_vm._v("取消")]
          )
        : _vm._e(),
      _c(
        "div",
        [
          _c("el-divider", { attrs: { "content-position": "left" } }, [
            _vm._v("基本信息")
          ]),
          _c(
            "el-descriptions",
            {
              staticClass: "margin-top",
              attrs: {
                contentStyle: _vm.contentStyle,
                labelStyle: _vm.labelStyle,
                column: 4,
                direction: "vertical",
                colon: false,
                border: ""
              }
            },
            [
              _c("el-descriptions-item", { attrs: { label: "注册电话" } }, [
                _vm._v(_vm._s(_vm.basicInfo.registerMobile))
              ]),
              _c("el-descriptions-item", { attrs: { label: "客户" } }, [
                _vm.isEdit && _vm.basicInfo.authStatus !== 1
                  ? _c(
                      "div",
                      [
                        _c("el-input", {
                          attrs: { size: "mini" },
                          model: {
                            value: _vm.formData.customerName,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "customerName", $$v)
                            },
                            expression: "formData.customerName"
                          }
                        })
                      ],
                      1
                    )
                  : _c("div", [_vm._v(_vm._s(_vm.basicInfo.customerName))])
              ]),
              _c("el-descriptions-item", { attrs: { label: "性别" } }, [
                _vm.isEdit
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择",
                              clearable: "",
                              size: "mini"
                            },
                            model: {
                              value: _vm.formData.sex,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "sex", $$v)
                              },
                              expression: "formData.sex"
                            }
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "男", value: "1" }
                            }),
                            _c("el-option", {
                              attrs: { label: "女", value: "0" }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _c("div", [_vm._v(_vm._s(_vm.basicInfo.sexValue))])
              ]),
              _c("el-descriptions-item", { attrs: { label: "年龄" } }, [
                _vm.isEdit
                  ? _c(
                      "div",
                      [
                        _c("el-input", {
                          attrs: { size: "mini" },
                          model: {
                            value: _vm.formData.age,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "age", $$v)
                            },
                            expression: "formData.age"
                          }
                        })
                      ],
                      1
                    )
                  : _c("div", [_vm._v(_vm._s(_vm.basicInfo.age))])
              ]),
              _c("el-descriptions-item", { attrs: { label: "民族" } }, [
                _vm.isEdit
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择",
                              clearable: "",
                              size: "mini"
                            },
                            model: {
                              value: _vm.formData.nation,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "nation", $$v)
                              },
                              expression: "formData.nation"
                            }
                          },
                          _vm._l(_vm.nationList, function(item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _c("div", [_vm._v(_vm._s(_vm.basicInfo.nationValue))])
              ]),
              _c("el-descriptions-item", { attrs: { label: "出生地" } }, [
                _vm.isEdit
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择",
                              clearable: "",
                              size: "mini"
                            },
                            model: {
                              value: _vm.formData.birthplace,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "birthplace", $$v)
                              },
                              expression: "formData.birthplace"
                            }
                          },
                          _vm._l(_vm.provinceList, function(item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _c("div", [_vm._v(_vm._s(_vm.basicInfo.birthplaceValue))])
              ]),
              _c("el-descriptions-item", { attrs: { label: "客户电话" } }, [
                _vm.isEdit
                  ? _c(
                      "div",
                      [
                        _c("el-input", {
                          attrs: { size: "mini" },
                          model: {
                            value: _vm.formData.mobile,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "mobile", $$v)
                            },
                            expression: "formData.mobile"
                          }
                        })
                      ],
                      1
                    )
                  : _c("div", [_vm._v(_vm._s(_vm.basicInfo.mobile))])
              ]),
              _c("el-descriptions-item", { attrs: { label: "微信" } }, [
                _vm.isEdit
                  ? _c(
                      "div",
                      [
                        _c("el-input", {
                          attrs: { size: "mini" },
                          model: {
                            value: _vm.formData.weChat,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "weChat", $$v)
                            },
                            expression: "formData.weChat"
                          }
                        })
                      ],
                      1
                    )
                  : _c("div", [_vm._v(_vm._s(_vm.basicInfo.weChat))])
              ]),
              _c("el-descriptions-item", { attrs: { label: "证件类型" } }, [
                _vm.isEdit && _vm.basicInfo.authStatus !== 1
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择",
                              clearable: "",
                              size: "mini"
                            },
                            model: {
                              value: _vm.formData.idType,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "idType", $$v)
                              },
                              expression: "formData.idType"
                            }
                          },
                          _vm._l(_vm.docTypeList, function(item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _c("div", [_vm._v(_vm._s(_vm.basicInfo.idTypeValue))])
              ]),
              _c("el-descriptions-item", { attrs: { label: "证件编码" } }, [
                _vm.isEdit && _vm.basicInfo.authStatus !== 1
                  ? _c(
                      "div",
                      [
                        _c("el-input", {
                          attrs: { size: "mini" },
                          on: { change: _vm.getinfo },
                          model: {
                            value: _vm.formData.identification,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "identification", $$v)
                            },
                            expression: "formData.identification"
                          }
                        })
                      ],
                      1
                    )
                  : _c("div", [_vm._v(_vm._s(_vm.basicInfo.identification))])
              ]),
              _c("el-descriptions-item", { attrs: { label: "生日" } }, [
                _vm.isEdit
                  ? _c(
                      "div",
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "178px" },
                          attrs: {
                            type: "date",
                            placeholder: "请选择",
                            "value-format": "yyyy-MM-dd",
                            size: "mini"
                          },
                          model: {
                            value: _vm.formData.birthday,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "birthday", $$v)
                            },
                            expression: "formData.birthday"
                          }
                        })
                      ],
                      1
                    )
                  : _c("div", [_vm._v(_vm._s(_vm.basicInfo.birthday))])
              ]),
              _c("el-descriptions-item", { attrs: { label: "家庭地址" } }, [
                _vm.isEdit
                  ? _c(
                      "div",
                      [
                        _c("el-input", {
                          attrs: { size: "mini" },
                          model: {
                            value: _vm.formData.address,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "address", $$v)
                            },
                            expression: "formData.address"
                          }
                        })
                      ],
                      1
                    )
                  : _c("div", [_vm._v(_vm._s(_vm.basicInfo.address))])
              ]),
              _c("el-descriptions-item", { attrs: { label: "邮箱" } }, [
                _vm.isEdit
                  ? _c(
                      "div",
                      [
                        _c("el-input", {
                          attrs: { size: "mini" },
                          model: {
                            value: _vm.formData.email,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "email", $$v)
                            },
                            expression: "formData.email"
                          }
                        })
                      ],
                      1
                    )
                  : _c("div", [_vm._v(_vm._s(_vm.basicInfo.email))])
              ]),
              _vm.isHasPermission("customer-category")
                ? _c("el-descriptions-item", { attrs: { label: "客户类别" } }, [
                    _vm.isEdit && _vm.basicInfo.authStatus !== 1
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择",
                                  clearable: "",
                                  size: "mini"
                                },
                                model: {
                                  value: _vm.formData.customerCategory,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "customerCategory",
                                      $$v
                                    )
                                  },
                                  expression: "formData.customerCategory"
                                }
                              },
                              _vm._l(_vm.customerCategoryList, function(item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _c("div", [
                          _vm._v(_vm._s(_vm.basicInfo.customerCategoryValue))
                        ])
                  ])
                : _c("el-descriptions-item", { attrs: { label: "" } }),
              _c("el-descriptions-item", { attrs: { label: "" } }),
              _c("el-descriptions-item", { attrs: { label: "" } })
            ],
            1
          ),
          _c("el-divider", { attrs: { "content-position": "left" } }, [
            _vm._v("业务信息")
          ]),
          _c(
            "el-descriptions",
            {
              staticClass: "margin-top",
              attrs: {
                contentStyle: _vm.contentStyle,
                labelStyle: _vm.labelStyle,
                column: 4,
                direction: "vertical",
                colon: false,
                border: ""
              }
            },
            [
              _c("el-descriptions-item", { attrs: { label: "理顾" } }, [
                _vm._v(_vm._s(_vm.businessInfo.agentUserName))
              ]),
              _c("el-descriptions-item", { attrs: { label: "理顾是否离职" } }, [
                _vm._v(
                  _vm._s(
                    _vm.businessInfo.agentUserHasResigned === 1
                      ? "已离职"
                      : "在职"
                  )
                )
              ]),
              _c("el-descriptions-item", { attrs: { label: "理顾部门状态" } }, [
                _vm._v(
                  _vm._s(_vm.businessInfo.orgStatus === "1" ? "启用" : "停用")
                )
              ]),
              _c("el-descriptions-item", { attrs: { label: "部门" } }, [
                _vm._v(_vm._s(_vm.businessInfo.departmentName))
              ]),
              _c("el-descriptions-item", { attrs: { label: "相关人" } }, [
                _vm._v(_vm._s(_vm.businessInfo.relatedUsersName))
              ]),
              _c("el-descriptions-item", { attrs: { label: "影响力" } }, [
                _vm._v(_vm._s(_vm.businessInfo.influenceAccount))
              ]),
              _c("el-descriptions-item", { attrs: { label: "注册终端" } }, [
                _vm._v(_vm._s(_vm.businessInfo.registerTerminalValue))
              ]),
              _c("el-descriptions-item", { attrs: { label: "注册渠道" } }, [
                _vm._v(_vm._s(_vm.businessInfo.registerChannelValue))
              ]),
              _c("el-descriptions-item", { attrs: { label: "客源活动" } }, [
                _vm._v(_vm._s(_vm.businessInfo.registrationActivity))
              ]),
              _c("el-descriptions-item", { attrs: { label: "推荐人" } }, [
                _vm._v(_vm._s(_vm.businessInfo.referrerName))
              ]),
              _c("el-descriptions-item", { attrs: { label: "客户类型" } }, [
                _vm._v(_vm._s(_vm.businessInfo.customerTypeValue))
              ]),
              _c("el-descriptions-item", { attrs: { label: "客户等级" } }, [
                _vm._v(_vm._s(_vm.businessInfo.customerLevelValue))
              ]),
              _c("el-descriptions-item", { attrs: { label: "会员积分" } }, [
                _vm._v(_vm._s(_vm.businessInfo.score))
              ]),
              _c("el-descriptions-item", { attrs: { label: "客户年限" } }, [
                _vm._v(_vm._s(_vm.businessInfo.customerYearLimit))
              ]),
              _c("el-descriptions-item", { attrs: { label: "投顾产品" } }, [
                _vm._v(_vm._s(_vm.businessInfo.investProductAmount) + "万元")
              ]),
              _c("el-descriptions-item", { attrs: { label: "保险产品" } }, [
                _vm._v(_vm._s(_vm.businessInfo.insuranceProductAmount) + "万元")
              ]),
              _c("el-descriptions-item", { attrs: { label: "珠宝产品" } }, [
                _vm._v(_vm._s(_vm.businessInfo.jewelryProductAmount) + "万元")
              ]),
              _c("el-descriptions-item", { attrs: { label: "回收产品" } }, [
                _vm._v(_vm._s(_vm.businessInfo.recycleProductAmount) + "万元")
              ])
            ],
            1
          ),
          _c("el-divider", { attrs: { "content-position": "left" } }, [
            _vm._v("详细信息")
          ]),
          _c(
            "el-descriptions",
            {
              staticClass: "margin-top",
              attrs: {
                contentStyle: _vm.contentStyle,
                labelStyle: _vm.labelStyle,
                column: 4,
                direction: "vertical",
                colon: false,
                border: ""
              }
            },
            [
              _c("el-descriptions-item", { attrs: { label: "工作单位" } }, [
                _vm.isEdit
                  ? _c(
                      "div",
                      [
                        _c("el-input", {
                          attrs: { size: "mini" },
                          model: {
                            value: _vm.formData.company,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "company", $$v)
                            },
                            expression: "formData.company"
                          }
                        })
                      ],
                      1
                    )
                  : _c("div", [_vm._v(_vm._s(_vm.detailInfo.company))])
              ]),
              _c("el-descriptions-item", { attrs: { label: "行业" } }, [
                _vm.isEdit
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择",
                              clearable: "",
                              size: "mini"
                            },
                            model: {
                              value: _vm.formData.industry,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "industry", $$v)
                              },
                              expression: "formData.industry"
                            }
                          },
                          _vm._l(_vm.industryList, function(item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _c("div", [_vm._v(_vm._s(_vm.detailInfo.industryValue))])
              ]),
              _c("el-descriptions-item", { attrs: { label: "身份" } }, [
                _vm.isEdit
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择",
                              clearable: "",
                              size: "mini"
                            },
                            model: {
                              value: _vm.formData.identity,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "identity", $$v)
                              },
                              expression: "formData.identity"
                            }
                          },
                          _vm._l(_vm.identityList, function(item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _c("div", [_vm._v(_vm._s(_vm.detailInfo.identityValue))])
              ]),
              _c("el-descriptions-item", { attrs: { label: "性格" } }, [
                _vm.isEdit
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择",
                              clearable: "",
                              size: "mini"
                            },
                            scopedSlots: _vm._u(
                              [
                                _vm.formData.character
                                  ? {
                                      key: "prefix",
                                      fn: function() {
                                        return [
                                          _c("div", {
                                            staticClass: "character-select",
                                            style:
                                              "background: " +
                                              _vm.formData.character +
                                              ";"
                                          })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  : null
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: _vm.formData.character,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "character", $$v)
                              },
                              expression: "formData.character"
                            }
                          },
                          _vm._l(_vm.characterList, function(item) {
                            return _c(
                              "el-option",
                              {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "character-select-option" },
                                  [
                                    _c("div", {
                                      staticClass:
                                        "character-select-option-item",
                                      style: "background:" + item.value + ";"
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "margin-left": "10px" }
                                      },
                                      [_vm._v(_vm._s(item.label))]
                                    )
                                  ]
                                )
                              ]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _c("div", { staticClass: "character-select-option" }, [
                      _c("div", {
                        staticClass: "character-select-option-item",
                        style: "background:" + _vm.detailInfo.character + ";"
                      }),
                      _c("div", { staticStyle: { "margin-left": "10px" } }, [
                        _vm._v(_vm._s(_vm.detailInfo.characterValue))
                      ])
                    ])
              ]),
              _c("el-descriptions-item", { attrs: { label: "学历" } }, [
                _vm.isEdit
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择", size: "mini" },
                            model: {
                              value: _vm.formData.education,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "education", $$v)
                              },
                              expression: "formData.education"
                            }
                          },
                          _vm._l(_vm.educationList, function(item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _c("div", [_vm._v(_vm._s(_vm.detailInfo.educationValue))])
              ]),
              _c("el-descriptions-item", { attrs: { label: "毕业院校" } }, [
                _vm.isEdit
                  ? _c(
                      "div",
                      [
                        _c("el-input", {
                          attrs: { size: "mini" },
                          model: {
                            value: _vm.formData.school,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "school", $$v)
                            },
                            expression: "formData.school"
                          }
                        })
                      ],
                      1
                    )
                  : _c("div", [_vm._v(_vm._s(_vm.detailInfo.school))])
              ]),
              _c("el-descriptions-item", { attrs: { label: "爱好" } }, [
                _vm.isEdit
                  ? _c(
                      "div",
                      [
                        _c("el-input", {
                          attrs: { size: "mini" },
                          model: {
                            value: _vm.formData.hobby,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "hobby", $$v)
                            },
                            expression: "formData.hobby"
                          }
                        })
                      ],
                      1
                    )
                  : _c("div", [_vm._v(_vm._s(_vm.detailInfo.hobby))])
              ]),
              _c("el-descriptions-item", { attrs: { label: "忌讳内容" } }, [
                _vm.isEdit
                  ? _c(
                      "div",
                      [
                        _c("el-input", {
                          attrs: { size: "mini" },
                          model: {
                            value: _vm.formData.taboo,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "taboo", $$v)
                            },
                            expression: "formData.taboo"
                          }
                        })
                      ],
                      1
                    )
                  : _c("div", [_vm._v(_vm._s(_vm.detailInfo.taboo))])
              ]),
              _c("el-descriptions-item", { attrs: { label: "投资偏好" } }, [
                _vm.isEdit
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择", size: "mini" },
                            model: {
                              value: _vm.formData.icHobby,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "icHobby", $$v)
                              },
                              expression: "formData.icHobby"
                            }
                          },
                          _vm._l(_vm.InvestmentPreferList, function(item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _c("div", [_vm._v(_vm._s(_vm.detailInfo.icHobbyValue))])
              ]),
              _c("el-descriptions-item", { attrs: { label: "投资经验" } }, [
                _vm.isEdit
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择", size: "mini" },
                            model: {
                              value: _vm.formData.icExperience,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "icExperience", $$v)
                              },
                              expression: "formData.icExperience"
                            }
                          },
                          _vm._l(_vm.experienceList, function(item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _c("div", [
                      _vm._v(_vm._s(_vm.detailInfo.icExperienceValue))
                    ])
              ]),
              _c("el-descriptions-item", { attrs: { label: "纪念日" } }, [
                _vm.isEdit
                  ? _c(
                      "div",
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "178px" },
                          attrs: {
                            type: "date",
                            placeholder: "请选择",
                            "value-format": "yyyy-MM-dd",
                            size: "mini"
                          },
                          model: {
                            value: _vm.formData.memorialDay,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "memorialDay", $$v)
                            },
                            expression: "formData.memorialDay"
                          }
                        })
                      ],
                      1
                    )
                  : _c("div", [_vm._v(_vm._s(_vm.detailInfo.memorialDay))])
              ]),
              _c("el-descriptions-item", { attrs: { label: "认证状态" } }, [
                _vm._v(_vm._s(_vm.basicInfo.authStatusValue))
              ])
            ],
            1
          ),
          _c("el-divider", { attrs: { "content-position": "left" } }, [
            _vm._v("系统信息")
          ]),
          _c(
            "el-descriptions",
            {
              staticClass: "margin-top",
              attrs: {
                contentStyle: _vm.contentStyle,
                labelStyle: _vm.labelStyle,
                column: 4,
                direction: "vertical",
                colon: false,
                border: ""
              }
            },
            [
              _c("el-descriptions-item", { attrs: { label: "创建人" } }, [
                _vm._v(_vm._s(_vm.basicInfo.createUserName))
              ]),
              _c("el-descriptions-item", { attrs: { label: "创建时间" } }, [
                _vm._v(_vm._s(_vm.basicInfo.createTime))
              ]),
              _c("el-descriptions-item", { attrs: { label: "更新人" } }, [
                _vm._v(_vm._s(_vm.basicInfo.updateUserName))
              ]),
              _c("el-descriptions-item", { attrs: { label: "更新时间" } }, [
                _vm._v(_vm._s(_vm.basicInfo.updateTime))
              ]),
              _c("el-descriptions-item", { attrs: { label: "客户ID" } }, [
                _vm._v(_vm._s(_vm.basicInfo.customerCode))
              ]),
              _c("el-descriptions-item", { attrs: { label: "客户完善度%" } }, [
                _vm._v(_vm._s(_vm.basicInfo.dataIntegrity))
              ]),
              _c("el-descriptions-item", { attrs: { label: "客户状态" } }, [
                _vm._v(_vm._s(_vm.basicInfo.status))
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }