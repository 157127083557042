// 表头
export default [{
  label: '名称',
  prop: 'orgName'
}, {
  label: '编号',
  prop: 'orgCd',
  width: '150px'
}, {
  label: '简称',
  prop: 'orgNameAbbr',
  width: '200px'
}, {
  label: '类型',
  prop: 'orgTypeText',
  width: '100px'
}, {
  label: '顺序',
  prop: 'orgIndex',
  width: '100px'
}, {
  label: '是否可用',
  prop: 'enabled',
  width: '100px',
  type: 'slot'
}];