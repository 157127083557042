var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page-table" },
    [
      _c("j-page", {
        ref: "jPage",
        attrs: {
          filters: _vm.topForm,
          "filters-data": _vm.filtersData,
          filtersFormatter: _vm.filtersFormatter,
          table: _vm.tableConfig,
          "table-label": _vm.tableLabel,
          "http-config": _vm.httpConfig
        },
        on: {
          "update:filtersData": function($event) {
            _vm.filtersData = $event
          },
          "update:filters-data": function($event) {
            _vm.filtersData = $event
          },
          "table-click": _vm.tableClick,
          "table-row-click": _vm.tableRowClick,
          "load:success": _vm.loadSuccess
        },
        scopedSlots: _vm._u([
          {
            key: "year",
            fn: function() {
              return [
                _c("el-input", {
                  attrs: { controls: false, placeholder: "开始年限" },
                  model: {
                    value: _vm.filtersData.beginYearLimit,
                    callback: function($$v) {
                      _vm.$set(_vm.filtersData, "beginYearLimit", $$v)
                    },
                    expression: "filtersData.beginYearLimit"
                  }
                }),
                _vm._v(" 至 "),
                _c("el-input", {
                  attrs: { controls: false, placeholder: "结束年限" },
                  model: {
                    value: _vm.filtersData.endYearLimit,
                    callback: function($$v) {
                      _vm.$set(_vm.filtersData, "endYearLimit", $$v)
                    },
                    expression: "filtersData.endYearLimit"
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "importBtn",
            fn: function() {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: {
                      click: function($event) {
                        _vm.visible = true
                      }
                    }
                  },
                  [_vm._v("导入")]
                ),
                _c(
                  "j-dialog",
                  {
                    attrs: {
                      visible: _vm.visible,
                      title: "Excel导入",
                      height: "300%"
                    },
                    on: {
                      "update:visible": function($event) {
                        _vm.visible = $event
                      },
                      close: _vm.hide,
                      confirm: _vm.upSubmit
                    }
                  },
                  [
                    _c(
                      "el-upload",
                      {
                        ref: "upload",
                        staticClass: "upload-demo",
                        attrs: {
                          action: _vm.uploadUrl,
                          accept: ".csv,.xls,.xlsx",
                          "auto-upload": _vm.upload,
                          "before-remove": _vm.beforeRemove,
                          "on-remove": _vm.handleRemove,
                          "on-success": _vm.handlerRequest,
                          limit: 1,
                          "on-exceed": _vm.handleExceed,
                          "file-list": _vm.fileList,
                          "on-change": _vm.handleChange,
                          "on-error": _vm.failed,
                          "before-upload": _vm.beforeUpload,
                          headers: _vm.headerObj
                        }
                      },
                      [
                        _c(
                          "el-button",
                          { attrs: { size: "small", type: "primary" } },
                          [_vm._v("选择文件")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "el-upload__tip",
                            attrs: { slot: "tip" },
                            slot: "tip"
                          },
                          [
                            _vm._v(
                              " 只能上传.csv,.xls,.xlsx文件，单次只能引入一个文件！ "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "up-Excel",
                        attrs: {
                          href: "/static/excel模板.xlsx",
                          download: "Excel模板"
                        }
                      },
                      [_vm._v("下载导入模板")]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("add", {
        ref: "addUserId",
        attrs: { visible: _vm.addVisible },
        on: { close: _vm.close, clickAddUserId: _vm.AddUserId }
      }),
      _c(
        "j-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading.fullscreen.lock",
              value: _vm.allotConfirmLoading,
              expression: "allotConfirmLoading",
              modifiers: { fullscreen: true, lock: true }
            }
          ],
          attrs: { visible: _vm.openAllot, title: _vm.titles },
          on: {
            "update:visible": function($event) {
              _vm.openAllot = $event
            },
            confirm: _vm.allotConfirm,
            close: _vm.AddUserIdHide
          }
        },
        [
          _vm.isAddOption
            ? _c(
                "el-radio-group",
                {
                  staticClass: "radio-group",
                  on: { change: _vm.transferTypeChange },
                  model: {
                    value: _vm.allotForm.transferType,
                    callback: function($$v) {
                      _vm.$set(_vm.allotForm, "transferType", $$v)
                    },
                    expression: "allotForm.transferType"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v(
                      "转移理顾（已选客户数：" +
                        _vm._s(_vm.transferCustomerCount) +
                        "）"
                    )
                  ]),
                  _c("el-radio", { attrs: { label: 2 } }, [
                    _vm._v(
                      "转移相关人（已选客户数：" +
                        _vm._s(_vm.transferCustomerCount) +
                        "）"
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form",
            {
              attrs: { inline: true, model: _vm.userSelectData, size: "small" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c("el-cascader", {
                    attrs: {
                      options: _vm.orgTreeData,
                      props: _vm.orgProps,
                      placeholder: "请选择部门",
                      filterable: "",
                      clearable: ""
                    },
                    model: {
                      value: _vm.userSelectData.orgCd,
                      callback: function($$v) {
                        _vm.$set(_vm.userSelectData, "orgCd", $$v)
                      },
                      expression: "userSelectData.orgCd"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入姓名" },
                    model: {
                      value: _vm.userSelectData.userName,
                      callback: function($$v) {
                        _vm.$set(_vm.userSelectData, "userName", $$v)
                      },
                      expression: "userSelectData.userName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.userSearch }
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.userTableData, "tooltip-effect": "dark" },
              on: { "selection-change": _vm.handleSelectionChange }
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" }
              }),
              _c("el-table-column", {
                attrs: { prop: "userName", label: "姓名" }
              }),
              _c("el-table-column", {
                attrs: { prop: "orgName", label: "部门" }
              }),
              _c("el-table-column", {
                attrs: { prop: "userCode", label: "工号" }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "j-dialog",
        {
          attrs: { visible: _vm.openMerge, title: "合并客户", height: "100px" },
          on: {
            "update:visible": function($event) {
              _vm.openMerge = $event
            },
            confirm: _vm.mergeConfirm
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.mergeConfirm()
                        }
                      }
                    },
                    [_vm._v("开始合并")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", { staticStyle: { background: "#e8e8e8" } }, [
            _c("div", [_vm._v("【注意事项】")]),
            _c("div", [
              _vm._v(
                "1、只有同时拥有合并客户按钮权限和需合并客户编辑权限才能合并成功。"
              )
            ]),
            _c("div", [
              _vm._v(
                " 2、有证件编码的是主客户，合并客户中有且只能有一个主客户；合并规则是以主客户为准，如果主客户为空，则将非主客户信息并入主客户。 "
              )
            ]),
            _c("div", [
              _vm._v(
                " 3、合并客户后非主客户将被停用，合并后的客户中将保留最早的创建日期及最晚的跟进时间。 "
              )
            ]),
            _c("div", [
              _vm._v("4、合并后主客户的会员积分需要加上非主客户的积分。")
            ]),
            _c("div", [
              _vm._v(
                "5、合并之后，原客户下的跟进记录、活动信息都迁移到主客户中，请谨慎合并。"
              )
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }