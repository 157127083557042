import _toConsumableArray from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from '@/mixins/dialog';
import crmMixin from '@/mixins/crm';
import Attachment from '@/components/business/attachment';
import SearchUser from '@/components/business/search-user';
import Contract from '@/components/business/contract';
import ContractGoldWeight from '@/components/business/contract-gold-weight';
import ContractLog from '@/components/business/contract-log';
import ATTACHMENT_TYPE from '@/maps/enum/attachment-type';
import LOG_TYPE from '@/maps/enum/contract-log-type';
import ENUM from '@/maps/enum/enum';
import PRODUCT from '@/maps/enum/product';
import DATA_FORMAT_TYPE from '@/maps/enum/data-format-type';
import { getEnumList } from '@api/common/common';
import { listEnum } from '@api/crm/setup/product';
import { computeContractEndDate } from '@/libs/crm/crm';
import { getInfo as _getBuyInfo, computeEarnings } from '@api/crm/buy/buy';
import { save, submit, getInfo as _getInfo } from '@api/crm/change/change';
export default {
  name: 'change-new-edit',
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {
    Attachment: Attachment,
    SearchUser: SearchUser,
    Contract: Contract,
    ContractGoldWeight: ContractGoldWeight,
    ContractLog: ContractLog
  },
  mixins: [dialogMixin, crmMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      formModel: {},
      //表单
      tempFormModel: {},
      // 暂存表单值
      tabActive: 'tabContract',
      //标签框默认选中
      selectData: {
        //表单各项初始数据
        idType: [],
        //证件类型
        timeLimit: [],
        //合同期限
        returnPeriod: [],
        //返还周期
        payMethod: [],
        //收款方式
        rentRansomType: [],
        //租赁赎回方式
        accountType: [],
        //账户类型,
        productId: [],
        //产品,
        renewMethod: [],
        //续约方式
        renewType: [],
        //续约类型
        saveMethod: [] //整存/零存
        // contractSignType: [
        //   { text: "否", value: "0" },
        //   { text: "转让", value: "04" },
        // ], //合同转让

      },
      showSearchCustomer: false,
      showSearchUser: false,
      attachmentType: ATTACHMENT_TYPE.CONTRACT_STAFF,
      //附件类型
      attachmentTypeChange: ATTACHMENT_TYPE.CHANGE_APPLY_STAFF,
      //附件类型
      showLogDialog: false,
      contractLogType: LOG_TYPE.CHANGE,
      isHaveFloatRate: false,
      //是否有浮动收益率
      isAttachmentCanEdit: false,
      //附件是否可编辑（可上传删除）
      attachmentOpened: '',
      //附件打开状态
      goldWeightDetail: {},
      //克重明细
      bankCardList: [],
      //客户银行卡信息
      showBankCardButton: true,
      //是否显示校验银行卡按钮
      bankCardButtonDisabled: false,
      //银行卡校验按钮是否可用
      rules: {
        contractCd: [{
          required: true,
          message: '请填写合同编号',
          trigger: 'input'
        }],
        customerName: [{
          required: true,
          message: '请填写客户姓名',
          trigger: 'input'
        }],
        identification: [{
          required: true,
          message: '请填写证件号码',
          trigger: 'input'
        }],
        mobile: [{
          required: true,
          message: '请填写联系方式',
          trigger: 'input'
        }],
        userName: [{
          required: true,
          message: '请选择经纪人',
          trigger: 'input'
        }],
        address: [{
          required: true,
          message: '请填写客户地址',
          trigger: 'input'
        }],
        timeLimit: [{
          required: true,
          message: '请选择合同期限',
          trigger: 'input'
        }],
        returnPeriod: [{
          required: true,
          message: '请选择返还周期',
          trigger: 'input'
        }],
        startDate: [{
          required: true,
          message: '请选择开始日期',
          trigger: 'input'
        }],
        endDate: [{
          required: true,
          message: '请选择到期日期',
          trigger: 'input'
        }],
        payMethod: [{
          required: true,
          message: '请选择收款方式',
          trigger: 'input'
        }],
        amount: [{
          required: true,
          message: '请填写合同金额',
          trigger: 'input'
        }],
        expectEarnings: [{
          required: true,
          message: '请填写预期收益',
          trigger: 'input'
        }],
        contractGoldPrice: [{
          required: true,
          message: '请填写合同金价',
          trigger: 'input'
        }],
        rentRansomType: [{
          required: true,
          message: '请选择租赁赎回方式',
          trigger: 'input'
        }],
        bankName: [{
          required: true,
          message: '请填写银行+支行信息',
          trigger: 'input'
        }],
        accountNo: [{
          required: true,
          message: '请填写银行帐号',
          trigger: 'input'
        }],
        accountName: [{
          required: true,
          message: '请填写银行户名',
          trigger: 'input'
        }],
        renewAmount: [{
          required: true,
          message: '请填写续约金额',
          trigger: 'input'
        }],
        renewMethod: [{
          required: true,
          message: '请选择续约方式',
          trigger: 'input'
        }],
        changeDesc: [{
          required: true,
          message: '请填写变更内容',
          trigger: 'input'
        }]
      }
    };
  },
  created: function created() {
    var _this = this;

    //证件类型
    getEnumList({
      enumCd: ENUM.ENUM_CD_0208
    }).then(function (res) {
      _this.selectData.idType = res;
    }); //合同期限

    getEnumList({
      enumCd: ENUM.ENUM_CD_0201
    }).then(function (res) {
      _this.selectData.timeLimit = res;
    }); //返还周期

    getEnumList({
      enumCd: ENUM.ENUM_CD_0202
    }).then(function (res) {
      _this.selectData.returnPeriod = res;
    }); //收款方式

    getEnumList({
      enumCd: ENUM.ENUM_CD_0204
    }).then(function (res) {
      _this.selectData.payMethod = res;
    }); //租赁赎回方式

    getEnumList({
      enumCd: ENUM.ENUM_CD_0249
    }).then(function (res) {
      _this.selectData.rentRansomType = res;
    }); //账户类型

    getEnumList({
      enumCd: ENUM.ENUM_CD_0207
    }).then(function (res) {
      _this.selectData.accountType = res;
    }); //产品.非私募，共享+

    listEnum({
      query: {
        enabled: '1'
      }
    }).then(function (res) {
      _this.selectData.productId = res.data;
    }); //续约方式

    getEnumList({
      enumCd: ENUM.ENUM_CD_0219
    }).then(function (res) {
      _this.selectData.renewMethod = res;
    }); //续约类型

    getEnumList({
      enumCd: ENUM.ENUM_CD_0220
    }).then(function (res) {
      _this.selectData.renewType = res;
    }); //积存方式

    getEnumList({
      enumCd: ENUM.ENUM_CD_0203
    }).then(function (res) {
      _this.selectData.saveMethod = res;
    }); //签约类型

    getEnumList({
      enumCd: ENUM.ENUM_CD_0110
    }).then(function (res) {
      _this.selectData.contractSignType = res;
    });
  },
  computed: {
    // 弹窗展示的标题
    title: function title() {
      return this.isEdit ? '修改' : '新增';
    },
    // 是否为编辑
    isEdit: function isEdit() {
      return this.propsModel.contractCd != null;
    },
    //判断表单内容是否变化
    isFormChange: function isFormChange() {
      var strTempForm = JSON.stringify(this.tempFormModel);
      var strForm = JSON.stringify(this.formModel);
      return strTempForm != strForm;
    }
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();

          if (this.isEdit) {
            //编辑，查询变更申请的明细
            this.getInfo();
          } else {
            this.getBuyInfo(); //新增，查询合同的明细
          }
        }
      },
      immediate: true
    },
    isFormChange: {
      handler: function handler(val) {}
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tabActive = 'tabContract'; //默认打开第一个标签页

      this.bankCardList = [];
      this.showBankCardButton = true; //是否显示校验银行卡按钮

      this.bankCardButtonDisabled = false; //银行卡校验按钮是否可用
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.showDialog = false;
      this.$refs.form.resetFields();
      this.$emit('refresh');
    },
    cancel: function cancel() {
      //对话框取消按钮
      this.dialogClose();
    },
    onAttachmentOpen: function onAttachmentOpen() {
      //初始化标签
      this.tabActive = 'tabContract';
      this.attachmentOpened = 'dialog-position';
    },
    // TODO 获取详情
    getInfo: function getInfo() {
      var _this2 = this;

      var changeApplyId = this.propsModel.changeApplyId;
      var accountNo = this.propsModel.accountNo;
      var identification = this.propsModel.identification;
      var param1 = {
        changeApplyId: changeApplyId
      };
      var param2 = {
        page: {
          pageSize: 999,
          pageNumber: 1
        },
        query: {
          identification: identification
        }
      };
      var promiseArr = [];
      promiseArr[0] = _getInfo(param1, DATA_FORMAT_TYPE.NO); //客户银行卡列表
      //promiseArr[1] = listCustomerBankCard(param2);//客户银行卡列表

      Promise.all(promiseArr).then(function (res) {
        //this.loading = false;
        var _res$0$data = res[0].data,
            data = _res$0$data === void 0 ? {} : _res$0$data;
        var contact = data.contact;
        _this2.isAttachmentCanEdit = true; //赎回实物联系方式

        if (contact != null) {
          data.contactName = contact.name;
          data.contactMobile = contact.mobile;
          data.contactAddress = contact.address;
        }

        _this2.model = data; //是否有浮动收益率

        if (_this2.model.floatAnnualRateFrom > 0 || _this2.model.floatAnnualRateTo > 0) {
          _this2.isHaveFloatRate = true;
        } //初始克重


        if (_this2.isHaveGoldWeight) {
          _this2.changeGoldWeight();
        }

        _this2.formModel = Object.assign({}, _this2.model); //克隆新对象

        _this2.tempFormModel = Object.assign({}, _this2.formModel); //临时表单值，用于比较表单是否变化
        //客户银行卡信息

        _this2.bankCardList = res[2].data; //this.isShowBankCard3cButton();//检查银行卡校验按钮是否显示
      }).catch(function (e) {
        _this2.loading = false;
      });
    },
    // TODO 获取详情
    getBuyInfo: function getBuyInfo() {
      var _this3 = this;

      var contractId = this.propsModel.contractId;

      _getBuyInfo({
        contractId: contractId
      }, DATA_FORMAT_TYPE.NO).then(function (res) {
        var _res$data = res.data,
            data = _res$data === void 0 ? {} : _res$data;
        var contact = data.contact; //赎回实物联系方式

        if (contact != null) {
          data.contactName = contact.name;
          data.contactMobile = contact.mobile;
          data.contactAddress = contact.address;
        }

        _this3.model = data;
        _this3.formModel = Object.assign({}, _this3.model); //复制新对象
        //是否有浮动收益率

        if (_this3.model.productId == PRODUCT.PRODUCT_1021) {
          _this3.isHaveFloatRate = true;
        } //初始克重


        if (_this3.isHaveGoldWeight) {
          _this3.changeGoldWeight();
        }
      }).catch(function (e) {
        _this3.loading = false;
      });
    },
    // TODO 确定
    clickSave: function clickSave() {
      var _this4 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var param = {
            object: {},
            contact: {
              name: _this4.formModel.contactName,
              mobile: _this4.formModel.contactMobile,
              address: _this4.formModel.contactAddress
            }
          };
          param.object = _objectSpread({}, _this4.formModel);
          _this4.loading = true;
          save(param).then(function (res) {
            _this4.loading = false;
            _this4.isAttachmentCanEdit = true;
            _this4.formModel.changeApplyId = res.data;

            _this4.$set(_this4.propsModel, 'contractId', _this4.formModel.contractId);

            _this4.$set(_this4.propsModel, 'changeApplyId', _this4.formModel.changeApplyId);

            _this4.$message({
              message: '信息已暂存',
              type: 'success'
            });

            _this4.tempFormModel = Object.assign({}, _this4.formModel); //临时表单值，用于比较表单是否变化
          }).catch(function (e) {
            _this4.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    clickSubmit: function clickSubmit(done) {
      var _this5 = this;

      var attachmentCount = this.$refs.componentAttachment.tableData.length; //附件数

      if (!this.propsModel.changeApplyId || attachmentCount == 0) {
        this.$message('请完善变更信息及申请附件');
      } else {
        if (this.isFormChange) {
          //表单有变化，没有暂存就提交
          var param = {
            object: {},
            contact: {
              name: this.formModel.contactName,
              mobile: this.formModel.contactMobile,
              address: this.formModel.contactAddress
            }
          };
          param.object = _objectSpread({}, this.formModel);
          this.loading = true;
          save(param).then(function (res) {
            submit({
              changeApplyId: _this5.formModel.changeApplyId
            }).then(function (data) {
              _this5.loading = false;
              _this5.showDialog = false;

              _this5.$message({
                message: '已提交',
                type: 'success'
              });

              _this5.dialogClose();
            });
          });
        } else {
          //已暂存过，直接提交
          this.loading = true;
          submit({
            changeApplyId: this.formModel.changeApplyId
          }).then(function (data) {
            _this5.loading = false;
            _this5.showDialog = false;

            _this5.$message({
              message: '已提交',
              type: 'success'
            });

            _this5.dialogClose();
          });
        }
      }
    },
    popSelectUser: function popSelectUser(user) {
      console.log('user', user); //给查找经纪人子组件选中时调

      this.$set(this.formModel, 'userId', user.personuuid);
      this.$set(this.formModel, 'orgCd', user.orgCd);
      this.$set(this.formModel, 'orgName', user.orgName);
      this.$set(this.formModel, 'userName', user.name);
      this.showSearchUser = false;
    },
    changeEndDate: function changeEndDate(val) {
      //变更合同期限，开始日期，计算到期日期
      var endDate = computeContractEndDate(this.model.productId, this.formModel.timeLimit, this.formModel.startDate);

      if (endDate != null && endDate != '') {
        this.$set(this.formModel, 'endDate', endDate);
      }
    },
    changeEarnings: function changeEarnings(val) {
      var _this6 = this;

      //变更合同收益
      if (this.formModel.productId != null && this.formModel.amount > 0 && this.formModel.timeLimit != null && this.formModel.returnPeriod != null) {
        var param = {
          productId: this.model.productId,
          orgCd: this.formModel.orgCd,
          amount: this.formModel.amount,
          timeLimit: this.formModel.timeLimit,
          returnPeriod: this.formModel.returnPeriod,
          startDate: this.formModel.startDate,
          endDate: this.formModel.endDate
        };
        computeEarnings(param).then(function (res) {
          if (res.data.rate != null && res.data.rate != '') {
            if (res.data.lower > 0 || res.data.upper > 0) {
              _this6.isHaveFloatRate = true;
            } else {
              _this6.isHaveFloatRate = false;
            }

            _this6.$set(_this6.formModel, 'annualRate', res.data.rate);

            _this6.$set(_this6.formModel, 'floatAnnualRateFrom', res.data.lower);

            _this6.$set(_this6.formModel, 'floatAnnualRateTo', res.data.upper);
          } else {
            _this6.isHaveFloatRate = false;

            _this6.$set(_this6.formModel, 'annualRate', 0);

            _this6.$set(_this6.formModel, 'floatAnnualRateFrom', 0);

            _this6.$set(_this6.formModel, 'floatAnnualRateTo', 0);
          }

          if (res.data.earnings != null && res.data.earnings != '') {
            _this6.formModel.expectEarnings = res.data.earnings;
            _this6.formModel.expectCache = Math.round((parseFloat(_this6.formModel.amount) + parseFloat(_this6.formModel.expectEarnings)) * 100) / 100; //预期现金流
          }
        });
      }
    },
    changeGoldWeight: function changeGoldWeight(val) {
      //变更黄金克重
      if (this.formModel.amount > 0 && this.formModel.contractGoldPrice > 0) {
        var goldWeight = Math.round(this.formModel.amount / this.formModel.contractGoldPrice);
        this.$set(this.formModel, 'goldWeight', goldWeight);
      }
    },
    //变更合同金额
    changeAmount: function changeAmount() {
      this.formModel.amount = Math.round((parseFloat(this.formModel.renewAmount) + parseFloat(this.formModel.appendAmount)) * 100) / 100;
      this.changeEarnings(); //变更收益

      this.changeGoldWeight(); //变更克重
    },
    //查询银行卡号
    queryBankCard: function queryBankCard(queryString, cb) {
      var array = _toConsumableArray(this.bankCardList);

      for (var i = 0; i < array.length; i++) {
        var n = array[i];
        n.value = n.accountNo; //value属性必填
      }

      cb(array);
    },
    //选择银行卡选项
    selectBankCard: function selectBankCard(item) {
      var bankFullName = item.bankName == null ? '' : item.bankName;
      bankFullName += item.branchName == null ? '' : item.branchName;
      this.$set(this.formModel, 'accountName', item.accountName);
      this.$set(this.formModel, 'accountNo', item.accountNo);
      this.$set(this.formModel, 'bankName', bankFullName);
    },
    //验证银行卡3要素（卡号，姓名，身份证）

    /*
    checkBankCard3c() { 
      if(this.formModel.contractId==null) {
        this.$message("请先保存合同");  
      } else {
        this.loading = true;
        let param = {
          certifyDataId: this.formModel.contractId,
          accountNo: this.formModel.accountNo,
          accountName: this.formModel.accountName,
          identification: this.formModel.identification,
        };
        checkBankCard3c(param).then((res) => {
          this.loading = false;
          let bankCard = res.data;    
          this.bankCardList[0] = bankCard;    
          if(bankCard.isCertify==1) {//验证一致         
            this.$message({ message: "银行账号校验通过", type: "success" });          
          } else if(bankCard.isCertify==2) {//验证不一致
            this.$message({ message: "姓名、证件号、银行账号不一致，请检查更正", type: "warning" });
          } else if(bankCard.isCertify==3) {//银行卡已注销
            this.$message({ message: bankCard.certifyDesc, type: "error" });
          }
          this.isShowBankCard3cButton();//设置按钮显示样式              
        });           
      }
    },
    */
    //是否显示校验银行卡信息按钮

    /*
    isShowBankCard3cButton() {
      let isShow = true;
      if(this.formModel.idType!="01") {//非身份证，无法校验
        isShow = false;
      } else { 
        if(this.bankCardList!=null) {//客户银行卡列表
          let bankCard = this.bankCardList.find((n) => n.certifyDataId==this.formModel.contractId);         
          if(bankCard==null) {//如果合同没有查到，再检查银行号
            bankCard = this.bankCardList.find((n) => n.accountNo==this.formModel.accountNo);
          }
          if(bankCard!=null && bankCard.isCertify==YES_NO.YES.value) {//校验已通过
            if(this.formModel.identification==bankCard.identification && this.formModel.accountName==bankCard.accountName && 
              this.formModel.accountNo==bankCard.accountNo) {//校验的信息和本次填的内容一致
              isShow = false;
            }
          }
          if(bankCard!=null && bankCard.certifyTimes>=3) {//校验超过3次，校验按钮不可用
            this.bankCardButtonDisabled = true;
          }          
        }
      }
      this.showBankCardButton = isShow;
      return isShow;
    }, 
    */
    showLog: function showLog() {
      //显示审核日志
      this.showLogDialog = true;
    }
  }
};