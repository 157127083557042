var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "dialog-search-contract",
      attrs: {
        top: "2vh",
        width: "910px",
        title: "推介费审核",
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      [
        _c(
          "el-tabs",
          {
            model: {
              value: _vm.tabActive,
              callback: function($$v) {
                _vm.tabActive = $$v
              },
              expression: "tabActive"
            }
          },
          [
            _c(
              "el-tab-pane",
              { attrs: { label: "计划详情", name: "tabAllocation" } },
              [
                _c(
                  "el-collapse",
                  {
                    model: {
                      value: _vm.collapseActive,
                      callback: function($$v) {
                        _vm.collapseActive = $$v
                      },
                      expression: "collapseActive"
                    }
                  },
                  [
                    _c(
                      "el-collapse-item",
                      { attrs: { title: "合同信息", name: "1" } },
                      [
                        _c(
                          "el-row",
                          { staticClass: "block-col-4" },
                          [
                            _c("el-col", { attrs: { span: 5 } }, [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v(_vm._s(_vm.labelContractCd) + ":")
                              ]),
                              _vm._v(" " + _vm._s(_vm.model.contractCd) + " ")
                            ]),
                            _c("el-col", { attrs: { span: 5 } }, [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v("合同状态:")
                              ]),
                              _vm._v(
                                " " + _vm._s(_vm.model.contractStateText) + " "
                              )
                            ]),
                            _c("el-col", { attrs: { span: 5 } }, [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v("开始日期:")
                              ]),
                              _vm._v(" " + _vm._s(_vm.model.startDate) + " ")
                            ]),
                            _c("el-col", { attrs: { span: 5 } }, [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v("到期日期:")
                              ]),
                              _vm._v(" " + _vm._s(_vm.model.endDate) + " ")
                            ])
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { staticClass: "block-col-2" },
                          [
                            _c("el-col", { attrs: { span: 5 } }, [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v("客户名称:")
                              ]),
                              _vm._v(" " + _vm._s(_vm.model.customerName) + " ")
                            ]),
                            _c("el-col", { attrs: { span: 15 } }, [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v("机构:")
                              ]),
                              _vm._v(" " + _vm._s(_vm.model.orgName) + " ")
                            ])
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { staticClass: "block-col-4" },
                          [
                            _c("el-col", { attrs: { span: 5 } }, [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v("产品:")
                              ]),
                              _vm._v(" " + _vm._s(_vm.model.productName) + " ")
                            ]),
                            _c("el-col", { attrs: { span: 5 } }, [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v("合同金额:")
                              ]),
                              _vm._v(" " + _vm._s(_vm.model.amount) + " ")
                            ]),
                            _c("el-col", { attrs: { span: 5 } }, [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v("期限:")
                              ]),
                              _vm._v(" " + _vm._s(_vm.model.timeLimit) + " ")
                            ]),
                            _c("el-col", { attrs: { span: 5 } }, [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v("返还周期:")
                              ]),
                              _vm._v(" " + _vm._s(_vm.model.returnPeriod) + " ")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("el-divider"),
                    _c(
                      "el-collapse-item",
                      { attrs: { title: "推介费信息", name: "2" } },
                      [
                        _c(
                          "el-row",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: "commissionV3-proportionCheck",
                                expression: "'commissionV3-proportionCheck'"
                              }
                            ],
                            staticClass: "block-col-4"
                          },
                          [
                            _c("el-col", { attrs: { span: 5 } }, [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v("规则编码:")
                              ]),
                              _vm._v(" " + _vm._s(_vm.model.ruleCode) + " ")
                            ]),
                            _c("el-col", { attrs: { span: 15 } }, [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v("规则名称:")
                              ]),
                              _vm._v(" " + _vm._s(_vm.model.ruleName) + " ")
                            ])
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { staticClass: "block-col-4" },
                          [
                            _c("el-col", { attrs: { span: 5 } }, [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v("推介费状态:")
                              ]),
                              _vm._v(" " + _vm._s(_vm.model.statusText) + " ")
                            ]),
                            _c("el-col", { attrs: { span: 5 } }, [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v("总次数:")
                              ]),
                              _vm._v(" " + _vm._s(_vm.model.totalTimes) + " ")
                            ]),
                            _c("el-col", { attrs: { span: 5 } }, [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v("第几次:")
                              ]),
                              _vm._v(" " + _vm._s(_vm.model.currentTimes) + " ")
                            ]),
                            _c("el-col", { attrs: { span: 5 } }, [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v("是否后勤人员:")
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.model.isBackOffice === "1" ? "是" : "否"
                                  ) +
                                  " "
                              )
                            ])
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { staticClass: "block-col-4" },
                          [
                            _c("el-col", { attrs: { span: 5 } }, [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v("推介费类型:")
                              ]),
                              _vm._v(" " + _vm._s(_vm.model.typeText) + " ")
                            ]),
                            _c("el-col", { attrs: { span: 5 } }, [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v("推介费结算月份:")
                              ]),
                              _vm._v(" " + _vm._s(_vm.model.planDate) + " ")
                            ]),
                            _c("el-col", { attrs: { span: 5 } }, [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v("运营年度:")
                              ]),
                              _vm._v(" " + _vm._s(_vm.model.fiscalYear) + " ")
                            ])
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { staticClass: "block-col-4" },
                          [
                            _c("el-col", { attrs: { span: 18 } }, [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v("备注:")
                              ]),
                              _vm._v(" " + _vm._s(_vm.model.remark) + " ")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    attrs: {
                      data: _vm.formModel.tableData,
                      border: "",
                      "row-style": { height: "20px" },
                      "cell-style": { padding: "6px 0" },
                      hight: "200"
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "职级", prop: "title" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.StatusText(scope.row.titleId)) +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { label: "比例", prop: "percent", align: "right" }
                    }),
                    _c("el-table-column", {
                      attrs: { label: "金额", prop: "bonus", align: "right" }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "人员",
                        prop: "bonusUserName",
                        align: "right"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "无人岗位",
                        prop: "isNoUser",
                        align: "right"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.isNoUser === "1" ? "是" : "否"
                                  ) +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm.model.contractId
              ? _c(
                  "el-tab-pane",
                  { attrs: { label: "附件", name: "tabAttachment" } },
                  [
                    _c("attachment", {
                      ref: "componentAttachment",
                      attrs: {
                        "is-attachment-edit": false,
                        "attachment-contract-id": _vm.model.contractId,
                        "attachment-type": _vm.attachmentType
                      },
                      on: { onAttachmentOpen: _vm.onAttachmentOpen }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        ),
        _c("el-divider", { attrs: { "content-position": "left" } }, [
          _vm._v("审核")
        ]),
        _c(
          "el-form",
          {
            ref: "form",
            attrs: {
              model: _vm.formModel,
              rules: _vm.rules,
              "label-width": "100px",
              size: "mini"
            }
          },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "审核意见", prop: "advice" } },
                      [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formModel.advice,
                              expression: "formModel.advice"
                            }
                          ],
                          staticClass: "el-textarea__inner",
                          attrs: { placeholder: "请填写审核意见" },
                          domProps: { value: _vm.formModel.advice },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formModel,
                                "advice",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              staticClass: "contract-log-tip",
              attrs: { plain: "", size: "mini", icon: "el-icon-finished" },
              on: {
                click: function($event) {
                  return _vm.showLog()
                }
              }
            },
            [_vm._v("审核日志")]
          ),
          _c(
            "el-button",
            { attrs: { plain: "", size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                plain: "",
                size: "mini",
                loading: _vm.loading
              },
              on: {
                click: function($event) {
                  return _vm.clickAuditPlan()
                }
              }
            },
            [_vm._v("退回到待计划")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "warning",
                plain: "",
                size: "mini",
                loading: _vm.loading
              },
              on: {
                click: function($event) {
                  return _vm.clickAuditFail()
                }
              }
            },
            [_vm._v("审核不通过")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                plain: "",
                size: "mini",
                loading: _vm.loading
              },
              on: {
                click: function($event) {
                  return _vm.clickAudit()
                }
              }
            },
            [_vm._v("同 意")]
          )
        ],
        1
      ),
      _vm.propsModel.contractId != ""
        ? _c("contract-log", {
            attrs: {
              "contract-id": _vm.propsModel.contractId,
              extId: _vm.propsModel.applicationId,
              active: _vm.contractLogType
            },
            model: {
              value: _vm.showLogDialog,
              callback: function($$v) {
                _vm.showLogDialog = $$v
              },
              expression: "showLogDialog"
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }