import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
//CRM 相关计算
import PRODUCT_CATEGORY2 from '@/maps/enum/product-category2';
import { formatDate, formatAmountToNumber } from "./format"; //常量
//到期日有延期的产品

var productEndDateDelay = {
  '4001': -1,
  '4002': -1 //'4003': -1,

};
/**
 * 计算合同的到期日期。
 * 结束日期 = 合同开始日期 + 合同期限。金享未来产品 -1 天
 * @param {*} produtId 产品
 * @param {*} startDate 合同开始日期
 * @param {*} timeLimit 合同期限
 */

export function computeContractEndDate(product, timeLimit, startDate) {
  var endDate = null;

  if (product != null && startDate != null && startDate != '' && timeLimit != null && timeLimit != '') {
    if (product.extList != null && product.extList.length > 0) {
      //有扩展信息，固定到期日
      if (timeLimit.indexOf("+") > 0) {
        //如果期限有+， 取第一个字符串  24+1 取24  
        timeLimit = timeLimit.substring(0, timeLimit.indexOf("+"));
      }

      for (var i = 0; i < product.extList.length; i++) {
        var n = product.extList[i];

        if (startDate >= n.dateBegin && startDate <= n.dateEnd) {
          var d = JSON.parse(n.contractEndDate);
          endDate = d[timeLimit];
          break;
        }
      }
    } else {
      //按开始+月数
      var totalTimeLimit = 0; //总月数

      if (timeLimit.indexOf("+") > -1) {
        var arr = timeLimit.split("+");
        totalTimeLimit = parseInt(arr[0]) + parseInt(arr[1]);
      } else {
        totalTimeLimit = parseInt(timeLimit);
      }

      var dateStartContract = new Date(startDate); //合同开始日期

      var date = new Date(startDate);
      date.setMonth(dateStartContract.getMonth() + totalTimeLimit);

      if (productEndDateDelay[product.productId] != null) {
        //如果产品有特殊顺延
        dateStartContract.setDate(dateStartContract.getDate() + productEndDateDelay[product.productId]);
      } //修正月底日期错误。例如2022-08-30, + 6个月后 = 2023-03-02。错误，不能过月，只能提前，所以合同到期日应为2023-02-28


      var startDateMonth = dateStartContract.getMonth(); //开始日期的月份

      var endDateMonth = date.getMonth(); //结束日期月份

      if (endDateMonth < startDateMonth) {
        endDateMonth += 12;
      }

      var diff = endDateMonth - startDateMonth; //相差月份

      if (diff > totalTimeLimit) {
        //月底日期+月份后过月
        date.setDate(0); //表示1日的前一日
      }

      endDate = formatDate(date, 'yyyy-MM-dd');
    }
  }

  return endDate;
}
/**
 * 根据证件号计算客户生日
 * @param {*} idType 证件类型
 * @param {*} identification 证件号码
 */

export function computeCustomerBirthday(identification) {
  var birthday = null;

  if (identification != null && identification.length == 18) {
    birthday = identification.substr(6, 4) + '-' + identification.substr(10, 2) + '-' + identification.substr(12, 2);
  }

  return birthday;
}
/**
 * 根据身份证号计算客户性别。1男0女
 * @param {*} identification 
 */

export function computeCustomerGender(identification) {
  var gender = null;

  if (identification != null && identification.length == 18) {
    gender = identification.charAt(16) % 2;
  }

  return gender;
}
/**
 * 计算预期现金流（本金+收益）
 * @param {*} amount 本金
 * @param {*} expectEarnings 收益
 */

export function computeExpectCache(amount, expectEarnings) {
  var expectCache = 0;
  var numAmount = amount == null ? 0 : amount;
  var numExpectEarnings = expectEarnings == null ? 0 : expectEarnings;

  if (typeof amount == "string") {
    numAmount = formatAmountToNumber(amount);
  }

  if (typeof expectEarnings == "string") {
    numExpectEarnings = formatAmountToNumber(expectEarnings);
  }

  expectCache = Math.round((numAmount + numExpectEarnings) * 100) / 100; //四舍五入

  return expectCache;
}
/**
 * 设置额外属性
 *
 * @export
 * @param {*} obj
 */

export function setExtendAttribute(obj) {
  //设置预期收益展示字段和值
  if (obj.hasOwnProperty("expectEarnings") && obj.expectEarnings > 0) {
    obj.extExpectEarnings = obj.expectEarnings; //预期收益

    obj.extLabelExpectEarnings = "预期收益";
    obj.extActualEarnings = obj.actualEarnings; //实际收益

    obj.extLabelActualEarnings = "实际收益";

    if (obj.hasOwnProperty("productCategory2")) {
      if (obj.productCategory2 == PRODUCT_CATEGORY2.HJCZ) {
        obj.extLabelExpectEarnings = "租金回报";
        obj.extLabelActualEarnings = "租金回报";
      } else if (obj.productCategory2 == PRODUCT_CATEGORY2.HJWT || obj.productCategory2 == PRODUCT_CATEGORY2.HJXLS) {
        if (obj.hasOwnProperty("rentRansomType")) {
          if (obj.rentRansomType == "2") {
            obj.extLabelExpectEarnings = "现金价值与预期收益";
            obj.extExpectEarnings = Math.round((parseFloat(obj.amount) + parseFloat(obj.expectEarnings)) * 100) / 100;

            if (obj.actualEarnings != null) {
              obj.extLabelActualEarnings = "现金价值与实际收益";
              obj.extActualEarnings = Math.round((parseFloat(obj.amount) + parseFloat(obj.actualEarnings)) * 100) / 100;
            }
          } else if (obj.rentRansomType == "1") {
            obj.extLabelExpectEarnings = "预期收益";
            obj.extLabelActualEarnings = "实际收益";
          }
        }
      } else {
        obj.extLabelExpectEarnings = "预期现金流";
        obj.extExpectEarnings = Math.round((parseFloat(obj.amount) + parseFloat(obj.expectEarnings)) * 100) / 100;

        if (obj.actualEarnings != null) {
          obj.extLabelActualEarnings = "实际现金流";
          obj.extActualEarnings = Math.round((parseFloat(obj.amount) + parseFloat(obj.actualEarnings)) * 100) / 100;
        }
      }
    }
  }
}