//合同购买预约
export default {
  SAVE: {
    value: '1',
    text: '待提交'
  },
  SUBMIT: {
    value: '2',
    text: '待客服审核'
  },
  AUDIT: {
    value: '3',
    text: '待结算审核'
  },
  COUNT_AUDIT: {
    value: '4',
    text: '待资金确认'
  },
  DEAL: {
    value: '5',
    text: '已成交'
  },
  SUSPEND: {
    value: '6',
    text: '提前终止'
  },
  PAYED: {
    value: '7',
    text: '已清算'
  },
  AUDIT_FAIL: {
    value: '8',
    text: '客服审核不通过'
  },
  DEAL_FAIL: {
    value: '9',
    text: '资金确认不通过'
  },
  WAIT_FOR_FINANCE: {
    value: '10',
    text: '待财务审核'
  },
  FINANCE_AUDIT_FAIL: {
    value: '11',
    text: '财务确认不通过'
  },
  FINANCE_AUDIT: {
    value: '12',
    text: '财务确认通过'
  },
  COUNT_AUDIT_FAIL: {
    value: '13',
    text: '结算确认不通过'
  },
  RESERVE_BUY: {
    value: '14',
    text: '待预约审核'
  },
  RESERVE_BUY_FAIL: {
    value: '15',
    text: '待预约审核不通过'
  },
  CLEAR_SUSPEND_SERVICE: {
    value: '17',
    text: '提前终止待结算审核'
  },
  CLEAR_SUSPEND_COUNT_FAIL: {
    value: '18',
    text: '提前终止结算审核不通过'
  },
  CLEAR_SUSPEND_COUNT: {
    value: '19',
    text: '提前终止待财务审核'
  },
  CLEAR_SUSPEND_FINANCE_FAIL: {
    value: '20',
    text: '提前终止财务审核不通过'
  },
  CLEAR_PAYED_SERVICE: {
    value: '21',
    text: '到期清算待结算审核'
  },
  CLEAR_PAYED_COUNT_FAIL: {
    value: '22',
    text: '到期清算结算审核不通过'
  },
  CLEAR_PAYED_COUNT: {
    value: '23',
    text: '到期清算待财务审核'
  },
  CLEAR_PAYED_FINANCE_FAIL: {
    value: '24',
    text: '到期清算财务审核不通过'
  },
  DEAL_RETURN: {
    value: '30',
    text: '资金审核退回'
  },
  DELETE: {
    value: '-99',
    text: '已删除'
  }
};