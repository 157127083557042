// 客户银行卡信息
import request from '@/plugin/axios';
import func from '@/maps/url/crm/customer/bankCard'; // TODO 列表

export function listCustomerBankCard(params) {
  var re = request.post(func.listCustomerBankCard, {
    body: params
  });
  return re;
} // TODO 详情

export function getCustomerBankCard(params) {
  var re = request.post(func.getCustomerBankCard, {
    body: params
  });
  return re;
} // TODO 更新详情

export function updateCustomerBankCard(params) {
  var re = request.post(func.updateCustomerBankCard, {
    body: params
  });
  return re;
} // TODO 3要素验证

export function checkBankCard3c(params) {
  var re = request.post(func.checkBankCard3c, {
    body: params
  });
  return re;
} // TODO 获取银行卡验证信息

export function getCheckBankCardInfo(params) {
  var re = request.post(func.getCheckBankCardInfo, {
    body: params
  });
  return re;
}