//赎回
var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var baseRoute = 'crm';
export default [// 财务总监

/*
{
    path: `${baseRoute}/apply/financeManager`,
    name: 'apply-financeManager',
    meta: {
        title: '赎回>财务总监审核',
        auth: true,
        cache: true,
    },
    component: _import('crm/apply/financeManager'),
},*/
// 出金打印
{
  path: "".concat(baseRoute, "/apply/print"),
  name: 'apply-print',
  meta: {
    title: '赎回>出金打印',
    auth: true,
    cache: true
  },
  component: _import('crm/apply/print')
}, // 资金审核
{
  path: "".concat(baseRoute, "/apply/money"),
  name: 'apply-money',
  meta: {
    title: '赎回>资金审核',
    auth: true,
    cache: true
  },
  component: _import('crm/apply/money')
}, // 客服发货
{
  path: "".concat(baseRoute, "/apply/jewelry"),
  name: 'apply-jewelry',
  meta: {
    title: '赎回>客服发货',
    auth: true,
    cache: true
  },
  component: _import('crm/apply/jewelry')
}, // 赎回查询
{
  path: "".concat(baseRoute, "/apply/search"),
  name: 'apply-search',
  meta: {
    title: '赎回>查询',
    auth: true,
    cache: true
  },
  component: _import('crm/apply/search')
}, // 赎回帐单
{
  path: "".concat(baseRoute, "/apply/bill"),
  name: 'apply-bill',
  meta: {
    title: '赎回>帐单',
    auth: true,
    cache: true
  },
  component: _import('crm/apply/bill')
}];