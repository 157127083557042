import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import BANK from "@/maps/enum/bank";
import DATA_FORMAT_TYPE from "@/maps/enum/data-format-type";
import { FILTER_PROPS, FILTER_FORMS } from "./option/filter";
import TABLE_LIST from "./option/list";
import DynamicForm from "@/components/common/dynamic-form";
import DynamicTable from "@/components/common/dynamic-table";
import DialogImport from "./components/dialog-import";
import DialogAdd from "./components/dialog-add";
import DialogInfo from "../dialog-info";
import { list, remove, exportExcel } from "@api/crm/bank/bill";
export default {
  name: "bank-bill-zx",
  components: {
    DynamicForm: DynamicForm,
    DynamicTable: DynamicTable,
    DialogImport: DialogImport,
    DialogAdd: DialogAdd,
    DialogInfo: DialogInfo
  },
  data: function data() {
    return {
      loading: false,
      filterProps: FILTER_PROPS,
      filterForms: FILTER_FORMS,
      headers: TABLE_LIST,
      table: {
        multiple: true
      },
      pagination: {},
      query: {
        kind: BANK.ZX
      },
      tableData: [],
      showImportDialog: false,
      showAddDialog: false,
      showInfoDialog: false,
      currentRow: {},
      checkboxRows: []
    };
  },
  computed: {},
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
      this.$refs.jTable.pageChange(1);
    },
    //初始化搜索条件
    initFilter: function initFilter() {
      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);
    },
    //点击刷新
    clickRefresh: function clickRefresh() {
      this.$refs.jTable.onRefresh();
      this.$message({
        message: "数据已刷新",
        type: "info",
        duration: 500
      });
    },
    //点击重置
    clickReset: function clickReset() {
      this.$refs.form.reset();
    },
    // TODO 搜索
    clickSearch: function clickSearch() {
      this.initFilter();
      this.$refs.jTable.pageChange(1);
    },
    // TODO 获取列表
    loadData: function loadData(_ref) {
      var _this = this;

      var _ref$page = _ref.page,
          page = _ref$page === void 0 ? {} : _ref$page;
      this.initFilter();
      var param = {
        page: page,
        query: this.query
      };
      this.loading = true;
      list(param, DATA_FORMAT_TYPE.USRE_SETUP).then(function (res) {
        _this.loading = false;
        _this.tableData = [];

        if (res.data != null) {
          var _res$data = res.data,
              data = _res$data === void 0 ? [] : _res$data,
              _page = res.page;
          var _page$totalCount = _page.totalCount,
              totalCount = _page$totalCount === void 0 ? 0 : _page$totalCount;
          _this.pagination = {
            total: totalCount
          };
          _this.tableData = data;
        } else {
          _this.$message({
            message: "暂无数据",
            type: "info"
          });
        }
      }).catch(function (e) {
        _this.loading = false;

        _this.$message({
          message: "查询列表数据出错",
          type: "error"
        });
      });
    },
    // TODO 选中行
    selectRow: function selectRow(row) {
      this.currentRow = row;
    },
    //复选框选中
    handleSelectionChange: function handleSelectionChange(rows) {
      this.checkboxRows = rows;
    },
    // TODO 点击详情
    clickInfo: function clickInfo() {
      var row = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.currentRow = row;
      this.showInfoDialog = true;
    },
    //批量导入
    clickImport: function clickImport() {
      this.showImportDialog = true;
    },
    clickAdd: function clickAdd() {
      this.showAddDialog = true;
    },
    //删除礼品
    clickRemove: function clickRemove() {
      var _this2 = this;

      if (this.checkboxRows.length === 0) {
        this.$message("请选择流水");
      } else {
        this.$confirm("确定删除选中流水吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          var arrayBankId = [];

          _this2.checkboxRows.forEach(function (n) {
            arrayBankId.push(n.bankId);
          });

          var param = {
            arrayBankId: arrayBankId
          };
          _this2.loading = true;
          remove(param).then(function (res) {
            _this2.loading = false;

            _this2.init();
          }).catch(function (e) {
            _this2.loading = false;
          });
        }).catch(function () {});
      }
    },
    clickExport: function clickExport() {
      var _this3 = this;

      this.$confirm("确定数据导出吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this3.initFilter();

        var param = {
          query: _this3.query
        };
        exportExcel(param, "云金雅福中信对帐流水.xlsx").catch(function (e) {
          _this3.$message({
            message: e,
            type: "error"
          });
        });
      }).catch(function () {});
    }
  },
  created: function created() {}
};