//银行流水
var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var baseRoute = 'crm';
export default [// 建行和平支行
{
  path: "".concat(baseRoute, "/bank/bill/js"),
  name: 'bank-bill-js',
  meta: {
    title: '流水>建设',
    auth: true,
    cache: true
  },
  component: _import('crm/bank/bill/js')
}, // 通联2
{
  path: "".concat(baseRoute, "/bank/bill/tl2"),
  name: 'bank-bill-tl2',
  meta: {
    title: '流水>通联2',
    auth: true,
    cache: true
  },
  component: _import('crm/bank/bill/tl2')
}, // 香梅支行(工行)
{
  path: "".concat(baseRoute, "/bank/bill/gsxm"),
  name: 'bank-bill-gsxm',
  meta: {
    title: '流水>工商香梅',
    auth: true,
    cache: true
  },
  component: _import('crm/bank/bill/gsxm')
}, // 通联支付2015
{
  path: "".concat(baseRoute, "/bank/bill/tl2015"),
  name: 'bank-bill-tl2015',
  meta: {
    title: '流水>通联2015',
    auth: true,
    cache: true
  },
  component: _import('crm/bank/bill/tl2015')
}, // 云金雅福银行流水(工行)
{
  path: "".concat(baseRoute, "/bank/bill/gsjyf"),
  name: 'bank-bill-gsjyf',
  meta: {
    title: '流水>工商',
    auth: true,
    cache: true
  },
  component: _import('crm/bank/bill/gsjyf')
}, //云金雅福流水 
{
  path: "".concat(baseRoute, "/bank/bill/jyfpos"),
  name: 'bank-bill-jyfpos',
  meta: {
    title: '流水>金雅福POS',
    auth: true,
    cache: true
  },
  component: _import('crm/bank/bill/jyfpos')
}, // 云金雅福平安银行流水
{
  path: "".concat(baseRoute, "/bank/bill/pa"),
  name: 'bank-bill-pa',
  meta: {
    title: '流水>平安',
    auth: true,
    cache: true
  },
  component: _import('crm/bank/bill/pa')
}, // 云金雅福中信银行流水
{
  path: "".concat(baseRoute, "/bank/bill/zx"),
  name: 'bank-bill-zx',
  meta: {
    title: '流水>中信',
    auth: true,
    cache: true
  },
  component: _import('crm/bank/bill/zx')
}, // 金雅福首饰民生银行
{
  path: "".concat(baseRoute, "/bank/bill/ms"),
  name: 'bank-bill-ms',
  meta: {
    title: '流水>民生',
    auth: true,
    cache: true
  },
  component: _import('crm/bank/bill/ms')
}, // 金雅福首饰工商银行
{
  path: "".concat(baseRoute, "/bank/bill/gs"),
  name: 'bank-bill-gs',
  meta: {
    title: '流水>工商',
    auth: true,
    cache: true
  },
  component: _import('crm/bank/bill/gs')
}, // 中山金雅福易宝网络科技有限公司(工商)
{
  path: "".concat(baseRoute, "/bank/bill/zsyb"),
  name: 'bank-bill-gs-zsyb',
  meta: {
    title: '流水>中山易宝',
    auth: true,
    cache: true
  },
  component: _import('crm/bank/bill/zsyb')
}, // 建设银行宝城支行
{
  path: "".concat(baseRoute, "/bank/bill/jsbc"),
  name: 'bank-bill-jsbc',
  meta: {
    title: '流水>建行宝城支行',
    auth: true,
    cache: true
  },
  component: _import('crm/bank/bill/jsbc')
}, // 桂林银行深圳支行
{
  path: "".concat(baseRoute, "/bank/bill/glyh"),
  name: 'bank-bill-glyh',
  meta: {
    title: '流水>桂林银行深圳支行',
    auth: true,
    cache: true
  },
  component: _import('crm/bank/bill/glyh')
}, // 金雅福传承文化首饰民生
{
  path: "".concat(baseRoute, "/bank/bill/msjyfwh"),
  name: 'bank-bill-msjyfwh',
  meta: {
    title: '流水>金雅福传承文化首饰民生',
    auth: true,
    cache: true
  },
  component: _import('crm/bank/bill/msjyfwh')
}, // 金雅福传承文化首饰农行
{
  path: "".concat(baseRoute, "/bank/bill/nyjyfwh"),
  name: 'bank-bill-nyjyfwh',
  meta: {
    title: '流水>金雅福传承文化首饰农行',
    auth: true,
    cache: true
  },
  component: _import('crm/bank/bill/nyjyfwh')
}, // 金雅福首饰农业银行
{
  path: "".concat(baseRoute, "/bank/bill/nysszz"),
  name: 'bank-bill-ny-sszz',
  meta: {
    title: '流水>首饰制造农行',
    auth: true,
    cache: true
  },
  component: _import('crm/bank/bill/nysszz')
}];