//列表项
var VAL_WIDTH = '100px'; // 表头

export default [{
  label: '编号',
  prop: 'enumCd',
  width: VAL_WIDTH
}, {
  label: '名称',
  prop: 'enumName',
  width: '300px'
}];