var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var baseRoute = 'crm';
export default [// 产品
{
  path: "".concat(baseRoute, "/setup/product"),
  name: 'product',
  meta: {
    title: '产品',
    auth: true,
    cache: false
  },
  component: _import('crm/setup/product/product')
}, // 新增产品
{
  path: "".concat(baseRoute, "/setup/product/add"),
  name: 'productAdd',
  meta: {
    title: '新增/编辑',
    auth: true,
    cache: false
  },
  component: _import('crm/setup/product/product/add')
}, // 产品收益率
{
  path: "".concat(baseRoute, "/setup/product/rate"),
  name: 'productRate',
  meta: {
    title: '产品收益率',
    auth: true,
    cache: true
  },
  component: _import('crm/setup/product/rate')
}, // 金价管理
{
  path: "".concat(baseRoute, "/setup/goldPrice"),
  name: 'goldPrice',
  meta: {
    title: '金价',
    auth: true,
    cache: true
  },
  component: _import('crm/setup/goldPrice')
}, // 金价查询
{
  path: "".concat(baseRoute, "/setup/goldPrice/search"),
  name: 'goldPriceSearch',
  meta: {
    title: '金价查询',
    auth: true,
    cache: true
  },
  component: _import('crm/setup/goldPriceSearch')
}, // 浮动收益
{
  path: "".concat(baseRoute, "/setup/monthFloatRate"),
  name: 'monthFloatRate',
  meta: {
    title: '浮动收益',
    auth: true,
    cache: true
  },
  component: _import('crm/setup/monthFloatRate')
}, // 浮动收益查询
{
  path: "".concat(baseRoute, "/setup/monthFloatRate/search"),
  name: 'monthFloatRateSearch',
  meta: {
    title: '浮动收益查询',
    auth: true,
    cache: true
  },
  component: _import('crm/setup/monthFloatRateSearch')
}, // 文档模板管理
{
  path: "".concat(baseRoute, "/setup/document/template"),
  name: 'documentTemplate',
  meta: {
    title: '文档模板管理',
    auth: true,
    cache: true
  },
  component: _import('crm/setup/documentTemplate')
}, // 文档模板查询
{
  path: "".concat(baseRoute, "/setup/document/template/search"),
  name: 'documentTemplateSearch',
  meta: {
    title: '文档模板查询',
    auth: true,
    cache: true
  },
  component: _import('crm/setup/documentTemplateSearch')
}, // 参数设置
{
  path: "".concat(baseRoute, "/setup/parameter"),
  name: 'parameter',
  meta: {
    title: '参数设置',
    auth: true,
    cache: true
  },
  component: _import('crm/setup/parameter')
}];