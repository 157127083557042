


























































































































































































































































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/renew/dialog-info.vue"
}
