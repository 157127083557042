import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.number.constructor.js";
import useImageParse from '@/hooks/use-image-parse';
var PUBLIC_CONFIG = {
  labelWidth: 80
};
export var TOP_FORM = {
  id: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '流水号',
    props: {
      placeholder: '请输入流水号'
    }
  }),
  // orgCd: {
  //   ...PUBLIC_CONFIG,
  //   label: '主办方',
  //   type: 'cascader',
  //   options: [],
  //   props: {
  //     placeholder: '请选择主办方',
  //     filterable: true,
  //     // showAllLevels: false,
  //     props: {
  //       checkStrictly: true,
  //       emitPath: false,
  //       value: 'orgCd',
  //       label: 'orgName',
  //       children: 'children',
  //     },
  //   },
  // },
  activityTitle: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '活动标题',
    props: {
      placeholder: '请输入活动标题'
    }
  }),
  activityType: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '活动类型',
    props: {
      placeholder: '请选择活动类型'
    },
    options: []
  }),
  classifyId: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '活动分类',
    props: {
      placeholder: '请选择活动分类'
    },
    options: []
  }),
  activityStatus: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '活动状态',
    props: {
      placeholder: '请选择活动状态'
    },
    options: [{
      label: '待审核',
      value: 0
    }, {
      label: '审核中',
      value: 1
    }, {
      label: '审核不通过',
      value: 2
    }, {
      label: '未开始',
      value: 3
    }, {
      label: '进行中',
      value: 4
    }, {
      label: '已结束',
      value: 5
    }]
  }),
  status: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '是否启用',
    props: {
      placeholder: '请选择是否启用'
    },
    options: [{
      label: '启用',
      value: 1
    }, {
      label: '禁用',
      value: 0
    }]
  })
};
export var TABLE_LABEL = [{
  label: '流水号',
  prop: 'id'
}, {
  label: '活动主图',
  prop: 'mainPicture',
  type: 'img',
  formatter: function formatter(row, column, cellValue) {
    return "".concat(process.env.VUE_APP_CLOUD_UPLOAD).concat(row.mainPicture);
  }
}, {
  label: '活动标题',
  prop: 'activityTitle'
}, {
  label: '活动类型',
  prop: 'activityTypeText'
}, {
  label: '活动分类',
  prop: 'classifyName'
}, {
  label: '活动开始时间',
  prop: 'startTime',
  width: 150
}, {
  label: '活动结束时间',
  prop: 'endTime',
  width: 150
}, // {
//   label: '主办方',
//   prop: 'orgCdText',
// },
{
  label: '活动状态',
  prop: 'activityStatusText'
}, {
  label: '审核状态',
  prop: 'auditStatus',
  type: 'slot',
  slotName: 'auditStatus',
  width: 150
}, {
  type: 'inputNumber',
  label: '排序值',
  prop: 'sort',
  width: 150,
  sortable: 'manageClassify',
  sortProp: 'sort'
}, {
  label: '是否启用',
  prop: 'status',
  formatter: function formatter(row, column, cellValue) {
    var res = '';

    switch (Number(cellValue)) {
      case 0:
        res = '停用';
        break;

      case 1:
        res = '启用';
        break;

      default:
        res = '';
    }

    return res;
  }
}, {
  type: 'operation',
  label: '操作',
  width: 200,
  formatter: function formatter(row) {
    var arr = [{
      type: 'read',
      label: '查看',
      permission: 'activity-read'
    }, {
      type: 'edit',
      label: '修改' // permission: 'activity-edit'

    } // {
    //   type: 'sign',
    //   label: '签到码',
    // },
    // {
    //   type: 'eval',
    //   label: '评价码',
    // },
    ]; // if (row.isEdit || row.auditStatus !== 1) {
    //   arr.push({
    //     type: 'edit',
    //     label: '修改',
    //     permission: 'activity-edit'
    //   })
    // }

    if (row.activityType === '4') {
      arr.push({
        type: 'register',
        label: '报名信息登记表',
        permission: 'activity-register'
      });
    }

    if (row.status === 1) {
      arr.push({
        type: 'stop',
        label: '停用',
        permission: 'activity-status'
      });
    } else {
      arr.push({
        type: 'start',
        label: '启用',
        permission: 'activity-status'
      });
      arr.push({
        type: 'delete',
        label: '删除',
        permission: 'activity-delete'
      });
    }

    if (row.isSignIn === 1) {
      arr.push({
        type: 'sign',
        label: '签到码'
      });
    }

    if (row.isComment === 1) {
      arr.push({
        type: 'eval',
        label: '评价码'
      });
    }

    return arr;
  }
}];
export var TABLE_CONFIG = {
  isSelect: true,
  rowKey: 'id',
  actions: [{
    label: '新增活动',
    type: 'add',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'activity-edit'
  }]
};