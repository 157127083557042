import "core-js/modules/es.object.to-string.js";
//查询条件
import STATUS from '@/maps/enum/status/buy';
import ENUM from '@/maps/enum/enum';
import { getEnumList } from '@api/common/common';
import { listCacheOrg, listCacheProduct } from '@api/common/cache';
import { listEnum as listEnumProduct } from '@api/crm/setup/product';
import { formatDate } from '@/libs/crm/format';
var VAL_WIDTH = '130px';
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
export var FILTER_FORMS = [{
  label: '合同编号',
  valueKey: 'contractCd',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '购销合同编号',
  valueKey: 'buyContractCd',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '客户',
  valueKey: 'customerName',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '手机号',
  valueKey: 'mobile',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '合同总状态',
  valueKey: 'arrayContractState',
  value: [],
  type: 'select',
  options: filterContractStatus(),
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  }
}, {
  label: '认购状态',
  valueKey: 'arrayBuyState',
  value: [],
  type: 'select',
  options: filterBuyStatus(),
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  }
}, {
  label: '产品',
  valueKey: 'arrayProductId',
  value: [],
  type: 'select',
  method: filterAllProduct,
  params: {},
  options: [],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  }
}, {
  label: '结算属性',
  valueKey: 'arrayProductCountType',
  value: [],
  type: 'select',
  method: getEnumList,
  params: {
    // 请求时用的参数
    enumCd: ENUM.ENUM_CD_PRODUCT_COUNT_TYPE
  },
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  }
}, {
  label: '收款方式',
  valueKey: 'arrayPayMethod',
  value: [],
  type: 'select',
  method: getEnumList,
  params: {
    enumCd: ENUM.ENUM_CD_0204
  },
  options: [],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  }
}, {
  label: '续约',
  valueKey: 'isReNew',
  value: '',
  type: 'select',
  options: [{
    value: '0',
    text: '否'
  }, {
    value: '1',
    text: '是'
  }],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '顾问',
  valueKey: 'userName',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '机构名称',
  valueKey: 'orgCd',
  value: '',
  type: 'cascader',
  method: listCacheOrg,
  params: {},
  options: [],
  props: {
    //expandTrigger: 'hover',
    checkStrictly: true,
    emitPath: false,
    showAllLevels: false,
    value: 'orgCd',
    label: 'orgName',
    children: 'children'
  }
}, {
  label: '开始日期',
  valueKey: 'startDateBegin',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'startDateEnd',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '到期日期',
  valueKey: 'endDateBegin',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'endDateEnd',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '提交日期',
  valueKey: 'submitDateBegin',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'submitDateEnd',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '更新时间',
  valueKey: 'updateTimeBegin',
  value: '',
  //value: initUpdateTimeBegin(),
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'updateTimeEnd',
  value: '',
  //value: initUpdateTimeEnd(),
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}]; //合同状态

function filterContractStatus() {
  var array = [STATUS.SAVE, STATUS.SUBMIT, STATUS.AUDIT, STATUS.COUNT_AUDIT, STATUS.DEAL, STATUS.SUSPEND, STATUS.PAYED, STATUS.AUDIT_FAIL, STATUS.DEAL_FAIL, STATUS.COUNT_AUDIT_FAIL, STATUS.RESERVE_BUY, STATUS.RESERVE_BUY_FAIL, STATUS.CLEAR_SUSPEND_SERVICE, STATUS.CLEAR_SUSPEND_COUNT_FAIL, STATUS.CLEAR_PAYED_SERVICE, STATUS.CLEAR_PAYED_COUNT_FAIL, STATUS.DEAL_RETURN];
  return array;
} //认购状态


function filterBuyStatus() {
  var array = [STATUS.SAVE, STATUS.SUBMIT, STATUS.AUDIT, STATUS.COUNT_AUDIT, STATUS.DEAL];
  return array;
} //合同查询需要所有产品


function filterAllProduct() {
  var promise = new Promise(function (resolve, reject) {
    var param = {
      query: {}
    };
    listEnumProduct(param).then(function (res) {
      var array = res.data;
      resolve(array);
    }).catch(function (res) {
      reject(res);
    });
  });
  return promise;
}
/**
 * 默认更新时间开始
 * @returns
 */


function initUpdateTimeBegin() {
  var date = new Date();
  date.setMonth(date.getMonth() - 1);
  var rs = formatDate(date);
  return rs;
}
/**
 * 默认更新时间结束
 * @returns
 */


function initUpdateTimeEnd() {
  var date = new Date();
  var rs = formatDate(date);
  return rs;
}