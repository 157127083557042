import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import { list as listAPI } from "@api/rm/api";
import { save, listTop, listTree } from "@api/rm/menu";
export default {
  name: "dialog-add-menu",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      formModel: {},
      //表单
      selectData: {
        menu: [],
        api: []
      },
      arrayParentId: [],
      //上级菜单Id
      arrayApiId: [],
      //关联接口ID，，分隔
      rules: {
        menuName: [{
          required: true,
          message: "请填写菜单名称",
          trigger: "input"
        }]
      }
    };
  },
  computed: {
    // 弹窗展示的标题
    title: function title() {
      return this.isEdit ? "修改" : "新增";
    },
    // 是否为编辑
    isEdit: function isEdit() {
      var id = this.propsModel.id;
      return !!id;
    }
  },
  created: function created() {
    var _this = this;

    listTree().then(function (res) {
      for (var i = 0; i < res.data.length; i++) {
        //一级菜单
        var n1 = res.data[i];
        var node1 = {
          value: n1.id,
          label: n1.menuName,
          children: []
        };

        for (var j = 0; j < n1.children.length; j++) {
          //二级菜单
          var n2 = n1.children[j];
          var node2 = {
            value: n2.id,
            label: n2.menuName,
            children: []
          };
          node1.children.push(node2);

          for (var k = 0; k < n2.children.length; k++) {
            //三级菜单
            var n3 = n2.children[k];
            var node3 = {
              value: n3.id,
              label: n3.menuName
            };
            node2.children.push(node3);
          }
        }

        _this.selectData.menu.push(node1);
      }
    }); //接口列表

    var paramAPI = {
      page: {
        pageNumber: 0,
        pageSize: 99999
      }
    };
    listAPI(paramAPI).then(function (res) {
      var array = [];
      var data = res.data;

      var _loop = function _loop(i) {
        var n = data[i]; //主节点

        var node1 = array.find(function (o) {
          return o.value == n.menuId;
        });

        if (node1 == null) {
          node1 = {
            value: n.menuId,
            label: n.menuIdText,
            children: []
          };
          array.push(node1);
        } //2级节点


        var node2 = {
          value: n.id,
          label: n.name
        };
        node1.children.push(node2); //3级节点
      };

      for (var i = 0; i < data.length; i++) {
        _loop(i);
      }

      _this.selectData.api = array;
    });
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();

          if (this.isEdit) {
            this.getInfo();
          } else {
            this.formModel = {
              enabled: '1',
              type: '1' //必须用数字，不能用'1'

            };
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.formModel = {};
      this.arrayParentId = [];
      this.arrayApiId = [];
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    // TODO 确定
    confirm: function confirm() {
      var _this2 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var param = _objectSpread({}, _this2.formModel);

          _this2.loading = true;
          save({
            object: param
          }).then(function (res) {
            _this2.loading = false;
            _this2.showDialog = false;

            _this2.$emit("refresh");
          }).catch(function (e) {
            _this2.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    //详情
    getInfo: function getInfo() {
      var _this3 = this;

      this.formModel = Object.assign({}, this.propsModel); //复制新对象
      //初始化上级菜单   

      if (this.formModel.parentId != null) {
        for (var i = 0; i < this.selectData.menu.length; i++) {
          var n = this.selectData.menu[i]; //归属菜单模块          

          if (this.formModel.parentId == n.value) {
            //如果菜单是归属一级
            this.arrayParentId.push(n.value);
            break;
          } else {
            //归属二级   
            var node = n.children.find(function (o) {
              return o.value == _this3.formModel.parentId;
            }); //childern中找到api中设置的值

            if (node != null) {
              //单选，1个数组2个元素
              this.arrayParentId.push(n.value);
              this.arrayParentId.push(node.value);
              break;
            }
          }
        }

        console.dir(this.arrayParentId);
      } //初始化关联接口


      if (this.formModel.apiId != null) {
        (function () {
          var arrayAPI = _this3.formModel.apiId.split(",");

          for (var _i = 0; _i < _this3.selectData.api.length; _i++) {
            var _n = _this3.selectData.api[_i]; //归属菜单模块

            var children = _n.children;

            var _loop2 = function _loop2(j) {
              var node = children.find(function (o) {
                return o.value == arrayAPI[j];
              }); //childern中找到api中设置的值

              if (node != null) {
                //多选，1个数组，元素也是数组
                var nodeArray = [_n.value, node.value];

                _this3.arrayApiId.push(nodeArray);
              }
            };

            for (var j = 0; j < arrayAPI.length; j++) {
              _loop2(j);
            }
          }
        })();
      }
    },
    //上级菜单
    handleChangeMenu: function handleChangeMenu(value) {
      if (value.length == 1) {
        //选择一级菜单
        this.formModel.parentId = value[0];
      } else if (value.length == 2) {
        //二级菜单
        this.formModel.parentId = value[1];
      }
    },
    //关联接口Id
    handleChangeAPI: function handleChangeAPI(value) {
      this.formModel.apiId = "";

      for (var i = 0; i < value.length; i++) {
        this.formModel.apiId += value[i][1] + ","; //value数组第一个值是第一级ID，第二级才是需要的ID
      }

      this.formModel.apiId = this.formModel.apiId.substring(0, this.formModel.apiId.length - 1);
    }
  }
};