var scoreURL = {
  score: {
    /** 积分列表 */
    getScoreList: '/manage/score/getScorePage',

    /** 积分导出 */
    exportScoreData: '/manage/score/exportScoreData',

    /** 批量调整积分 */
    batchAdjustScore: '/manage/score/manageBatchAdjustScore',

    /** 调整积分 */
    adjustScore: '/manage/score/manageAdjustScore',

    /** 积分明细列表 */
    getScoreDetailList: '/manage/scoreDetail/getScoreDetailPage',

    /** 导出积分明细数据 */
    exportScoreDetailData: '/manage/scoreDetail/exportScoreDetailData',

    /** 获取积分明细的变动记录列表 */
    getChangeList: '/manage/scoreDetail/getChangeList',

    /** 获取积分奖励规则获取详情 */
    getInfo: '/manage/rewardScoreRule/getInfo',

    /** 获取积分奖励规更新详情 */
    update: '/manage/rewardScoreRule/update'
  }
};
export default scoreURL;